import * as Yup from 'yup';

const PHONE_FIELD_LENGTH_MIN = 9;
const PHONE_FIELD_LENGTH_MAX = 15;

export const userValidationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    role: Yup.string().required(ty('field_required')),
    first_name: Yup.string().required(ty('field_required')),
    last_name: Yup.string().required(ty('field_required')),
    email: Yup.string().email(ty('invalid_email')).required(ty('field_required')),
    phone: Yup.number()
      .typeError(ty('must_be_number'))
      .positive(ty('invalid_format'))
      .integer(ty('invalid_format'))
      .test(
        'num',
        ty('phone_field_length', { lengthMin: PHONE_FIELD_LENGTH_MIN, lengthMax: PHONE_FIELD_LENGTH_MAX }),
        (val) => !val || val?.toString()?.length >= PHONE_FIELD_LENGTH_MIN && val.toString().length <= PHONE_FIELD_LENGTH_MAX,
      ),
    user_language: Yup.string().required(ty('field_required')),
  });
