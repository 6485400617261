import React, { useState, useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { GetUsersResponse } from 'utils/api/users';
import usePrefix from 'utils/usePrefix';
import { getApiUrl, api, PaginatedList, ResponseError } from 'utils/api';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';
import colors from 'styles/colors';

import Loader from 'components/Loader';
import Modal from 'components/Modal';
import SearchBar from 'components/SearchBar';
import NoResults from 'components/NoResults';
import Row from 'components/Row';
import Icon from 'components/Icons';

import { ButtonAction, ACTION_ICON_SIZE } from '../styles';
import { Wrapper, IntervenersList } from './styles';

const url = getApiUrl(`/schools`);

const RECORD_COUNT = 100;

interface Props {
  schoolId: number;
  isOpen: boolean;
  onClose: () => void;
  fetchData: () => void;
}

const PossibleUsers: React.FC<Props> = ({ schoolId, onClose, isOpen, fetchData }) => {
  const [, dispatch] = useApp();

  const t = usePrefix('Schools');
  const tg = usePrefix('General');

  const [users, setUsers] = useState<GetUsersResponse[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const MIN_QUERY_LENGTH = 3;

  const loadData = async () => {
    try {
      const response: PaginatedList<GetUsersResponse[]> = await api(
        `${url}/${schoolId}/possibleUsers?phrase=${nameFilter}&page=0&size=${RECORD_COUNT}`,
        {
          method: 'GET',
        }
      );

      if (response) {
        setUsers(response.content);
        setIsLoading(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    if (nameFilter.length >= MIN_QUERY_LENGTH) {
      setIsLoading(true);
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter]);

  const addUser = async (userId: number) => {
    try {
      const response = await api(`${url}/${schoolId}/users/${userId}`, {
        method: 'POST',
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} centered paddingTop="24px">
        <Wrapper justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
          <SearchBar
            name="name"
            placeholder={t('search')}
            onChange={(e) => setNameFilter(e.target.value)}
            value={nameFilter}
            onClear={() => setNameFilter('')}
            isDisabled={nameFilter.length < 1}
          />
          <IntervenersList>
            {users.length ? (
              <AutoSizer disableWidth>
                {({ height }: { height: number }) => (
                  <List
                    height={height}
                    width="100%"
                    itemCount={users.length}
                    itemSize={48}
                    innerElementType="ul"
                  >
                    {({ index, ...props }) => (
                      <Row
                        key={users[index].id}
                        title={`${users[index].first_name} ${users[index].last_name}`}
                        labels={[
                          `${users[index].first_name} ${users[index].last_name}`,
                          users[index].email,
                          tg(users[index].role),
                        ]}
                        clickable={false}
                        cellChildren={
                          <ButtonAction
                            onClick={() => addUser(users[index].id)}
                            title={t('add')}
                            disabled={false}
                          >
                            <Icon
                              type={'plus'}
                              width={ACTION_ICON_SIZE}
                              height={ACTION_ICON_SIZE}
                              color={colors.success}
                            />
                          </ButtonAction>
                        }
                        {...props}
                      />
                    )}
                  </List>
                )}
              </AutoSizer>
            ) : (
              <NoResults margin="0" title={t('no_users')} />
            )}
          </IntervenersList>
        </Wrapper>
        {isLoading && <Loader label={t('fetching_users')} />}
      </Modal>
    </>
  );
};

export default PossibleUsers;
