import styled from 'styled-components';
import { Form } from 'formik';

import Link from 'components/Link';
import { loginFormStyles, loginTitleStyles } from 'components/LoginWrapper/styles';

export const Content = styled(Form)`
  ${loginFormStyles};
`;

export const Title = styled.h1`
  ${loginTitleStyles};
`;

export const StyledLink = styled(Link)`
  align-self: center;
`;
