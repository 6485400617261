import styled, { css } from 'styled-components';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';

interface Props {
  color: string;
}

export const linkButtonStyles = css`
  padding: 0;
  height: 20px;
  background-color: transparent;
  font-size: 16px;
  color: ${colors.brand_2};
  transition: color ${ANIMATION};
  @media (hover: hover) {
    &:hover {
      color: ${colors.brand_1};
    }
  }
`;

const Link = styled(RouterLink)<Props & LinkProps>`
  ${linkButtonStyles};
  text-decoration: none;
  color: ${({ color }) => color};
`;

export default Link;
