import { StylesConfig } from 'react-select';
import colors from 'styles/colors';

export const customSelectStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    marginBottom: '16px',
    width: '100%',
  }),
  option: (provided, state: { isSelected: any }) => ({
    ...provided,
    cursor: state.isSelected ? 'default' : 'pointer',
    color: colors.gray,
    backgroundColor: state.isSelected ? colors.brand_shadow_025 : colors.brand_2_shadow_01,
    fontSize: '16px',
    minHeight: '30px',
    lineHeight: '16px',
    ':hover': {
      backgroundColor: state.isSelected ? colors.brand_shadow_025 : colors.brand_shadow_01,
    },
  }),
  control: (provided) => ({
    ...provided,
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px 12px',
    width: '100%',
    minWidth: 0,
    minHeight: 0,
    height: '48px',
    border: `1px solid transparent`,
    borderRadius: `4px`,
    backgroundColor: colors.brand_shadow_005,
    color: colors.brand_2,
    cursor: 'pointer',
    outline: 'none',
    ':hover': {
      backgroundColor: 'rgba(17, 132, 255, 0.08)',
      borderColor: colors.brand_shadow_01,
      boxShadow: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: 0,
    border: `1px solid ${colors.brand_shadow_01}`,
    boxShadow: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    backgroundColor: colors.brand_2_shadow_01,
  }),
  singleValue: (provided) => ({
    ...provided,
    overflow: "hidden",
    position: "absolute",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    top: "50%",
    transform: "translateY(-50%)",
    color: colors.brand_2,
    fontSize: '16px',
    lineHeight: '20px',
  }),
  indicatorsContainer: () => ({ height: 16 }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.brand_2,
    padding: 0,
    width: 16,
    height: 16,
    ':hover': {
      color: colors.brand_2,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    display: "flex"
  }),
  input: () => ({ margin: 0 }),
  placeholder: () => ({
    position: 'absolute',
    fontSize: '16px',
    color: colors.brand_shadow_025,
  }),
};
