import styled from 'styled-components';
import colors from 'styles/colors';

import FlexDiv from 'components/FlexDiv';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	margin-inline: auto;
	padding-block: 40px;
	width: 372px;
	min-height: 388px;
`;

export const ButtonContainer = styled(FlexDiv)`
`;

export const Description = styled.div`
  width: 100%;
  margin-bottom: 15px;
  font-size: 18px;
  color: ${colors.gray};
`;
