import React from 'react';

import colors from 'styles/colors';

import { RequirementStyled, RequirementProps, StyledIcon } from './styles';

const Requirement: React.FC<RequirementProps> = ({ touched, error, children }) => (
  <RequirementStyled touched={touched} error={error}>
    {touched ? (
      <StyledIcon
        type={error ? 'close' : 'check'}
        width="18px"
        height="18px"
        color={error ? colors.error : colors.success}
      />
    ) : (
      <>&#8226; </>
    )}
    {children}
  </RequirementStyled>
);

export default Requirement;
