import React from 'react';
import colors from 'styles/colors';

import { GraphicProps, Cloud1Graphic, Cloud2Graphic } from './Graphics';

export type GraphicName = 'cloud1' | 'cloud2';

interface Props {
  type: GraphicName;
  onClick?: (event: React.MouseEvent<SVGElement>) => void;
  disabled?: boolean;
}

const GRAPHIC_SIZE = '100px';

const Graphic: React.FC<Props & Partial<GraphicProps>> = ({
  type,
  color = colors.brand_2,
  width = GRAPHIC_SIZE,
  height = GRAPHIC_SIZE,
  disabled = false,
  opacity = '1',
  ...props
}) => {
  const params = { width, height, color, disabled, opacity };

  return {
    cloud1: <Cloud1Graphic {...params} {...props} />,
    cloud2: <Cloud2Graphic {...params} {...props} />,
  }[type];
};

export default Graphic;
