import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useBrowserStorage from 'utils/browserStorage';
import {languages, parseLang} from 'utils/languages';
import useClickOutside from 'components/ClickOutside';

import { LanguageStyled, LanguageMenu } from './styles';
import ButtonLang from './ButtonLang';
import ButtonToggle from './ButtonToggle';

let defaultLang: string = ['pl'].includes(window.navigator.language) ? parseLang(languages.pl) : parseLang(languages.en);

export interface LangProps {
  isNav?: boolean;
}

const Language: React.FC<LangProps> = ({ isNav }) => {
  const { i18n } = useTranslation();

  const [isOpen, setDropdown] = useState(false);
  const [browserLang, setBrowserLang] = useBrowserStorage<string>(
    'setLang',
    defaultLang,
    false,
    localStorage
  );

  const outsideDivRef = useClickOutside<HTMLDivElement>(() => setDropdown(false));

  useEffect(() => {
    i18n.changeLanguage(browserLang);
  }, [i18n, browserLang]);

  const changeLanguage = (lang: string) => {
    setBrowserLang(lang);
    defaultLang = lang;
    setDropdown(false);
    // window.location.reload();
  };

  const toggleDropdown = (isOpen: boolean) => {
    if (isOpen) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  let label = '';

  if (browserLang === parseLang(languages.uk)) {
    label = 'UA';
  } else if (browserLang === parseLang(languages.cs)) {
    label = 'CZ';
  }  else if (browserLang === parseLang(languages.sk)) {
    label = 'SK';
  } else {
    label = browserLang;
  }

  return (
    <LanguageStyled ref={outsideDivRef} isNav={isNav}>
      <ButtonToggle onClick={() => toggleDropdown(isOpen)} label={label} isNav={isNav} />
      {isOpen && (
        <LanguageMenu isNav={isNav}>
          <ButtonLang
            isActive={browserLang === parseLang(languages.pl)}
            label="PL"
            onClick={() => changeLanguage(parseLang(languages.pl))}
          />
          <ButtonLang
            isActive={browserLang === parseLang(languages.en)}
            label="EN"
            onClick={() => changeLanguage(parseLang(languages.en))}
          />
          <ButtonLang
            isActive={browserLang === parseLang(languages.uk)}
            label="UA"
            onClick={() => changeLanguage(parseLang(languages.uk))}
          />
          <ButtonLang
            isActive={browserLang === parseLang(languages.cs)}
            label="CZ"
            onClick={() => changeLanguage(parseLang(languages.cs))}
          />
          <ButtonLang
            isActive={browserLang === parseLang(languages.sk)}
            label="SK"
            onClick={() => changeLanguage(parseLang(languages.sk))}
          />
        </LanguageMenu>
      )}
    </LanguageStyled>
  );
};

export default Language;
