import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'styles/colors';

const StyledPage = styled.h2<{ empty?: boolean }>`
  margin: 0;
  color: ${colors.brand_2};
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1.1px;
  ${({ empty }) =>
    empty &&
    css`
      opacity: 0.2;
    `};
`;

const DetailsTitle: React.FC<{ title: string; empty?: boolean }> = ({ title, empty, ...props }) => (
  <StyledPage {...props} empty={empty}>
    {title}
  </StyledPage>
);

export default DetailsTitle;
