import React from 'react';

import usePrefix from 'utils/usePrefix';

import SearchBar from 'components/SearchBar';
import PageTitle from 'components/PageTitle';

import { Wrapper, TitleContainer } from './styles';

interface Props {
  children?: React.ReactNode;
  title: string;
  filter?: string;
  setFilter?: (val: string) => void;
  resetFilter?: () => void;
  isSearchClearDisabled?: boolean;
}

const ListContainer: React.FC<Props> = ({
  title,
  filter,
  setFilter,
  children,
  resetFilter,
  isSearchClearDisabled,
}) => {
  const t = usePrefix('General');

  return (
    <Wrapper>
      <TitleContainer>
        <PageTitle title={title} />
      </TitleContainer>
      {setFilter && (
        <SearchBar
          name="filter"
          placeholder={t('search')}
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          onClear={resetFilter}
          isDisabled={isSearchClearDisabled}
          short
        />
      )}
      {children}
    </Wrapper>
  );
};
export default ListContainer;
