import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import FlexDiv from 'components/FlexDiv';

export const Wrapper = styled.div`
  margin-bottom: 5px;
`;

export const Content = styled(FlexDiv)<{
  clickable: boolean;
  selected: boolean;
  disabled: boolean;
  unread: boolean;
  attention: boolean;
}>`
  border-radius: 5px;
  background-color: ${colors.gray_scale9};
  padding: 10px;
  overflow: hidden;

  ${({ selected, unread, attention }) =>
    (selected || unread || attention) &&
    css`
      background-color: ${selected
        ? colors.brand_1
        : unread
        ? colors.yellow
        : colors.brand_shadow_005};
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          background-color: ${colors.gray_scale4};
        }
      }
    `};

  & div {
    overflow: hidden;
    ${({ unread, selected }) =>
      (unread || selected) &&
      css`
        color: ${selected ? colors.white : colors.brand_2};
      `}
  }
`;

export const Title = styled.div<{ maxHeight?: string; color?: string }>`
  color: ${({ color }) => (color ? color : colors.gray_scale7)};
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${({ maxHeight }) => css`
    max-height: ${maxHeight};
  `}
`;

export const Subtitle = styled.div<{ color?: string }>`
  color: ${({ color }) => (color ? color : colors.gray_scale8)};
  font-size: 12px;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
