import React, { useState, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import dayjs from 'dayjs';

import { getApiUrl, PaginatedList } from 'utils/api';
import { useApi } from 'utils/api/useApi';
import usePrefix from 'utils/usePrefix';
import { ReceivedInstructionResponse } from 'utils/api/inbox';
import { useApp } from 'App';
import { findViolenceTypeKey } from 'utils/misc';

import NoticeContainer from 'components/NoticeContainer';
import ListContainer from 'components/ListContainer';
import RowShort from 'components/RowShort';
import NoData from 'components/NoData';
import { ListWrapper } from 'components/ListContainer/styles';

import Instruction from './Instruction';

const url = getApiUrl('/interveners/me/instructions');

const RECORD_COUNT = 9999999;

export interface ModalState {
  isOpen: boolean;
  instructionId?: number;
}

const Instructions: React.FC = () => {
  const t = usePrefix('Instructions');
  const tv = usePrefix('Violence');

  const [{ violenceTypes }] = useApp();

  const [instructionId, setInstructionId] = useState<number | undefined>(undefined);
  const [nameFilter, setNameFilter] = useState('');

  const MIN_QUERY_LENGTH = 3;

  const { data, fetchData } = useApi<PaginatedList<ReceivedInstructionResponse[]>>(
    nameFilter.length < MIN_QUERY_LENGTH
      ? `${url}`
      : `${url}?page=0&size=${RECORD_COUNT}&name=${nameFilter}`,
    {
      method: 'GET',
    }
  );

  const handleFilterReset = () => {
    setNameFilter('');
  };

  useEffect(() => {
    if (!!data?.content.length && !instructionId) setInstructionId(data.content[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) return null;

  return (
    <NoticeContainer>
      <ListContainer
        title={t('instructions_list')}
        setFilter={setNameFilter}
        filter={nameFilter}
        resetFilter={handleFilterReset}
        isSearchClearDisabled={nameFilter.length < 1}
      >
        {data.content.length > 0 && (
          <ListWrapper>
            <AutoSizer disableWidth>
              {({ height }: { height: number }) => (
                <List height={height} width="100%" itemCount={data.content.length} itemSize={64}>
                  {({ index, style }) => (
                    <RowShort
                      title={data.content[index].name}
                      subtitle={`${dayjs(data.content[index].receivedDate).format(
                        'DD-MM-YYYY'
                      )} / ${
                        data.content[index].readDate
                          ? dayjs(data.content[index].readDate).format('DD-MM-YYYY')
                          : t('not_read')
                      }`}
                      violenceType={tv(
                        findViolenceTypeKey(violenceTypes, data.content[index].violenceId)
                      )}
                      onClick={() => setInstructionId(data.content[index].id)}
                      unread={!data.content[index].readDate}
                      selected={!!instructionId && data.content[index].id === instructionId}
                      style={style}
                    />
                  )}
                </List>
              )}
            </AutoSizer>
          </ListWrapper>
        )}
        {!instructionId && <NoData title={t('no_instructions')} />}
      </ListContainer>
      {!instructionId ? (
        <NoData title={t('no_instructions')} />
      ) : (
        <Instruction instructionId={instructionId} fetchData={fetchData} />
      )}
    </NoticeContainer>
  );
};

export default Instructions;
