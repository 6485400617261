import styled from 'styled-components';

import colors from 'styles/colors';
import FlexDiv from 'components/FlexDiv';
import { CHAT_META_WIDTH } from 'pages/Chat/styles';

export const Wrapper = styled.section`
  position: relative;
  flex: 0 0 ${CHAT_META_WIDTH};
  padding: 16px 24px;
  height: 100%;
  border-left: 1px solid ${colors.gray_shadow_03};
  overflow-y: auto;
`;

export const DateRow = styled(FlexDiv)`
  flex: 1;
  margin: 0 -8px;
  padding-bottom: 20px;
`;

export const DateCell = styled.div`
  padding: 0 8px;
  width: 50%;

  & input::-webkit-calendar-picker-indicator {
    margin: 0;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.black_shadow};
  margin-bottom: 15px;
`;
