import React from 'react';

import ArrowNav from 'statics/triangle_down_primary_inverted.svg';
import Arrow from 'statics/triangle_down_primary.svg';

import { ButtonToggleStyled } from './styles';

export interface Props {
  isActive?: boolean;
  isNav?: boolean;
  label?: string;
  onClick?: () => void;
}

const ButtonToggle: React.FC<Props> = ({ isActive, isNav, label, onClick}) => (
  <ButtonToggleStyled
    isActive={isActive}
    isNav={isNav}
    onClick={onClick}
    type="button"
  >
    {label}
    <img src={isNav ? ArrowNav : Arrow} alt="" />
  </ButtonToggleStyled>
);

export default ButtonToggle;
