import styled from 'styled-components';

import { screenLgAbove } from 'styles/breakpoinst';

const Layout = styled.main`
  display: grid;
  grid-template-areas: 'nav main';
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  @media screen and (min-width: ${screenLgAbove}) {
    grid-template-columns: 174px 1fr;
  }
`;

export default Layout;
