import styled from 'styled-components';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';
import FlexDiv from 'components/FlexDiv';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  overflow-y: auto;
`;

export const ButtonsContainer = styled(FlexDiv)`
  margin: 16px -16px 0;
  z-index: 2060;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ButtonItem = styled.div`
  margin: 0 16px;
`;

export const ModalContainer = styled.div`
  position: relative;
  margin: 48px auto 0;
  padding: 24px;
  width: 100%;
  max-width: 360px;
  background-color: ${colors.white};
  box-shadow: 0 0 64px 0 ${colors.gray_shadow_03};
  animation: showModal ${ANIMATION} forwards;
  h3 {
    margin: 0 0 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.black};
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.black};
  }
  @keyframes showModal {
    0% {
      top: -48px;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
`;
