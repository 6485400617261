import React from 'react';

import colors from 'styles/colors';

import Icon, { IconName } from 'components/Icons';

import { SectionWrapper, Title, Content, PlusContainer } from './styles';

export const ICON_HEIGHT = '18px';

interface Props {
  children?: React.ReactNode;
  title: string;
  onClick?: () => void;
  iconType?: IconName;
  absolute?: boolean;
}

const MetaSection: React.FC<Props> = ({ title, onClick, iconType, children, absolute }) => (
  <SectionWrapper absolute={absolute}>
    <Content>
      <Title alignItems="center" justifyContent="space-between">
        {title}
        {!!iconType && (
          <PlusContainer>
            <Icon
              onClick={onClick}
              type={iconType}
              width={ICON_HEIGHT}
              height={ICON_HEIGHT}
              color={colors.brand_2}
            />
          </PlusContainer>
        )}
      </Title>
      {children}
    </Content>
  </SectionWrapper>
);

export default MetaSection;
