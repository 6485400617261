import React from 'react';
import colors from 'styles/colors';

import {
  AttachIcon,
  ExitIcon,
  BulbIcon,
  ChatIcon,
  CloseIcon,
  CheckIcon,
  IconProps,
  SendIcon,
  ReportsIcon,
  NotesIcon,
  PlusIcon,
  MinusIcon,
  EditIcon,
  ChevronDownIcon,
  ErrorCircleIcon,
  SuccessCircleIcon,
  LogoIcon,
  UserIcon,
  UsersIcon,
  SchoolsIcon,
  MaterialIcon,
  MovieIcon,
  ArticleIcon,
  ChartIcon,
  PlusbgIcon,
  DeleteIcon,
  RadioOnIcon,
  RadioOffIcon,
  NotesSmallIcon,
  HomeIcon,
  MenuIcon,
  FacebookIcon,
  LinkedInIcon,
  RepositoryIcon,
  LessonIcon,
  AlertIcon,
  RefreshIcon,
} from './Icons';

export type IconName =
  | 'attach'
  | 'exit'
  | 'bulb'
  | 'chat'
  | 'close'
  | 'check'
  | 'send'
  | 'notes'
  | 'reports'
  | 'plus'
  | 'minus'
  | 'edit'
  | 'chevronDown'
  | 'errorCircle'
  | 'successCircle'
  | 'logo'
  | 'user'
  | 'users'
  | 'schools'
  | 'material'
  | 'movie'
  | 'article'
  | 'chart'
  | 'plusbg'
  | 'delete'
  | 'radioon'
  | 'radiooff'
  | 'notessm'
  | 'home'
  | 'menu'
  | 'facebook'
  | 'linkedin'
  | 'repository'
  | 'lesson'
  | 'alert'
  | 'refresh';

interface Props {
  type: IconName;
  onClick?: (event: React.MouseEvent<SVGElement>) => void;
  disabled?: boolean;
}

const ICON_SIZE = '24';

const Icon: React.FC<Props & Partial<IconProps>> = ({
  type,
  color = colors.gray_scale3,
  width = ICON_SIZE,
  height = ICON_SIZE,
  disabled = false,
  ...props
}) => {
  const params = { width, height, color, disabled };

  return {
    attach: <AttachIcon {...params} {...props} />,
    exit: <ExitIcon {...params} {...props} />,
    bulb: <BulbIcon {...params} {...props} />,
    chat: <ChatIcon {...params} {...props} />,
    close: <CloseIcon {...params} {...props} />,
    check: <CheckIcon {...params} {...props} />,
    send: <SendIcon {...params} {...props} />,
    notes: <NotesIcon {...params} {...props} />,
    reports: <ReportsIcon {...params} {...props} />,
    plus: <PlusIcon {...params} {...props} />,
    minus: <MinusIcon {...params} {...props} />,
    edit: <EditIcon {...params} {...props} />,
    chevronDown: <ChevronDownIcon {...params} {...props} />,
    errorCircle: <ErrorCircleIcon {...params} {...props} />,
    successCircle: <SuccessCircleIcon {...params} {...props} />,
    logo: <LogoIcon {...params} {...props} />,
    user: <UserIcon {...params} {...props} />,
    users: <UsersIcon {...params} {...props} />,
    schools: <SchoolsIcon {...params} {...props} />,
    material: <MaterialIcon {...params} {...props} />,
    movie: <MovieIcon {...params} {...props} />,
    article: <ArticleIcon {...params} {...props} />,
    chart: <ChartIcon {...params} {...props} />,
    plusbg: <PlusbgIcon {...params} {...props} />,
    delete: <DeleteIcon {...params} {...props} />,
    radioon: <RadioOnIcon {...params} {...props} />,
    radiooff: <RadioOffIcon {...params} {...props} />,
    notessm: <NotesSmallIcon {...params} {...props} />,
    home: <HomeIcon {...params} {...props} />,
    menu: <MenuIcon {...params} {...props} />,
    facebook: <FacebookIcon {...params} {...props} />,
    linkedin: <LinkedInIcon {...params} {...props} />,
    repository: <RepositoryIcon {...params} {...props} />,
    lesson: <LessonIcon {...params} {...props} />,
    alert: <AlertIcon {...params} {...props} />,
    refresh: <RefreshIcon {...params} {...props} />,
  }[type];
};

export default Icon;
