import styled from 'styled-components';

import colors from 'styles/colors';
import FlexDiv from 'components/FlexDiv';

export const LoaderStyled = styled(FlexDiv)`
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 24px;
  background-color: ${colors.brand_2};
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  z-index: 2050;
`;
