import React from 'react';

import { DataCellStyled, Value } from './styles';

interface DataCellProps {
  children?: React.ReactNode;
  label?: string;
  cols?: number;
}

// eslint-disable-next-line no-magic-numbers
const DataCell: React.FC<DataCellProps> = ({ children, label, cols = 1 }) => (
  <DataCellStyled cols={cols} justifyContent="flex-start" alignItems="center">
    {label && <Value>{label}</Value>}
    {children && children}
  </DataCellStyled>
);

export default DataCell;
