/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import colors from 'styles/colors';
import { screenMdAbove } from 'styles/breakpoinst';
import { ANIMATION } from 'styles/globalVariables';
import { linkButtonStyles } from 'components/Link';

export type ButtonColors = 'primary' | 'secondary' | 'option' | 'link' | 'red';

export interface StyleProps {
  height?: number;
  disabled: boolean;
  color: ButtonColors;
  width?: string;
}

export const buttonColors = (color: ButtonColors) =>
  ({
    primary: css`
      background-color: ${colors.brand_2};
      color: ${colors.white};
      @media (hover: hover) {
        &:hover:not(:disabled),
        &:hover:not([disabled]) {
          background-color: ${colors.brand_1};
        }
      }
    `,
    secondary: css`
      background-color: ${colors.brand_1};
      color: ${colors.white};
      @media (hover: hover) {
        &:hover:not(:disabled),
        &:hover:not([disabled]) {
          background-color: ${colors.brand_4};
        }
      }
    `,
    option: css`
      background-color: ${colors.yellow};
      color: ${colors.brand_2};
      @media (hover: hover) {
        &:hover:not(:disabled),
        &:hover:not([disabled]) {
          background-color: ${colors.brand_4};
        }
      }
    `,
    link: linkButtonStyles,
    red: css`
      ${linkButtonStyles};
      color: ${colors.error};
      @media (hover: hover) {
        &:hover {
          color: ${colors.error_scale2};
        }
      }
    `,
  })[color];

export const buttonStyles = css<StyleProps>`
  height: ${({ height }) => height || '40px'};
  width: ${({ width }) => (width ? width : 'auto')};
  white-space: nowrap;
  color: ${colors.white};
  padding: 0 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  outline: 0;
  display: inline-block;
  cursor: pointer;
  transition:
    background-color ${ANIMATION},
    color ${ANIMATION};
  ${({ disabled }) =>
    disabled &&
    css`
      &:disabled,
      &[disabled] {
        background-color: ${colors.gray_scale6};
        color: ${colors.gray_scale4};
        cursor: default;
      }
    `};

  ${({ color }) => buttonColors(color)};
  
  @media screen and (min-width: ${screenMdAbove}) {
    padding-inline: 20px;
  }
`;

export const StyledButton = styled.button<StyleProps>`
  ${buttonStyles}
`;

export const StyledLink = styled(Link)<StyleProps>`
  ${buttonStyles};
  display: flex;
  align-items: center;
  text-decoration: none;
`;
