import styled from 'styled-components';

import colors from 'styles/colors';

export const FetchingMessageStyled = styled('div')`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  background: ${colors.white_tint_70};
  color: ${colors.brand_2};
  
  h3 {
    margin: 0;
    font-size: 16px;
    line-height: 1.2;
    color: ${colors.brand_2};
    text-align: center;
    white-space: pre-line;
  }
`;
