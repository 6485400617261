import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { screenXlgAbove } from 'styles/breakpoinst';

import FlexDiv from 'components/FlexDiv';

import { ICON_HEIGHT } from './MetaSection';
import { CHAT_META_WIDTH, CHAT_META_WIDTH_MD } from '../styles';

export const Wrapper = styled.div`
  flex: 0 0 ${CHAT_META_WIDTH};
  padding: 16px 24px;
  height: 100%;
  overflow-y: auto;
`;

const sectionAbsoluteStyles = css`
  position: absolute;
  top: 0;
  right: ${CHAT_META_WIDTH_MD};
  margin-bottom: 0;
  padding: 16px 24px;
  border-right: 1px solid ${colors.gray_shadow_03};
  border-left: 1px solid ${colors.gray_shadow_03};
  width: 330px;
  height: 100%;
  background-color: ${colors.white};
  @media screen and (min-width: ${screenXlgAbove}) {
    right: ${CHAT_META_WIDTH};
  }
`;

export const SectionWrapper = styled(FlexDiv)<{ absolute?: boolean }>`
  width: 100%;
  margin-bottom: 15px;
  ${({ absolute }) => absolute && sectionAbsoluteStyles};
  svg {
    flex: 0 0 ${ICON_HEIGHT};
  }
`;

export const Title = styled(FlexDiv)`
  min-height: ${ICON_HEIGHT};
  font-size: 22px;
  font-weight: 600;
  color: ${colors.brand_2};
  margin-bottom: 15px;
  padding-right: 15px;
`;

export const Content = styled.div`
  flex: 1;
  min-width: 0;
  @media screen and (min-width: ${screenXlgAbove}) {
    padding-left: 8px;
  }
`;

export const DetailTitle = styled.div`
  color: ${colors.gray_scale8};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

export const Detail = styled.div<{ transparent?: boolean }>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 15px;
  color: ${colors.brand_2};
  background-color: ${colors.brand_shadow_005};
  border-radius: 4px;
  margin-bottom: 15px;
  ${({ transparent }) =>
    transparent &&
    css`
      background-color: ${colors.white};
      border: 1px solid ${colors.brand_shadow_025};
      color: ${colors.gray_scale7};
    `};
`;

export const Label = styled.div`
  flex: 1;
  padding-right: 8px;
`;

export const ViolenceTypesList = styled.ul`
  margin: 8px 0 0;
  padding: 0;
  width: 100%;
  min-width: 0;
  list-style: none;
`;

export const TypeItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  padding: 15px;
  border: 1px solid ${colors.brand_shadow_025};
  color: ${colors.gray};
  border-radius: 4px;
  min-width: 0;

  & > span {
    margin-right: 14px;
  }
`;

export const SwitchContainer = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`;

export const PlusContainer = styled.div<{ disabled?: boolean; padding?: string }>`
  ${({ disabled }) =>
    !disabled &&
    css`
      & > svg {
        cursor: pointer;
      }
    `};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
`;

export const TagInputContainer = styled(FlexDiv)`
  margin-bottom: 15px;
`;
