import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';

import colors from 'styles/colors';
import { FONT_PRIMARY } from 'styles/globalVariables';

export const NoteActionButton = styled(FlexDiv)`
  margin-top: 16px;
  width: 100%;
`;

export const TextArea = styled.textarea`
  width: 100%;
  color: ${colors.brand_2};
  font-family: ${FONT_PRIMARY};
  font-size: 16px;
  line-height: 20px;
  border: 1px solid ${colors.brand_1};
  border-radius: 4px;
  box-shadow: 0 0 10px 0 ${colors.brand_2_shadow_01};
  resize: none;
  padding: 15px;
`;

export const PastNote = styled(FlexDiv)`
  margin-top: 30px;
  color: ${colors.gray_scale7};
  font-size: 16px;
  line-height: 20px;
  padding: 15px;
  border: 1px solid ${colors.gray_shadow_03};
  border-radius: 4px;
  white-space: pre-line;
`;

export const PastNoteInfo = styled(FlexDiv)`
  padding: 5px 15px 0;
  color: #7a7a7a;
  font-size: 12px;
  line-height: 15px;
`;
