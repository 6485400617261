import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { getApiUrl, api, ResponseError } from 'utils/api';
import usePrefix from 'utils/usePrefix';
import { useApi } from 'utils/api/useApi';
import { SchoolLicenceResponse } from 'utils/api/schools';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';

import Loader from 'components/Loader';
import NoResults from 'components/NoResults';
import useClickOutside from 'components/ClickOutside';
import { UsersList } from 'components/TableContainer/styles';

import Section from '../Section';

import Row from './Row';

const validationSchema = (licensed_reporters: number) =>
  Yup.object().shape({
    date_to: Yup.date().min(new Date()),
    max_number_of_reporters: Yup.number().positive().min(licensed_reporters),
  });

const url = getApiUrl('/schools');

interface Props {
  schoolId: number;
}

export interface FormValues {
  date_to: string;
  max_number_of_reporters: number;
}

const Licence: React.FC<Props> = ({ schoolId }) => {
  const [, dispatch] = useApp();

  const { data, isLoading, fetchData } = useApi<SchoolLicenceResponse>(
    `${url}/${schoolId}/licence`,
    {
      method: 'GET',
    }
  );
  const t = usePrefix('Schools');
  const [isEditing, setIsEditing] = useState(false);

  const outsideDivRef = useClickOutside<HTMLDivElement>(() => setIsEditing(false));

  const isValueNotChanged = (values: FormValues): boolean =>
    values.date_to === data?.date_to &&
    values.max_number_of_reporters === data?.max_number_of_reporters;

  const updateLicence = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    if (isValueNotChanged(values)) return;

    try {
      const response = await api(`${url}/${schoolId}/licence`, {
        method: 'PUT',
        payload: {
          ...values,
        },
      });
      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        setIsEditing(false);
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
    resetForm();
  };

  const setIsEditingCallback = (set: boolean) => {
    setIsEditing(set);
  };

  return (
    <Section title={t('license')}>
      {isLoading && <Loader label={t('fetching_licence_data')} />}
      {!isLoading && !data && <NoResults title={t('no_licence_data')} />}
      {!!data && (
        <Formik
          initialValues={{
            date_to: data.date_to,
            max_number_of_reporters: data.max_number_of_reporters,
          }}
          onSubmit={updateLicence}
          validationSchema={() => validationSchema(data.number_of_licensed_reporters)}
          enableReinitialize={true}
        >
          {({ errors, touched, isSubmitting, resetForm, values }) => (
            <Form autoComplete="off">
              <div ref={outsideDivRef}>
                <UsersList>
                  {data && (
                    <Row
                      licence={data}
                      touched={touched}
                      errors={errors}
                      isChanged={isValueNotChanged(values)}
                      isEditing={isEditing}
                      isDisabled={
                        isValueNotChanged(values) ||
                        dayjs(values.date_to).isBefore(dayjs()) ||
                        values.max_number_of_reporters < data.number_of_licensed_reporters ||
                        isSubmitting
                      }
                      setIsEditing={setIsEditingCallback}
                      resetForm={resetForm}
                    />
                  )}
                </UsersList>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Section>
  );
};

export default Licence;
