import styled, { css } from 'styled-components';

import { INPUT_PADDING } from 'components/TextInput/styles';
import colors from 'styles/colors';

import FlexDiv from 'components/FlexDiv';

export const Wrapper = styled(FlexDiv)<{ margin: string; login: boolean }>`
  position: relative;
  width: 100%;
  margin: ${({ margin }) => margin};
  ${({ login }) =>
    login &&
    css`
      margin-bottom: 17px;
    `}
`;

export const Content = styled(FlexDiv)`
  width: 100%;
  min-height: 32px;
  font-size: 16px;
`;

export const Label = styled.label`
  margin-bottom: 4px;
  margin-left: ${INPUT_PADDING};
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  color: ${colors.brand_1};
  text-transform: uppercase;
  font-weight: 600;
`;

export const Error = styled.span`
  position: absolute;
  top: 100%;
  left: 0;
  margin-left: ${INPUT_PADDING};
  font-size: 10px;
  line-height: 16px;
  color: ${colors.error};
`;
