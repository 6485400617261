import React from 'react';

import usePrefix from 'utils/usePrefix';

import FlexDiv from 'components/FlexDiv';
import { ObjectReportWrapper } from 'components/TableContainer/styles';
import EmptyList from 'components/EmptyList';
import NoResults from 'components/NoResults';
import { DataRowStyled } from 'components/DataRow/styles';
import DataCell from 'components/DataCell';

import {Content} from '../styles';
import {ReportType} from '../Criteria';

interface Props {
  objectData?: { reportType: ReportType, data: object };
}

const ObjectReport: React.FC<Props> = ({objectData}) => {
  const t = usePrefix('Reports');

  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex="1"
      width="100%"
    >
      <Content>
        <ObjectReportWrapper>
          {(!objectData || !objectData.data) && (
            <EmptyList>
              <NoResults title={t('no_data')} />
            </EmptyList>
          )}
          {(!!objectData && !!objectData.data) && (
            <>
              {Object.keys(objectData.data).map((key) => (
                <DataRowStyled key={key} clickable={false} selected={false} disabled={false} unread={false}>
                  <DataCell>
                    <span>{t(key)}: <b>{(objectData.data as {[key: string]: string})[key]}</b></span>
                  </DataCell>
                </DataRowStyled>
              ))}
            </>
          )}
        </ObjectReportWrapper>
      </Content>
    </FlexDiv>
  );
};

export default ObjectReport;
