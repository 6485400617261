import React, { useState } from 'react';

import { useApp } from 'App';
import usePrefix from 'utils/usePrefix';
import {
  ReportGeneralResponse,
  ReportDetailResponse,
  UsersMgmntReportResponse,
  ReportSingleResponse,
  MessagesIp,
  LicenseReportResponse,
} from 'utils/api/report';
import { userRoles } from 'utils/userRoles';
import { getApiUrl } from 'utils/api';
import circles from 'statics/circles.svg';

import PageTitle from 'components/PageTitle';
import FlexDiv from 'components/FlexDiv';
import Anchor from 'components/Anchor';

import ChartPanel from './ChartPanel';
import Criteria, { LocationSpecificTargetType, ReportType } from './Criteria';
import UsersManagement from './UsersManagement';
import ObjectReport from './ObjectReport';
import MessagesIpReport from './MessagesIp';
import { Wrapper, ChartContainer, ReportsTip, ChartDetailStyled } from './styles';

const Reports: React.FC = () => {
  const t = usePrefix('Reports');

  const [dataGeneral, setGeneralData] = useState<ReportGeneralResponse>();
  const [dataDetail, setDetailData] = useState<ReportDetailResponse[]>();
  const [dataLicence, setLicenceData] = useState<LicenseReportResponse[]>();
  const [usersMgmntData, setUsersMgmntData] = useState<UsersMgmntReportResponse>()
  const [messagesIpData, setMessagesIpData] = useState<{reportType: ReportType, data: MessagesIp[]}>();
  const [reportParams, setReportParams] = useState('');
  const [reportType, setReportType] = useState<ReportType | undefined>(undefined);
  const [schoolId, setSchooldId] = useState<LocationSpecificTargetType>();
  const [dataSingle, setSingleData] = useState<{reportType: ReportType, data: ReportSingleResponse}>();
  const [objectData, setObjectData] = useState<{reportType: ReportType, data: object}>();
  const [{ violenceTypes }] = useApp();
  const [{ profile }] = useApp();
  const isDirector = profile?.role === userRoles.director;
  const urlAdmin = getApiUrl('/reports');
  const urlDirector = getApiUrl('/director/me/reports');

  const handleDetailData = (data?: ReportDetailResponse[]) => {
    setDetailData(data);
    setGeneralData(undefined);
    setLicenceData(undefined);
    setUsersMgmntData(undefined);
    setMessagesIpData(undefined);
    setSingleData(undefined);
    setObjectData(undefined);
    setReportType('licence');
  };

  const handleLicenceData = (data?: LicenseReportResponse[]) => {
    setLicenceData(data);
    setGeneralData(undefined);
    setDetailData(undefined);
    setUsersMgmntData(undefined);
    setMessagesIpData(undefined);
    setSingleData(undefined);
    setObjectData(undefined);
    setReportType('count_licence_usage');
  };

  const handleGeneralData = (data?: ReportGeneralResponse) => {
    setGeneralData(data);
    setDetailData(undefined);
    setLicenceData(undefined);
    setUsersMgmntData(undefined);
    setMessagesIpData(undefined);
    setSingleData(undefined);
    setObjectData(undefined);
    setReportType('violence');
  };

  const handleUsersMgmntData = (data?: UsersMgmntReportResponse) => {
    setGeneralData(undefined);
    setDetailData(undefined);
    setLicenceData(undefined);
    setUsersMgmntData(data);
    setMessagesIpData(undefined);
    setSingleData(undefined);
    setObjectData(undefined);
    setReportType('users_mgmnt');
  };

  const handleMessagesIpData = (reportType: ReportType, data: MessagesIp[]) => {
    setGeneralData(undefined);
    setDetailData(undefined);
    setLicenceData(undefined);
    setUsersMgmntData(undefined);
    setMessagesIpData({reportType, data});
    setSingleData(undefined);
    setObjectData(undefined);
    setReportType('messages_ip');
  };

  const handleSingleData = (reportType: ReportType, data: ReportSingleResponse) => {
    setGeneralData(undefined);
    setDetailData(undefined);
    setLicenceData(undefined);
    setUsersMgmntData(undefined);
    setMessagesIpData(undefined);
    setSingleData({reportType, data});
    setObjectData(undefined);
    setReportType(reportType);
  };

  const handleObjectData = (reportType: ReportType, data: object) => {
    setGeneralData(undefined);
    setDetailData(undefined);
    setLicenceData(undefined);
    setUsersMgmntData(undefined);
    setMessagesIpData(undefined);
    setSingleData(undefined);
    setObjectData({reportType, data});
    setReportType(reportType);
  };

  if (!violenceTypes.length) return null;

  // eslint-disable-next-line no-magic-numbers
  const isTransitionState =
    (dataGeneral ? 1 : 0) + (dataDetail ? 1 : 0) + (dataLicence ? 1 : 0) + (usersMgmntData ? 1 : 0) + (messagesIpData ? 1 : 0) + (dataSingle ? 1 : 0) + (objectData ? 1 : 0) > 1;

  const title = dataGeneral
    ? t('violence_types')
    : dataDetail
    ? t('licence_use')
    : dataLicence
    ? t('count_licence_usage')
    : usersMgmntData
    ? t('users_mgmnt')
    : messagesIpData
    ? t(messagesIpData.reportType)
    : dataSingle
    ? t(dataSingle.reportType)
    : objectData
    ? t(objectData.reportType)
    : 'unknown';

  const url = isDirector ? `${urlDirector}/${schoolId}` : urlAdmin;

  return (
    <Wrapper alignItems="stretch">
      <ChartContainer>
        {!isTransitionState && (dataGeneral || dataDetail || dataLicence || usersMgmntData || dataSingle || objectData || messagesIpData) ? (
          <>
            <FlexDiv justifyContent="space-between" alignItems="center" width="100%">
              <PageTitle title={title} />
              <Anchor href={`${url}/${reportType}/csv${reportParams}`} label={t('download_report')} color="secondary" download />
            </FlexDiv>
            <ChartDetailStyled>
              {(dataGeneral || dataDetail || dataSingle || dataLicence) ? (
                <ChartPanel
                  dataGeneral={dataGeneral}
                  dataDetail={dataDetail}
                  dataLicence={dataLicence}
                  dataSingle={dataSingle}
                />
              ) : (
                <>
                  {usersMgmntData && <UsersManagement reportParams={reportParams} schoolId={schoolId} />}
                  {objectData && <ObjectReport objectData={objectData} />}
                  {messagesIpData && <MessagesIpReport objectData={messagesIpData} />}
                </>
              )}
            </ChartDetailStyled>
          </>
        ) : (
          <FlexDiv flexDirection="column" alignItems="center">
            <PageTitle title={t('no_generated_raports')} />
            <ChartDetailStyled>
              <img src={circles} alt={t('chart_placeholder')} />
              <ReportsTip>{t('reports_tip')}</ReportsTip>
            </ChartDetailStyled>
          </FlexDiv>
        )
        }
      </ChartContainer>
      <Criteria
        setGeneralData={handleGeneralData}
        setDetailData={handleDetailData}
        setLicenceData={handleLicenceData}
        setUsersMgmntData={handleUsersMgmntData}
        setMessagesIpData={handleMessagesIpData}
        setReportType={setReportType}
        setReportParams={setReportParams}
        setSchooldId={setSchooldId}
        setSingleData={handleSingleData}
        setObjectData={handleObjectData}
      />
    </Wrapper>
  );
};

export default Reports;
