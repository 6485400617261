import React from 'react';

import { NoDataStyled } from './styles';

interface NoDataProps {
  title?: string;
}

const NoData: React.FC<NoDataProps> = ({ title }) => (
  <NoDataStyled justifyContent="center" alignItems="center" alignContent="center">
    {title && title}
  </NoDataStyled>
);

export default NoData;
