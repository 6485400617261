import { createGlobalStyle } from 'styled-components';

import colors from './colors';
import { FONT_PRIMARY } from './globalVariables';

const GlobalStyle = createGlobalStyle`
  html, #root {
    width: 100%;
    height: 100%;
    -webkit-scrollbar-width: thin;
    scrollbar-width: thin;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: ${FONT_PRIMARY}, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue';
    font-size: 14px;
    color: ${colors.gray};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    overscroll-behavior-y: none;
  }
  
  .tox {
    width: 100%;
  }
  
  ::-webkit-scrollbar {
    margin-right: 8px;
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.gray_scale5};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray_scale6};
    transition: background 0.15s linear;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.gray_scale3};
  }
`;

export default GlobalStyle;
