import React, { Suspense, Dispatch, createContext, useReducer, useEffect, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Navigate, Routes } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

import GlobalStyle from 'styles/globalStyle';
import useBrowserStorage from 'utils/browserStorage';

import TranslationsLoader from 'components/TranslationsLoader';
import HomeApp from 'pages/HomeApp';

import reducer, { Action, State, initialState } from './reducer';

type ContextProps = [State, Dispatch<Action>];

const AppContext = createContext({} as ContextProps);

export const useApp = () => useContext(AppContext);

const App: React.FC = () => {
  const [value, setValue] = useBrowserStorage<State>('userData', initialState);
  const values = useReducer(reducer, value);

  useEffect(() => {
    // eslint-disable-next-line no-magic-numbers
    setValue(values[0]);
  }, [values, setValue]);

  return (
    <>
      <GlobalStyle />
      <AppContext.Provider value={values}>
        <BrowserRouter>
          <Routes>
            <Route path="app/*" element={<HomeApp />} />
            <Route path="*" element={<Navigate to="/app" />} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
};

const WrappedApp = () => (
  <StyleSheetManager
    shouldForwardProp={(prop, target) => (typeof target === 'function' ? true : isPropValid(prop))}
  >
    <Suspense fallback={<TranslationsLoader />}>
      <App />
    </Suspense>
  </StyleSheetManager>
);

export default WrappedApp;
