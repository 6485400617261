import React from 'react';

import { EmptyListStyled } from './styles';

interface EmptyListProps {
  children?: React.ReactNode;
}

const EmptyList: React.FC<EmptyListProps> = ({ children }) => (
  <EmptyListStyled justifyContent="center" alignItems="center" alignContent="center">
    {children}
  </EmptyListStyled>
);

export default EmptyList;
