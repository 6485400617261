import styled from 'styled-components';

import { HEADER_HEIGHT } from './constants';

export const HeaderStyled = styled.header`
	grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
	padding: 10px 0;
	height: ${HEADER_HEIGHT};
`;

export const LogoStyled = styled.img<{ width: string }>`
	width: ${({ width }) => width};
	margin: 0 30px;
	max-height: 100%;
	cursor: pointer;
`;
