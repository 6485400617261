import React from 'react';

import usePrefix from 'utils/usePrefix';

import PageTitle from 'components/PageTitle';
import Loader from 'components/Loader';
import NoResults from 'components/NoResults';
import EmptyList from 'components/EmptyList';
import SearchBar from 'components/SearchBar';
import FlexDiv from 'components/FlexDiv';
import Button from 'components/Button';

import { Header, Content, UsersWrapper, UsersList, GridRow, ButtonContainer } from './styles';

interface Props {
  children?: React.ReactNode;
  title: string;
  isLoading: boolean;
  isData: boolean;
  labelNoResults: string;
  labelLoader: string;
  labelButton?: string;
  filter: string;
  setFilter: (val: string) => void;
  setModal?: ({ isOpen }: { isOpen: boolean }) => void;
  resetFilter?: () => void;
  isSearchClearDisabled?: boolean;
}

const TableContainer: React.FC<Props> = ({
  title,
  isLoading,
  isData,
  setFilter,
  filter,
  labelLoader,
  labelNoResults,
  labelButton,
  setModal,
  children,
  resetFilter,
  isSearchClearDisabled,
}) => {
  const t = usePrefix('General');

  return (
    <>
      <Header>
        <GridRow>
          <PageTitle title={title} />
          <FlexDiv
            flex={1}
            justifyContent="flex-start"
            alignItems="flex-start"
            alignSelf="flex-start"
          >
            <SearchBar
              name="filter"
              placeholder={t('search')}
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
              onClear={resetFilter}
              isDisabled={isSearchClearDisabled}
            />
            {setModal && (
              <ButtonContainer justifyContent="flex-end" alignContent="center">
                <Button label={labelButton} onClick={() => setModal({ isOpen: true })} />
              </ButtonContainer>
            )}
          </FlexDiv>
        </GridRow>
      </Header>
      <Content>
        <UsersWrapper>
          {isLoading && <Loader label={labelLoader} />}
          {!isLoading && !isData && (
            <EmptyList>
              <NoResults title={labelNoResults} />
            </EmptyList>
          )}
          {!!isData && <UsersList>{children}</UsersList>}
        </UsersWrapper>
      </Content>
    </>
  );
};

export default TableContainer;
