import styled, { css } from 'styled-components';
import { Form } from 'formik';

import colors from 'styles/colors';
import { CLOSE_ICON_SIZE } from 'components/Wrapper';
import InputField from 'components/InputField';
import FormField from 'components/FormField';
import FlexDiv from 'components/FlexDiv';
import { Attachments } from 'components/AttachmentsList/styles';

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px calc(${CLOSE_ICON_SIZE} * 2);
  width: 100%;
  overflow: auto;
  &:not(:first-of-type) {
    border-top: 1px solid ${colors.gray_shadow_03};
  }
`;

export const Content = styled(Form)`
  ${contentStyles};
`;

export const ButtonsContainer = styled(FlexDiv)`
  > button {
    margin-left: 20px;
  }
`;

const INPUT_GROUP_WIDTH = '372px';

export const StyledFormField = styled(FormField)`
  width: ${INPUT_GROUP_WIDTH};
  margin-bottom: 23px;
`;

export const StyledInputField = styled(InputField)`
  width: ${INPUT_GROUP_WIDTH};
  margin-bottom: 24px;
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  width: 880px;
  margin-top: 24px;
`;

export const Title = styled.h3`
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
`;

export const EditorWrapper = styled.div<{ error: boolean }>`
  width: 100%;
  ${({ error }) =>
    error &&
    css`
      .tox {
        border-color: ${colors.error};
      }
    `};
  ${Attachments} {
    margin-top: 24px;
    padding: 20px;
    border: 1px solid ${colors.black_shadow};
  }
`;

export const FieldTitle = styled(FlexDiv)`
  margin-bottom: 4px;
  font-size: 11px;
`;

export const Label = styled.label`
  margin-left: 12px;
`;

export const EditorMessage = styled.div`
  color: ${colors.error};
  &:before {
    content: '\\00a0';
  }
`;
