import React, { useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { getApiUrl, PaginatedList } from 'utils/api';
import { SchoolResponse } from 'utils/api/schools';
import { useApi } from 'utils/api/useApi';
import usePrefix from 'utils/usePrefix';

import FlexDiv from 'components/FlexDiv';
import TableContainer from 'components/TableContainer';
import Row from 'components/Row';

import School from './School';

const url = getApiUrl('/schools');

const RECORD_COUNT = 9999999;

export interface ModalState {
  isOpen: boolean;
  schoolId?: number;
}

const Schools: React.FC = () => {
  const t = usePrefix('Schools');

  const [modal, setModal] = useState<ModalState>({ isOpen: false });
  const [nameFilter, setNameFilter] = useState('');

  const MIN_QUERY_LENGTH = 1;

  const { data, isLoading, fetchData } = useApi<PaginatedList<SchoolResponse[]>>(
    nameFilter.length < MIN_QUERY_LENGTH
      ? `${url}?page=0&size=${RECORD_COUNT}`
      : `${url}?name=${nameFilter}&page=0&size=${RECORD_COUNT}`,
    {
      method: 'GET',
    }
  );

  const handleFilterReset = () => {
    setNameFilter('');
  };

  if (!data) return null;

  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex={1}
    >
      <TableContainer
        title={t('schools')}
        isLoading={isLoading}
        isData={!!data?.content.length}
        setFilter={setNameFilter}
        labelLoader={t('fetching_schools')}
        labelNoResults={t('no_schools')}
        labelButton={t('add_school')}
        filter={nameFilter}
        setModal={setModal}
        resetFilter={handleFilterReset}
        isSearchClearDisabled={nameFilter.length < 1}
      >
        <AutoSizer disableWidth>
          {({ height }: { height: number }) => (
            <List
              height={height}
              width="100%"
              itemCount={data.content.length}
              itemSize={48}
              innerElementType="ul"
            >
              {({ index, ...props }) => (
                <Row
                  title={data.content[index].name}
                  labels={[
                    data.content[index].name,
                    data.content[index].voivodeship,
                    data.content[index].locality,
                    data.content[index].street,
                    data.content[index].number,
                  ]}
                  onClick={() => setModal({ isOpen: true, schoolId: data.content[index].id })}
                  {...props}
                />
              )}
            </List>
          )}
        </AutoSizer>
      </TableContainer>

      {modal.isOpen && (
        <School
          schools={data?.content}
          schoolId={modal.schoolId}
          onClose={() => setModal({ isOpen: false })}
          fetchData={fetchData}
          isOpen={modal.isOpen}
        />
      )}
    </FlexDiv>
  );
};

export default Schools;
