import styled from 'styled-components';
import FlexDiv from 'components/FlexDiv';
import colors from 'styles/colors';

export const Value = styled.div`
  padding-left: 8px;
`;

export const Label = styled.span`
  white-space: nowrap;
`;

export const ActionButtons = styled(FlexDiv)`
  margin-top: 24px;
`;

export const ButtonSaveItemStyled = styled('div')`
  position: relative;
`;

export const TooltipStyled = styled('div')`
  position: absolute;
  right: 0;
  bottom: 100%;
  z-index: 1;
  transform: translate3d(0, -10px, 0);
  padding: 8px;
  min-width: 144px;
  width: fit-content;
  max-width: 200px;
  border-radius: 4px;
  background: #fff;
  color: ${colors.brand_2};
  filter: drop-shadow(0 0 8px ${colors.gray_shadow_03});

  &:before {
    content: '';
    position: absolute;
    right: 16px;
    bottom: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;
