import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';

import usePrefix from 'utils/usePrefix';
import { useApp } from 'App';
import {
  ReportGeneralResponse,
  ReportDetailResponse,
  ReportSingleResponse,
  LicenseReportResponse,
} from 'utils/api/report';
import colors from 'styles/colors';

import FlexDiv from 'components/FlexDiv';
import EmptyList from 'components/EmptyList';
import NoResults from 'components/NoResults';

import { pieChartColors } from './styles';
import { ReportType } from '../Criteria';
import { Content } from '../styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);

const chartOptionsDoughnut = ({ text }: { text: string }): ChartOptions<'doughnut'> => ({
  responsive: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 60,
        color: colors.gray_scale10,
        font: {
          size: 16,
        },
      },
    },
    title: {
      display: true,
      text,
    },
  },
  cutout: '75%',
  radius: '90%',
  offset: 0,
});

const chartOptionsLine = ({ axis }: { axis?:any }): ChartOptions<'line'> => ({
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        boxWidth: 60,
        color: colors.gray_scale10,
        font: {
          size: 16,
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: axis.xAxis,
        font: {
          family: 'Source Sans Pro',
          size: 16,
          weight: '400',
          lineHeight: 1.2,
        }
      }
    },
    y: {
      display: true,
      title: {
        display: true,
        text: axis.yAxis,
        font: {
          family: 'Source Sans Pro',
          size: 16,
          weight: '400',
          lineHeight: 1.2,
        }
      },
    }
  }
});

interface Props {
  dataDetail?: ReportDetailResponse[];
  dataLicence?: LicenseReportResponse[];
  dataGeneral?: ReportGeneralResponse;
  dataSingle?: {reportType: ReportType, data: ReportSingleResponse};
}

const ChartPanel: React.FC<Props> = ({
  dataDetail,
  dataLicence,
  dataGeneral,
  dataSingle
}) => {
  const [reportData, setReportData] = useState<ChartData | undefined>();
  const [report2Data, setReport2Data] = useState<ChartData | undefined>();
  const [reportOptions, setOptions] = useState<any | undefined>();

  const [{ violenceTypes }] = useApp();

  const t = usePrefix('Reports');
  const tv = usePrefix('Violence');

  useEffect(() => {
    if (!!dataDetail || !!dataLicence || !!dataGeneral || !!dataSingle) {
      if (dataGeneral) {
        const intervenerViolenceStats = dataGeneral.intervener_violation_stats
          .map((stat) => {
            const vType = violenceTypes.find((violence) => violence.id === stat.violence_id);
            return { label: vType ? tv(vType.key) : t('invalid_key'), occurence: stat.occurrence };
          })
          .filter((stat) => !!stat.occurence);
        setReportData(
          intervenerViolenceStats.length
            ? {
                labels: intervenerViolenceStats.map((stat) => stat.label),
                datasets: [
                  {
                    label: t('intervener_violation_stats'),
                    data: intervenerViolenceStats.map((stat) => stat.occurence),
                    backgroundColor: pieChartColors,
                  },
                ],
              }
            : undefined
        );
        const reporterViolenceStats = dataGeneral.reporter_violation_stats
          .map((stat) => {
            const vType = violenceTypes.find((violence) => violence.id === stat.violence_id);
            return { label: vType ? tv(vType.key) : t('invalid_key'), occurence: stat.occurrence };
          })
          .filter((stat) => !!stat.occurence);
        setReport2Data(
          reporterViolenceStats.length
            ? {
                labels: reporterViolenceStats.map((stat) => stat.label),
                datasets: [
                  {
                    label: t('reporter_violation_stats'),
                    data: reporterViolenceStats.map((stat) => stat.occurence),
                    backgroundColor: pieChartColors,
                  },
                ],
              }
            : undefined
        );
      } else if (dataSingle) {
        setReportData({
          labels: Object.keys(dataSingle.data.data),
          datasets: [
            {
              label: t(dataSingle.reportType),
              data: Object.values(dataSingle.data.data).map((stat) => Number(stat)),
              backgroundColor: colors.brand_2,
              borderColor: colors.brand_2,
              fill: 'disabled',
            }
          ],
        });
        setReport2Data(undefined);
        setOptions(
          dataSingle.reportType === 'count_reporter_messages' ?
            {
              xAxis: t('param_count_reporter_messages'),
              yAxis: t('number_of_students')
            } : dataSingle.reportType === 'count_reporter_hours' ? {
              xAxis: t('count_reporter_hours'),
              yAxis: t('count_reporter_messages')
            } : dataSingle.reportType === 'count_intervener_login' ? {
              xAxis: t('param_count_intervener_login'),
              yAxis: t('number_of_interveners')
            } : {
              xAxis: undefined,
              yAxis: undefined
            }
        )
      } else if (dataDetail) {
        if (!dataDetail.length) {
          setReportData(undefined);
          setReport2Data(undefined);
          setOptions(undefined);
          return;
        }
        setReportData({
          labels: dataDetail.map((stat) => stat.date),
          datasets: [
            {
              label: t('max_licence_limit'),
              data: dataDetail.map((stat) => stat.max_number_of_reporters),
              backgroundColor: colors.brand_2,
              borderColor: colors.brand_2,
              fill: 'disabled',
            },
            {
              label: t('licence_use'),
              data: dataDetail.map((stat) => stat.number_of_licensed_reporters),
              backgroundColor: colors.brand_1,
              borderColor: colors.brand_1,
              fill: 'disabled',
            },
            {
              label: t('nonanonim_chat_number'),
              data: dataDetail.map((stat) => stat.number_of_regular_chats),
              backgroundColor: colors.brand_6,
              borderColor: colors.brand_6,
              fill: 'disabled',
            },
          ],
        });
        setReport2Data(undefined);
        setOptions(
          {
            xAxis: t('time_range'),
            yAxis: t('licence_use')
          }
        )
      } else if (dataLicence) {
        if (!dataLicence?.length) {
          setReportData(undefined);
          setReport2Data(undefined);
          setOptions(undefined);
          return;
        }
        setReportData({
          labels: dataLicence.map((stat) => stat.to),
          datasets: [
            {
              label: t('licences_used'),
              data: dataLicence.map((stat) => stat.sumUsed),
              backgroundColor: colors.brand_1,
              borderColor: colors.brand_1,
              fill: 'disabled',
            },
            {
              label: t('licences_max'),
              data: dataLicence.map((stat) => stat.sumMax),
              backgroundColor: colors.brand_2,
              borderColor: colors.brand_2,
              fill: 'disabled',
            },
          ],
        });
        setReport2Data(undefined);
        setOptions(
          {
            xAxis: t('param_count_licence_usage'),
            yAxis: t('count_licence_usage')
          }
        )
      } else {
        setReportData(undefined);
        setReport2Data(undefined);
        setOptions(undefined);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetail, dataGeneral, dataLicence, dataSingle]);

  return reportData || report2Data ? (
    <>
      {dataGeneral ? (
        <>
          {reportData && (
            <Doughnut
              data={reportData as ChartData<'doughnut'>}
              options={chartOptionsDoughnut({ text: t('intervener_violation_stats') })}
            />
          )}
          {report2Data && (
            <Doughnut
              data={report2Data as ChartData<'doughnut'>}
              options={chartOptionsDoughnut({ text: t('reporter_violation_stats') })}
            />
          )}
        </>
      ) : (
        reportData && <Line data={reportData as ChartData<'line'>} options={chartOptionsLine({ axis: reportOptions })} />
      )}
    </>
  ) : (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex="1"
      width="100%"
    >
      <Content>
        <EmptyList>
          <NoResults title={t('no_data')} />
        </EmptyList>
      </Content>
    </FlexDiv>
  );
};

export default ChartPanel;
