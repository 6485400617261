import React from 'react';

import colors from 'styles/colors';
import { IconName } from 'components/Icons';

import { ListItem, NavItem, StyledIcon } from './styles';

interface Props {
  icon: IconName;
  to: string;
  label?: string;
}

const NavigationItem: React.FC<Props> = ({ to, icon, label}) => (
  <ListItem>
    <NavItem to={to} title={label}>
      <StyledIcon type={icon} color={colors.white} />
      <span>{label}</span>
    </NavItem>
  </ListItem>
);

export default NavigationItem;
