import styled from 'styled-components';

import { ANIMATION } from 'styles/globalVariables';
import colors from 'styles/colors';

export const DataRowStyled = styled.li<{
  clickable: boolean;
  selected: boolean;
  disabled: boolean;
  unread: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
  padding: 4px 16px;
  min-height: 48px;
  border: 1px solid ${({ selected }) => (selected ? colors.brand_1 : 'rgba(151, 151, 151, 0.3)')};
  background-color: ${({ selected, unread }) =>
    selected ? colors.brand_shadow_005 : unread ? colors.brand_2 : colors.white};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ disabled, unread }) =>
    unread ? colors.white : disabled ? colors.gray_scale4 : colors.black};
  transition: background-color ${ANIMATION};
  &:not(:first-child) {
    border-top-width: 0;
  }
  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        background-color: #f7f7f7;
        color: ${colors.black}
      }
    }
  `};
`;
