import styled from 'styled-components';

import colors from 'styles/colors';
import FlexDiv from 'components/FlexDiv';

export const NoDataStyled = styled(FlexDiv)`
  padding: 8px 16px;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.black};
  text-align: center;
`;
