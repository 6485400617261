import React from 'react';
import styled from 'styled-components';

import colors from 'styles/colors';

import Icon from 'components/Icons';

interface Props {
  children?: React.ReactNode;
  onClose(): void;
}

export const CLOSE_ICON_SIZE = '46px';

const Wrapper: React.FC<Props> = ({ children, onClose }) => (
  <Container>
    <StyledIcon type="close" width={CLOSE_ICON_SIZE} height={CLOSE_ICON_SIZE} onClick={onClose} />
    {children}
  </Container>
);

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 208px;
  bottom: 0;
  right: 0;
  background: white;
  box-shadow: 0 0 64px 0 ${colors.gray_shadow_05};
  padding: 24px 0;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  left: calc(45px / 2);
`;

export default Wrapper;
