import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import { Form } from 'formik';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';
import { screenSmAbove } from 'styles/breakpoinst';

import { TEXT_AREA_HEIGHT } from 'components/TextArea/styles';
import Icon from 'components/Icons';
import FlexDiv from 'components/FlexDiv';

interface MessageProps {
  intervenerMsg?: boolean;
}

const chatNotesOpenStyles = css`
  padding-right: 0px;
`;

export const Wrapper = styled(FlexDiv)<{ chatPadding: boolean }>`
  padding: 0;
  width: 100%;
  ${({ chatPadding }) => chatPadding && chatNotesOpenStyles};
  @media screen and (min-width: ${screenSmAbove}) {
    border-right: 1px solid ${colors.gray_shadow_03};
  }
`;

export const MessagesPanel = styled(FlexDiv)`
  width: 100%;
  overflow: hidden;
`;

export const MessageWrapper = styled(FlexDiv)`
  margin-bottom: 16px;
  width: 100%;
  color: ${colors.gray};
`;

export const MessageDate = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.4;
`;

export const MessageContainer = styled.div<MessageProps>`
  padding: 8px 16px;
  border-radius: 8px;
  border: solid 0.5px
    ${({ intervenerMsg }) => (intervenerMsg ? colors.brand_1 : colors.gray_scale6)};
  background-color: ${({ intervenerMsg }) =>
    intervenerMsg ? colors.brand_2_shadow_02 : colors.gray_scale5};
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
`;

export const IconContainer = styled.div`
  margin: 0 8px;

  & > svg {
    cursor: pointer;
  }
`;

export const MessageData = styled.div`
  max-width: 55%;
`;

export const MessageAuthor = styled.div<MessageProps>`
  width: 100%;
  min-width: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.brand_2};
  ${({ intervenerMsg }) =>
    intervenerMsg &&
    css`
      text-align: right;
    `}
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 32px;
  height: ${TEXT_AREA_HEIGHT};
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  path {
    fill: ${colors.brand_shadow_05};
    transition: color ${ANIMATION};
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    path {
      fill: ${colors.gray_scale4};
    }
  }
  &:hover {
    path {
      fill: ${colors.brand_2};
    }
  }
  &:focus {
    outline: none;
  }
`;

export const PanelWrapper = styled.div`
  padding: 8px 24px;
  width: 100%;
  height: calc(100% - ${TEXT_AREA_HEIGHT});
  overflow: auto;
`;

export const AnswerMessage = styled.div`
  padding: 16px 24px;
  width: 100%;
`;

export const FormStyled = styled(Form)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${colors.gray_scale5};
`;

export const StyledScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column-reverse;
`;

export const NicknameInputContainer = styled.div`
  width: 200px;
`;

export const NicknameContainer = styled(FlexDiv)`
  cursor: pointer;
`;

export const IconStyled = styled(Icon)`
  margin-left: 8px;
`;

const StyledImage = styled.img`
  max-height: 180px;
  object-fit: cover;
`;

export const AttachmentLink = styled.a`
  font-size: 13px;
  color: ${colors.gray};
`;

interface ImageProps {
  src: string;
  mimeType: string;
  target: string;
  alt: string;
}

export const AttachmentImage: FC<ImageProps> = ({ src, mimeType, target, alt }) => (
  <a href={src} type={mimeType} target={target}>
    <StyledImage src={src} alt={alt} />
  </a>
);
