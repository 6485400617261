/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ReactSelect from 'react-select';

import { api, getApiUrl, PaginatedList, ResponseError } from 'utils/api';
import { ChatItem, CityResponse, SchoolResponse, VoivodeshipResponse } from 'utils/api/schools';
import {
  LicenseReportResponse,
  MessagesIp,
  MessagesIpResponse,
  ReportDetailResponse,
  ReportGeneralResponse,
  ReportSingleResponse,
  UsersMgmntReportResponse,
} from 'utils/api/report';
import { userRoles } from 'utils/userRoles';
import usePrefix from 'utils/usePrefix';
import { useApp } from 'App';
import { notificationTypes } from 'utils/constants';
import { ActionTypes } from 'App/types';

import FormField from 'components/FormField';
import InputField from 'components/InputField';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import { customSelectStyles } from 'components/Select/styles';

import { DateCell, DateRow, Separator, Wrapper } from './styles';
import FetchingMessage from '../FetchingMessage';
import {
  COUNT_INTERVENER_LOGIN_DEFAULT_PARAM,
  COUNT_LICENCE_USAGE_DEFAULT_PARAM,
  COUNT_REPORTER_MESSAGES_DEFAULT_PARAM
} from './constants';

const urlSchools = getApiUrl('/director/me/schools');
const urlSchoolsAdmin = getApiUrl('/schools');

const urlReports = getApiUrl('/director/me/reports');
const urlReportsAdmin = getApiUrl('/reports');

const paramReportTypes: ReportType[] =['count_intervener_login', 'count_reporter_messages', 'count_licence_usage']

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    type: Yup.object().shape({
      value: Yup.string().required(ty('field_required')),
      label: Yup.string().required(ty('field_required')),
    }),
    locationTarget: Yup.object().shape({
      value: Yup.boolean().required(ty('field_required')),
      label: Yup.string().required(ty('field_required')),
    }),
    date_from: Yup.date()
      .min('2020-01-01', ty('reports_min_date_from'))
      .max(Yup.ref('date_to'), ty('reports_max_date_from'))
      .required(),
    date_to: Yup.date()
      .min(Yup.ref('date_from'), ty('reports_min_date_to'))
      .max(dayjs().subtract(0, 'day').format('YYYY-MM-DD'), ty('reports_max_date_to'))
      .required(),
    hourRes: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
    param: Yup.string()
  });

const ipValidationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    type: Yup.object().shape({
      value: Yup.string().required(ty('field_required')),
      label: Yup.string().required(ty('field_required')),
    })
  });

export type ReportType = 'violence' | 'licence' | 'users_mgmnt' | 'count_intervener_login' | 'count_reporter_messages' | 'count_reporter_hours' | 'count_licence_usage' | 'school_stats' | 'messages_stats' | 'messages_ip'
export type LocationType = 'school' | 'locality' | 'voivodeship';

interface TypeOption {
  value: ReportType;
  label: string;
}

interface HourResOption {
  value: string;
  label: string;
}


interface LocationTypeOption {
  value: LocationType;
  label: string;
}

interface LocationTargetOption {
  value: boolean;
  label: string;
}

export type LocationSpecificTargetType = number | string | null;

interface LocationSpecificTargetOption {
  value: LocationSpecificTargetType;
  label: string;
}

interface EntityOption {
  value: number;
  label: string;
}

interface ChatEntityOption extends EntityOption {
  idx: number;
}

interface FormValues {
  type: TypeOption;
  locationTarget: LocationTargetOption;
  locationTargetSpecific?: LocationSpecificTargetOption;
  date_from: string;
  date_to: string;
  hourRes: HourResOption | undefined;
  param: string | undefined;
}

interface IpFormValues {
  type: TypeOption;
  chatSchool?: EntityOption;
  chat?: ChatEntityOption;
  dateTime: string;
}

interface Props {
  setGeneralData: (data?: ReportGeneralResponse) => void;
  setDetailData: (data?: ReportDetailResponse[]) => void;
  setLicenceData: (data?: LicenseReportResponse[]) => void;
  setUsersMgmntData: (data?: UsersMgmntReportResponse) => void;
  setMessagesIpData: (reportType: ReportType, data: MessagesIp[]) => void;
  setReportParams: (params: string) => void;
  setReportType: (params: ReportType | undefined) => void;
  setSchooldId: (schoolId: LocationSpecificTargetType | undefined) => void;
  setSingleData: (reportType: ReportType, data: ReportSingleResponse) => void;
  setObjectData: (reportType: ReportType, data: object) => void;
}

const Criteria: React.FC<Props> = ({
  setGeneralData,
  setDetailData,
  setLicenceData,
  setUsersMgmntData,
  setMessagesIpData,
  setReportParams,
  setReportType,
  setSchooldId,
  setSingleData,
  setObjectData
}) => {
  const PAGE_START = 0;
  const PAGE_SIZE = 20;

  const [{ profile }, dispatch] = useApp();

  const isDirector = profile?.role === userRoles.director;

  const t = usePrefix('Reports');
  const tg = usePrefix('General');
  const ty = usePrefix('YupErrors');

  const typeOptions: TypeOption[] = isDirector
    ? [
        { value: 'violence', label: t('violence_types') },
        { value: 'users_mgmnt', label: t('users_mgmnt') },
      ]
    : [
        { value: 'violence', label: t('violence_types') },
        { value: 'messages_stats', label: t('messages_stats') },
        { value: 'count_reporter_messages', label: t('count_reporter_messages') },
        { value: 'count_reporter_hours', label: t('count_reporter_hours') },
        { value: 'licence', label: t('licence_use') },
        { value: 'count_licence_usage', label: t('count_licence_usage') },
        { value: 'school_stats', label: t('school_stats') },
        { value: 'count_intervener_login', label: t('count_intervener_login') },
        { value: 'users_mgmnt', label: t('users_mgmnt') },
        { value: 'messages_ip', label: t('messages_ip') },
    ];

  const locationTypeOptions: LocationTypeOption[] = [
    { value: 'school', label: t('school') },
    { value: 'locality', label: t('locality') },
    { value: 'voivodeship', label: t('voivodeship') },
  ];

  const hoursOptions: HourResOption[] = [
    { value: '1', label: t('res1h') },
    { value: '2', label: t('res2h') },
    { value: '3', label: t('res3h') },
    { value: '4', label: t('res4h') },
    { value: '6', label: t('res6h') },
    { value: '8', label: t('res8h') },
    { value: '12', label: t('res12h') },
  ];

  const locationTargetOptions: LocationTargetOption[] = [
    { value: false, label: t('as_choosen') },
    { value: true, label: t('all') },
  ];

  const [criteriaType, setCriteriaType] = useState<{type: TypeOption, reset: boolean }>({type: typeOptions[0], reset: false});
  const [locationFilter, setLocationFilter] = useState('');
  const [locationType, setLocationType] = useState<LocationTypeOption>(locationTypeOptions[0]);
  const [locationSpecificTargetOptions, setLocationSpecificTargetOptions] = useState<
    LocationSpecificTargetOption[]
  >([]);
  const [reportsFetching, setReportsFetching] = useState(false);

  const [chatSchoolOptions, setChatSchoolOptions] = useState<
    EntityOption[]
  >([]);
  const [chatSchoolFilter, setChatSchoolFilter] = useState('');
  const [chatSchoolOption, setChatSchoolOption] = useState<
    EntityOption | undefined
  >(undefined);
  const [chatOptions, setChatOptions] = useState<
    EntityOption[]
  >([]);
  const [chatFilter, setChatFilter] = useState('');



  const initialValues: FormValues = {
    type: typeOptions[0],
    locationTarget: locationTargetOptions[0],
    date_from: '2020-01-01',
    date_to: dayjs().subtract(0, 'day').format('YYYY-MM-DD'),
    hourRes: undefined,
    param: undefined,
  };

  const initialIpFormValues: IpFormValues = {
    type: typeOptions[9],
    chat: undefined,
    chatSchool: undefined,
    dateTime: dayjs().format('YYYY-MM-DDTHH:mm'),
  };

  const getSchools = async () => {
    const postfix = locationFilter ? `?name=${locationFilter}` : '';

    try {
      const response: SchoolResponse[] = await api(`${urlSchools}${postfix}`, {
        method: 'GET',
      });

      if (response?.length)
        setLocationSpecificTargetOptions(
          response.map((school) => ({ value: school.id, label: school.name }))
        );
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getAdminSchools = async () => {
    try {
      const response: PaginatedList<SchoolResponse[]> = await api(
        `${urlSchoolsAdmin}?page=${PAGE_START}&size=${PAGE_SIZE}&name=${locationFilter}`,
        {
          method: 'GET',
        }
      );

      if (response?.content.length)
        setLocationSpecificTargetOptions(
          response.content.map((school) => ({ value: school.id, label: school.name }))
        );
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getCities = async () => {
    try {
      const response: PaginatedList<CityResponse[]> = await api(
        `${urlSchoolsAdmin}/cities?page=${PAGE_START}&size=${PAGE_SIZE}&pattern=${locationFilter}`,
        {
          method: 'GET',
        }
      );

      if (response?.content.length) {
        setLocationSpecificTargetOptions(
          response.content.map((city) => ({ value: city.city, label: city.city }))
        );
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getVoivodeships = async () => {
    try {
      const response: VoivodeshipResponse[] = await api(`${urlSchoolsAdmin}/voivodeshipList`, {
        method: 'GET',
      });

      if (response.length)
        setLocationSpecificTargetOptions(
          response.map((voiv) => ({ value: voiv.voivodeship, label: voiv.voivodeship }))
        );
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getChatSchools = async () => {
    try {
      const response: PaginatedList<SchoolResponse[]> = await api(
        `${urlSchoolsAdmin}?page=${PAGE_START}&size=${PAGE_SIZE}&name=${chatSchoolFilter}`,
        {
          method: 'GET',
        }
      );

      if (response?.content.length)
        setChatSchoolOptions(
          response.content.map((school) => ({ value: school.id, label: school.name }))
        );
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const getChats = async () => {
    try {
      if (!chatSchoolOption) return;

      const response: PaginatedList<ChatItem[]> = await api(
        `${urlSchoolsAdmin}/chats?page=${PAGE_START}&size=${9999999}&schoolId=${chatSchoolOption.value}&pattern=${chatFilter}`,
        {
          method: 'GET',
        }
      );

      setChatOptions(
        response.content.map((item) => ({ value: item.id, label: item.name ? item.name : `${tg('student')} ${item.idx}` }))
      );

    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    if (!!locationType && 'value' in locationType) {
      if (profile?.role === 'DIRECTOR') {
        if (!locationFilter || locationFilter.length > 2) getSchools();
        return;
      }

      switch (locationType.value) {
        case 'voivodeship':
          getVoivodeships();
          break;
        case 'locality':
          if (locationFilter.length > 2) getCities();
          break;
        case 'school':
          if (locationFilter.length > 2) getAdminSchools();
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationFilter, locationType]);

  useEffect(() => {
    if (chatSchoolFilter.length > 2) {
      getChatSchools()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSchoolFilter]);

  useEffect(() => {
    if (chatSchoolOption)
      getChats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSchoolOption, chatFilter]);

  useEffect(() => {
    if (criteriaType.reset) {
      initialValues.type = criteriaType.type;
      switch (criteriaType.type.value) {
        case 'count_intervener_login':
          initialValues.param = COUNT_INTERVENER_LOGIN_DEFAULT_PARAM;
          initialValues.hourRes = undefined;
          break;
        case 'count_reporter_messages':
          initialValues.param = COUNT_REPORTER_MESSAGES_DEFAULT_PARAM;
          initialValues.hourRes = undefined;
          break;
        case 'count_reporter_hours':
          initialValues.param = undefined;
          initialValues.hourRes = hoursOptions[1];
          break;
        case 'count_licence_usage':
          initialValues.param = COUNT_LICENCE_USAGE_DEFAULT_PARAM;
          initialValues.hourRes = undefined;
          break;
        default:
          initialValues.param = undefined;
          initialValues.hourRes = undefined;
      }
    }
  }, [criteriaType]);


  const handleTypeChange = (
    setFieldValue: (
      field: string,
      value: any | null,
      shouldValidate?: boolean
    ) => void,
    value: TypeOption
  ) => {
    setCriteriaType({ type: value, reset: false });

    setFieldValue('type', value);
    switch (value.value) {
      case 'count_intervener_login':
        setFieldValue('param', COUNT_INTERVENER_LOGIN_DEFAULT_PARAM);
        setFieldValue('hourRes', undefined);
        break;
      case 'count_reporter_messages':
        setFieldValue('param', COUNT_REPORTER_MESSAGES_DEFAULT_PARAM);
        setFieldValue('hourRes', undefined);
        break;
      case 'count_reporter_hours':
        setFieldValue('param', undefined);
        setFieldValue('hourRes', hoursOptions[1]);
        break;
      case 'count_licence_usage':
        setFieldValue('param', COUNT_LICENCE_USAGE_DEFAULT_PARAM);
        setFieldValue('hourRes', undefined);
        break;
      case 'messages_ip':
        setFieldValue('param', undefined);
        setFieldValue('hourRes', undefined);
        break;
      default:
        setFieldValue('param', undefined);
        setFieldValue('hourRes', undefined);
    }
  };

  const handleLocationTypeChange = (
    setFieldValue: (
      field: string,
      value: LocationTypeOption | null,
      shouldValidate?: boolean
    ) => void,
    value: LocationTypeOption
  ) => {
    setFieldValue('locationTargetSpecific', null);
    setLocationSpecificTargetOptions([]);
    setLocationFilter('');
    setLocationType(value);

    if (!!value && 'value' in value && value.value === 'voivodeship') getVoivodeships();
  };

  const handleIpTypeChange = (
    setFieldValue: (
      field: string,
      value: any | null,
      shouldValidate?: boolean
    ) => void,
    value: TypeOption
  ) => {
    setFieldValue('type', value);
    setCriteriaType({type: value, reset: true});
  };

  const handleChatSchoolChange = (
    setFieldValue: (
      field: string,
      value: EntityOption | null,
      shouldValidate?: boolean
    ) => void,
    value: EntityOption
  ) => {
    setFieldValue('chatSchool', value);
    setFieldValue('chat', null);
    if (value) setChatSchoolOption(value)
    else setChatSchoolOption(undefined);
  };

  const getGeneralReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: ReportGeneralResponse = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setGeneralData(response);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getSingleReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: ReportSingleResponse = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setSingleData(reportType, response);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getObjectReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: object = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setObjectData(reportType, response);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getDetailReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: ReportDetailResponse[] = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setDetailData(response);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getLicenceReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: { data: LicenseReportResponse[] } = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setLicenceData(response.data);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getUsersMgmntReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: UsersMgmntReportResponse = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setUsersMgmntData(response);
        setReportsFetching(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const getMessagesIpReport = async (url: string, reportType: ReportType, queryStrings: string) => {
    try {
      const response: MessagesIpResponse = await api(`${url}/${reportType}${queryStrings}`, {
        method: 'GET',
      });

      if (response) {
        setMessagesIpData(reportType, response.data);
        setReportsFetching(false);
      }
      setReportParams(queryStrings);

    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      setReportsFetching(false);
    }
  };

  const submitIp = async (values: IpFormValues) => {
    const queryStrings = `?dateTime=${values.dateTime}&chatId=${values.chat?.value}`;
    getMessagesIpReport(urlReportsAdmin, values.type.value, queryStrings);
    setReportsFetching(true);
  };

  const submit = async (values: FormValues) => {
    if (
      (!values.locationTarget && !values.locationTargetSpecific) ||
      !locationType ||
      (locationType && !('value' in locationType)) ||
      (isDirector && values.locationTargetSpecific && !('value' in values.locationTargetSpecific))
    )
    return;

    setReportsFetching(true);

    const url = isDirector
      ? `${urlReports}/${values.locationTargetSpecific?.value}`
      : urlReportsAdmin;
    const dateQueryString = `?date_from=${values.date_from}&date_to=${values.date_to}`;
    const locationQuery =
      isDirector || values.locationTarget.value
        ? ''
        : locationType.value === 'voivodeship'
        ? `&voivodeship=${values?.locationTargetSpecific?.value}`
        : locationType.value === 'locality'
        ? `&city=${values?.locationTargetSpecific?.value}`
        : `&schoolId=${values?.locationTargetSpecific?.value}`;

    let paramQuery;
    if (values.type.value === 'count_reporter_hours' && values.hourRes) {
      paramQuery = `&param=${values.hourRes.value}`;
    } else if (paramReportTypes.includes(values.type.value) && values.param) {
      paramQuery = `&param=${values.param}`;
    } else {
      paramQuery = '';
    }

    if (isDirector) setSchooldId(values.locationTargetSpecific?.value);

    const queryStrings = `${dateQueryString}${locationQuery}${paramQuery}`;

    setReportParams(queryStrings);
    setReportType(values.type.value);

    if (values.type.value === 'violence') {
      getGeneralReport(url, values.type.value, queryStrings);
    } else if (values.type.value === 'licence') {
      getDetailReport(url, values.type.value, queryStrings);
    } else if (values.type.value === 'count_licence_usage') {
      getLicenceReport(url, values.type.value, queryStrings);
    } else if (values.type.value === 'users_mgmnt') {
      getUsersMgmntReport(url, values.type.value, queryStrings);
    } else if (
      values.type.value === 'count_intervener_login'
      || values.type.value === 'count_reporter_messages'
      || values.type.value === 'count_reporter_hours'
    ) {
      getSingleReport(url, values.type.value, queryStrings);
    }else if (values.type.value === 'school_stats' || values.type.value === 'messages_stats') {
      getObjectReport(url, values.type.value, queryStrings);
    }
  };

  return (
    <>
    {criteriaType.type.value !== 'messages_ip' ? (
    <Formik key="1"
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={() => validationSchema(ty)}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, touched, errors }) => (
        <Wrapper>
          <Form autoComplete="off">
            <PageTitle title={t('criteria')} paddingBottom="20px" />
            <ReactSelect
              onChange={(val) => handleTypeChange(setFieldValue, val as TypeOption)}
              options={typeOptions}
              value={values.type}
              isSearchable={false}
              styles={customSelectStyles}
            />
            {!isDirector && (
              <>
                {!values.locationTarget.value && (
                  <ReactSelect
                    onChange={(val) =>
                      handleLocationTypeChange(setFieldValue, val as LocationTypeOption)
                    }
                    options={locationTypeOptions}
                    value={locationType}
                    isSearchable={false}
                    styles={customSelectStyles}
                  />
                )}
                <ReactSelect
                  onChange={(val) => setFieldValue('locationTarget', val)}
                  options={locationTargetOptions}
                  value={values.locationTarget}
                  isSearchable={false}
                  styles={customSelectStyles}
                />
              </>
            )}
            {!values.locationTarget.value && (
              <ReactSelect
                onChange={(val) => setFieldValue('locationTargetSpecific', val)}
                options={locationSpecificTargetOptions}
                value={values.locationTargetSpecific}
                placeholder={
                  !!locationType && 'value' in locationType
                    ? locationType.value === 'locality'
                      ? t('choose_locality')
                      : locationType.value === 'school'
                      ? t('choose_school')
                      : t('choose_voivodeship')
                    : t('as_choosen')
                }
                isSearchable={true}
                styles={customSelectStyles}
                onInputChange={(newValue: string) => setLocationFilter(newValue)}
                noOptionsMessage={() => t('no_options_to_display')}
              />
            )}
            {!isDirector && values.type.value === 'count_reporter_hours' && (
              <>
                <Separator />
                <ReactSelect
                  placeholder={t('hourRes_placeholder')}
                  onChange={(val) => setFieldValue('hourRes', val)}
                  options={hoursOptions}
                  value={values.hourRes}
                  isSearchable={false}
                  styles={customSelectStyles}
                />
              </>
            )}
            {!isDirector && (paramReportTypes.includes(values.type.value)) && (
              <>
                <Separator />
                <InputField
                  name="param"
                  label={t(`param_${values.type.value}`)}
                  placeholder={t(`param_${values.type.value}`)}
                  margin="15px 0 17px"
                />
              </>
            )}
            <Separator />
            <DateRow>
              <DateCell>
                <FormField label={t('from')} margin="0">
                  <InputField
                    name="date_from"
                    error={touched.date_to && !!errors.date_to}
                    autoFocus
                    type="date"
                    dimension="lg"
                  />
                </FormField>
              </DateCell>
              <DateCell>
                <FormField label={t('to')} margin="0">
                  <InputField
                    name="date_to"
                    error={touched.date_to && !!errors.date_to}
                    autoFocus
                    type="date"
                    dimension="lg"
                  />
                </FormField>
              </DateCell>
            </DateRow>
            <Separator />
            <Button
              width="100%"
              label={t('generate_report')}
              type="submit"
              disabled={
                !!Object.keys(errors).length ||
                (!values.locationTarget.value && !values.locationTargetSpecific?.value) || reportsFetching
              }
            />
          </Form>
          {reportsFetching ? <FetchingMessage /> : null}
        </Wrapper>
      )}
    </Formik>
      )
    :
  (<Formik key="2"
    initialValues={initialIpFormValues}
    onSubmit={submitIp}
    validationSchema={() => ipValidationSchema(ty)}
    enableReinitialize={true}
  >
    {({ values, setFieldValue, touched, errors }) => (
      <Wrapper>
        <Form autoComplete="off">
          <PageTitle title={t('criteria')} paddingBottom="20px" />
          <ReactSelect
            onChange={(val) => handleIpTypeChange(setFieldValue, val as TypeOption)}
            options={typeOptions}
            value={values.type}
            isSearchable={false}
            styles={customSelectStyles}
          />
          <ReactSelect
            name="chatSchool"
            onChange={(val) => handleChatSchoolChange(setFieldValue, val as EntityOption)}
            options={chatSchoolOptions}
            value={values.chatSchool}
            placeholder={t('choose_school')}
            isSearchable={true}
            styles={customSelectStyles}
            onInputChange={(newValue: string) => setChatSchoolFilter(newValue)}
            noOptionsMessage={() => t('no_options_to_display')}
          />
          <ReactSelect
            name="chat"
            onChange={(val) => setFieldValue('chat', val)}
            options={chatOptions}
            value={values.chat}
            placeholder={t('choose_chat')}
            isSearchable={true}
            styles={customSelectStyles}
            onInputChange={(newValue: string) => setChatFilter(newValue)}
            noOptionsMessage={() => t('no_options_to_display')}
          />
          <Separator />
              <FormField label={t('date_time')} margin="0">
                <InputField
                  name="dateTime"
                  error={touched.dateTime && !!errors.dateTime}
                  autoFocus
                  type="datetime-local"
                  dimension="lg"
                />
              </FormField>
          <Separator />
          <Button
            width="100%"
            label={t('generate_report')}
            type="submit"
            disabled={!!Object.keys(errors).length || !values.chatSchool?.value || !values.chat?.value || !values.dateTime || reportsFetching}
          />
        </Form>
        {reportsFetching ? <FetchingMessage /> : null}
      </Wrapper>
    )}
  </Formik>
  )
    }
      </>
  );
};

export default Criteria;
