import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import usePrefix from 'utils/usePrefix';
import { ChatWithLastMessageResponse } from 'utils/api/chats';
import { api, getApiUrl, ResponseError } from 'utils/api';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';
import colors from 'styles/colors';

import PageTitle from 'components/PageTitle';
import InputField from 'components/InputField';
import NoData from 'components/NoData';

import { PageTop } from '../styles';
import { Wrapper, NicknameContainer, NicknameInputContainer, IconStyled } from './styles';
import Panel from './Panel';

const url = getApiUrl('/interveners/me/chats');

const MAX_NICKNAME_LENGTH = 63;

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    nickname: Yup.string().max(
      MAX_NICKNAME_LENGTH,
      ty('max_nickname_length_is', { length: MAX_NICKNAME_LENGTH })
    ),
  });

interface Props {
  onClear: () => void;
  chatId?: number;
  anonymous?: boolean;
  chatList: ChatWithLastMessageResponse[];
  nickname?: string | null;
  deactivationTime: string | null;
  onClearAndReset: () => void;
  chatNumber?: number;
  chatPadding: boolean;
}

interface FormProps {
  nickname: string | null;
}

const ChatPanel: React.FC<Props> = ({
  chatId,
  anonymous,
  onClear,
  nickname,
  deactivationTime,
  chatList,
  onClearAndReset,
  chatNumber,
  chatPadding,
}) => {
  const [, dispatch] = useApp();

  const [editMode, setEditMode] = useState(false);

  const t = usePrefix('Chat');
  const ty = usePrefix('YupErrors');

  const submit = async (values: FormProps) => {
    if (values.nickname === nickname || (!values.nickname && !nickname)) {
      setEditMode(false);
      return;
    }

    try {
      const response = await api(`${url}/${chatId}`, {
        method: 'PUT',
        payload: { ...values },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        setEditMode(false);
        onClear();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Wrapper
      justifyContent="space-between"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      chatPadding={chatPadding}
    >
      {chatId ? (
        <>
          <PageTop>
            <Formik
              initialValues={{
                nickname: nickname ? nickname : '',
              }}
              onSubmit={submit}
              enableReinitialize={true}
              validationSchema={() => validationSchema(ty)}
            >
              {({ submitForm, values, errors }) =>
                !editMode ? (
                  <NicknameContainer
                    onClick={() => setEditMode(true)}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <PageTitle
                      clickable
                      title={
                        values.nickname
                          ? values.nickname
                          : chatNumber
                          ? t('username_number', { number: chatNumber })
                          : t('username')
                      }
                    />
                    <IconStyled type="edit" width="18" height="18" color={colors.brand_shadow_05} />
                  </NicknameContainer>
                ) : (
                  <NicknameInputContainer>
                    <InputField
                      name="nickname"
                      autoFocus
                      onBlur={submitForm}
                      error={errors?.nickname}
                    />
                  </NicknameInputContainer>
                )
              }
            </Formik>
          </PageTop>
          <Panel
            chatId={chatId}
            deactivationTime={deactivationTime}
            anonymous={anonymous}
            chatList={chatList}
            onClearAndReset={onClearAndReset}
            nickname={nickname ? nickname : t('username')}
          />
        </>
      ) : (
        <NoData title={t('no_data')} />
      )}
    </Wrapper>
  );
};

export default ChatPanel;
