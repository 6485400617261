import React, { useCallback } from 'react';

import usePrefix from 'utils/usePrefix';
import { userRoles } from 'utils/userRoles';
import { getApiUrl, api } from 'utils/api';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import colors from 'styles/colors';

import Icon from 'components/Icons';
import FlexDiv from 'components/FlexDiv';
// import Language from 'components/Language';
import SetLanguage from 'components/SetLanguage';

import {
  Wrapper,
  GrayLine,
  NavList,
  StyledIcon,
  StyledButton,
  StyledButtonLink,
  LogoLink,
} from './styles';
import NavigationItem from './NavigationItem';
// import AppVersion from './AppVersion';

const Navigation: React.FC = () => {
  const [{ profile }, dispatch] = useApp();
  const t = usePrefix('Navigation');

  const isAdmin = profile?.role === userRoles.admin;
  const isDirector = profile?.role === userRoles.director;

  const onLogout = useCallback(() => {
    const url = getApiUrl('/users/me/logout');
    api(`${url}`, { method: 'POST' }).then();

    dispatch({ type: ActionTypes.LOGOUT });
  }, [dispatch]);

  return (
    <Wrapper>
      <LogoLink to="/app">
        <Icon type="logo" width="60" height="52" color={colors.white} />
      </LogoLink>
      <GrayLine />

      <NavList>
        {(isAdmin || isDirector) && (
          <NavigationItem icon="reports" to={'reports'} label={t('reports')} />
        )}
        {isAdmin ? (
          <NavigationItem icon="repository" to={'notices'} label={t('notices')} />
        ) : (
          <>
          <NavigationItem icon="chat" to={'chat'} label={t('chat')} />
          <NavigationItem icon="lesson" to={'advices'} label={t('advices')} />
          <NavigationItem icon="material" to={'instructions'} label={t('materials')} />
          <NavigationItem icon="movie" to={'movies'} label={t('movies')} />
          <NavigationItem icon="article" to={'articles'} label={t('articles')} />
          </>
        )}
        {isAdmin && (
          <>
            <NavigationItem icon="users" to={'users'} label={t('users')} />
            <NavigationItem icon="schools" to={'schools'} label={t('schools')} />
          </>
        )}
      </NavList>
      <FlexDiv flexDirection="column" alignItems="center">
        <StyledButtonLink to={'profile'} bgColor={colors.black_shadow}>
          <StyledIcon type="user" margin="auto" color={colors.white} />
        </StyledButtonLink>
        <StyledButton type="button" onClick={onLogout} bgColor={colors.yellow}>
          <StyledIcon type="exit" margin="auto" color={colors.brand_2} />
        </StyledButton>
      </FlexDiv>
      <SetLanguage />
      {/*<Language isNav />*/}
      {/*<AppVersion />*/}
    </Wrapper>
  );
};

export default Navigation;
