import React from 'react';

import colors from 'styles/colors';

import { StyledGraphic } from './styles';

const Clouds: React.FC = () => (
  <>
    <StyledGraphic
      type="cloud1"
      color={colors.brand_2}
      opacity="0.2"
      position={{ top: '10px', left: '300px' }}
    />
    <StyledGraphic
      inverted
      type="cloud1"
      color={colors.brand_2}
      opacity="0.2"
      position={{ top: '100px', right: '100px' }}
      width="75px"
      height="75px"
    />
    <StyledGraphic
      type="cloud2"
      color={colors.brand_2}
      opacity="0.4"
      position={{ top: '45px', left: '20px' }}
      width="75px"
      height="75px"
    />
    <StyledGraphic
      inverted
      type="cloud2"
      color={colors.brand_2}
      opacity="0.4"
      position={{ top: '200px', right: '150px' }}
    />
  </>
);

export default Clouds;
