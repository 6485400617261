import {LanguagesList, UserType} from 'utils/api/users';

const PL: LanguagesList = 'PL';
const EN: LanguagesList = 'EN';
const CZ: LanguagesList = 'CZ';
const SK: LanguagesList = 'SK';
const UA: LanguagesList = 'UA';

export const parseLang = (lang: LanguagesList): string =>
  ({
    PL: 'pl',
    EN: 'en',
    UA: 'uk',
    CZ: 'cs',
    SK: 'sk',
  })[lang];

export const languages = {
  pl: PL,
  en: EN,
  cs: CZ,
  sk: SK,
  uk: UA,
};
