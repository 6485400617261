import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'styles/colors';

interface Props {
  title: string;
  clickable?: boolean;
  paddingBottom?: string;
}

const PageTitle: React.FC<Props> = ({ title, ...props }) => (
  <StyledPage {...props}>{title}</StyledPage>
);

const StyledPage = styled.h1<Partial<Props>>`
  margin: 0;
  padding: 0;
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${paddingBottom};
    `}
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1.1px;
  color: ${colors.brand_2};
  width: 100%;
  ${({ clickable }) => clickable && 'cursor: pointer;'};
`;

export default PageTitle;
