import React from 'react';

import shapes from 'statics/shapes.svg';

import Header from 'components/Header';

import {LoginContentStyled, LoginFormStyled, LoginWrapperStyled} from './styles';
import Clouds from './Clouds';

interface LoginWrapperProps {
  children?: React.ReactNode;
}

export const LoginWrapper: React.FC<LoginWrapperProps> = ({ children }) => (
  <LoginWrapperStyled>
    <Header />
    <LoginContentStyled flexDirection="column" alignItems="center" justifyContent="center">
      <LoginFormStyled img={shapes}>
        {children}
      </LoginFormStyled>
    </LoginContentStyled>
    <Clouds />
  </LoginWrapperStyled>
);

export default LoginWrapper;
