import React, { useState, useEffect } from 'react';

import { ChatWithLastMessageResponse, TagResponse } from 'utils/api/chats';
import { useApi, getApiUrl } from 'utils/api/useApi';
import { chatTypes, notificationTypes, chatlistTypes } from 'utils/constants';
import { api, ChatListTypes, PaginatedList, ResponseError } from 'utils/api';
import { ActionTypes } from 'App/types';
import { useApp } from 'App';
import useBrowserStorage from 'utils/browserStorage';

import ChatList from './ChatList';
import ChatPanel from './ChatPanel';
import ChatMeta from './ChatMeta';
import { Wrapper, ChatMetaWrapper } from './styles';

const url = getApiUrl('/interveners/me/chats');
const urlTags = getApiUrl('/interveners/me/chats/tags');

export interface ListState {
  chatList: ChatWithLastMessageResponse[];
  page: number;
  size: number;
  selectedTags: string[];
  query?: string;
}

const Chat: React.FC = () => {
  const [, dispatch] = useApp();

  const PAGE_START = 0;
  const PAGE_SIZE = 20;
  const REFRESH_INTERVAL = 10000;
  const [displayedChat, setChat] = useState<ChatWithLastMessageResponse>();
  const [deactivationTime, setDeactivationTime] = useState<string | null>(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [listFilter, setListFilter] = useState<ChatListTypes>(chatlistTypes.all);
  const [chatPadding, setChatPadding] = useState(false);
  const [queryFilter, setQueryFilter] = useState('');
  const [isArchive, setIsArchive] = useBrowserStorage<boolean>(
    'chatListIsArchive',
    false,
    false,
    localStorage
  );

  const [state, setState] = useState<ListState>({
    chatList: [],
    page: PAGE_START,
    size: PAGE_SIZE,
    selectedTags: [],
    query: '',
  });

  // eslint-disable-next-line no-magic-numbers
  const [newMessageDate, setNewMessageDate] = useState(0);

  const MIN_QUERY_LENGTH = 1;

  const IS_TAGS = state.selectedTags.length
    ? `&tags=${state.selectedTags}`
    : `&category=${listFilter}`;
  const IS_QUERY = queryFilter.length < MIN_QUERY_LENGTH ? '' : `&query=${queryFilter}`;

  const { data, isLoading, fetchData } = useApi<PaginatedList<ChatWithLastMessageResponse[]>>(
    `${url}?page=${state.page}&size=${state.size}${IS_TAGS}&archived=${isArchive}${IS_QUERY}`,
    {
      method: 'GET',
    }
  );

  const {
    data: dataTags,
    isLoading: isLoadingTags,
    fetchData: fetchDataTags,
  } = useApi<TagResponse[]>(urlTags, {
    method: 'GET',
  });

  const clearState = () =>
    setState((prevState) => ({
      ...prevState,
      selectedTags: [],
      page: PAGE_START,
      chatList: [],
    }));

  const clearStateWithoutTags = () =>
    setState((prevState) => ({
      ...prevState,
      page: PAGE_START,
      chatList: [],
    }));

  const onClearAndReset = (withoutTags?: boolean) => {
    setFirstLoad(true);
    if (withoutTags) {
      clearStateWithoutTags();
      return;
    }
    clearState();
  };

  const dataPoll = async () => {
    try {
      const response: PaginatedList<ChatWithLastMessageResponse[]> = await api(
        `${url}?page=${PAGE_START}&size=${PAGE_SIZE}&category=${chatlistTypes.all}`,
        {
          method: 'GET',
        }
      );

      if (response?.content.length) {
        const lastMessageTimeArray = response.content.map((chat) =>
          new Date(chat.last_message.creation_time).getTime()
        );
        setNewMessageDate(Math.max(...lastMessageTimeArray));
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      dataPoll();
    }, REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.content?.length) {
      if (!state.chatList.length) {
        const lastMessageTimeArray = data.content.map((chat) =>
          new Date(chat.last_message.creation_time).getTime()
        );
        // eslint-disable-next-line no-magic-numbers
        if (listFilter === chatlistTypes.all) setNewMessageDate(Math.max(...lastMessageTimeArray));
      }
      setState((prevState) => ({
        ...prevState,
        chatList: state.page ? [...prevState.chatList, ...data.content] : [...data.content],
      }));
      // eslint-disable-next-line no-magic-numbers
      if (!displayedChat) setChat(data.content[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!data && !state.chatList.length && state.page === PAGE_START) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.chatList]);

  useEffect(() => {
    if (newMessageDate) {
      if (firstLoad) {
        setFirstLoad(false);
        return;
      }
      clearState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageDate]);

  const onChatClick = (chatId: number) => {
    setDeactivationTime(null);
    setChat(state.chatList.find((chat) => chatId === chat.id));
  };

  const loadMore = () => {
    // eslint-disable-next-line no-magic-numbers
    if (!isLoading) setState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  const handleInactiveChat = (deactivation_time: string | null) =>
    setDeactivationTime(deactivation_time);

  const handleListFilter = (filter: ChatListTypes) => {
    setListFilter(filter);
  };

  const setSelectedTags = (tags: string[]) => {
    setState((prevState) => ({ ...prevState, selectedTags: [...tags] }));
    onClearAndReset(true);
  };

  const handleIsArchive = (isArchive: boolean) => {
    setIsArchive(isArchive);
    onClearAndReset();
  };

  const handleQuery = (query: string) => {
    setQueryFilter(query);
    if (query.length >= MIN_QUERY_LENGTH) {
      clearState();
    }
  };

  const handleQueryReset = () => {
    setQueryFilter('');
  };

  const handleSelectedTags = (tag: string) => {
    const tagIndex = state.selectedTags.indexOf(tag);
    // eslint-disable-next-line no-magic-numbers
    if (tagIndex > -1) {
      const tempSelectedTags = [...state.selectedTags];
      // eslint-disable-next-line no-magic-numbers
      tempSelectedTags.splice(tagIndex, 1);
      setSelectedTags(tempSelectedTags);
    } else {
      setSelectedTags([...state.selectedTags, tag]);
    }
  };

  const handleFetchTags = () => {
    onClearAndReset();
    fetchDataTags();
  };

  return (
    <Wrapper alignItems="stretch">
      <ChatList
        setChatId={onChatClick}
        chatId={displayedChat?.id}
        chatList={state.chatList}
        totalElements={data?.totalElements}
        PAGE_START={PAGE_START}
        PAGE_SIZE={PAGE_SIZE}
        loadMore={loadMore}
        currentPage={state.page}
        listFilter={listFilter}
        handleListFilter={handleListFilter}
        tags={dataTags}
        isLoadingTags={isLoadingTags}
        handleSelectedTags={handleSelectedTags}
        selectedTags={state.selectedTags}
        handleIsArchive={handleIsArchive}
        isArchive={isArchive}
        setQuery={handleQuery}
        resetQuery={handleQueryReset}
        query={queryFilter}
        isSearchClearDisabled={queryFilter.length < 1}
      />
      <ChatPanel
        chatList={state.chatList}
        chatId={displayedChat?.id}
        nickname={displayedChat?.nickname}
        chatNumber={displayedChat?.chat_number}
        anonymous={displayedChat?.type === chatTypes.anonymous}
        onClear={clearState}
        onClearAndReset={onClearAndReset}
        deactivationTime={deactivationTime}
        chatPadding={chatPadding}
      />
      <ChatMetaWrapper>
        {displayedChat?.id && (
          <ChatMeta
            chatId={displayedChat?.id}
            onClear={clearState}
            handleInactiveChat={handleInactiveChat}
            deactivationTime={deactivationTime}
            fetchTags={handleFetchTags}
            setChatPadding={setChatPadding}
          />
        )}
      </ChatMetaWrapper>
    </Wrapper>
  );
};

export default Chat;
