import React from 'react';

import { useApp } from 'App';
import { ChatViolenceStatsResponse } from 'utils/api/chats';
import usePrefix from 'utils/usePrefix';

import Dropdown from 'components/Dropdown';

import MetaSection from '../MetaSection';

interface Props {
  dataViolence?: ChatViolenceStatsResponse;
  isLoadingViolence?: boolean;
  closeViolenceScreen: () => void;
  onViolenceSelect: (violenceId: number) => void;
}

const ViolenceScreen: React.FC<Props> = ({ onViolenceSelect, closeViolenceScreen }) => {
  const [{ violenceTypes }] = useApp();
  const t = usePrefix('Chat');

  return (
    <MetaSection iconType="close" onClick={closeViolenceScreen} title={t('select_violence')}>
      <Dropdown onSelect={onViolenceSelect} violenceTypes={violenceTypes} />
    </MetaSection>
  );
};

export default ViolenceScreen;
