import React, { MouseEvent } from 'react';

import { DataRowStyled } from './styles';

interface DataRowProps {
  children?: React.ReactNode;
  title?: string;
  clickable?: boolean;
  onClick?: (event: MouseEvent<HTMLLIElement>) => void;
  selected?: boolean;
  disabled?: boolean;
  unread?: boolean;
}

const DataRow: React.FC<DataRowProps> = ({
  children,
  clickable = false,
  onClick,
  title,
  selected = false,
  disabled = false,
  unread = false,
  ...props
}) => (
  <DataRowStyled
    selected={selected}
    clickable={!disabled && clickable}
    title={title}
    onClick={onClick}
    disabled={disabled}
    unread={unread}
    {...props}
  >
    {children && children}
  </DataRowStyled>
);

export default DataRow;
