import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { screenMdAbove } from 'styles/breakpoinst';

interface LangState {
  isNav?: boolean;
}

const langStyles = css`
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 10;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${screenMdAbove}) {
    position: relative;
    top: initial;
    right: initial;
    transform: none;
  }
`;

const langNavStyles = css`
  position: relative;
  margin-top: auto;
`;

export const LanguageStyled = styled.div<LangState>`
  ${({ isNav }) => (isNav ? langNavStyles : langStyles)};
`;

interface LangMenuState {
  isNav?: boolean;
}

const menuBottom = css`
  top: 100%;
`;

const menuTop = css`
  bottom: 100%;
`;

export const LanguageMenu = styled.div<LangMenuState>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  padding: 16px;
  width: 80px;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0 0 16px 0 ${colors.gray_shadow_03};
  ${({ isNav }) => (isNav ? menuTop : menuBottom)};
`;

interface ButtonState {
  isActive?: boolean;
  isNav?: boolean;
}

const langActiveStyles = css`
  color: ${colors.brand_2};
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
`;

export const ButtonLangStyled = styled.button<ButtonState>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  background: transparent;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.brand_2};
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  ${({ isActive }) => (isActive ? langActiveStyles : '')};
`;

const buttonToggleMenuStyles = css`
	color: ${colors.white};
	opacity: 0.7;
`;

const buttonToggleDefaultStyles = css`
	color: ${colors.brand_2};
`;

export const ButtonToggleStyled = styled.button<ButtonState>`
	${({ isNav }) => (isNav ? buttonToggleMenuStyles : buttonToggleDefaultStyles)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  
  img {
    display: block;
    margin-left: 4px;
  }
  
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;
