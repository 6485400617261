import styled from 'styled-components';

import colors from 'styles/colors';
import { CLOSE_ICON_SIZE } from 'components/Wrapper';
import { DataRowStyled } from 'components/DataRow/styles';
import DataRow from 'components/DataRow';
import FlexDiv from 'components/FlexDiv';

import { ButtonsContainer } from '../styles';

const PAGE_PADDING = '24px';

export const SubHeader = styled(FlexDiv)`
  padding: ${PAGE_PADDING} 0;
`;

export const Subtitle = styled.h3`
  flex: 0 0 168px;
  align-self: center;
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.gray};
`;

export const Value = styled.span`
  color: ${colors.brand_1};
`;

export const ListWrapper = styled.div`
  flex: 1;
  margin: 0;
  width: 100%;
`;

export const SelectedList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 480px;
  overflow-y: auto;
`;

export const SearchList = styled.div<{ height: string }>`
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${({ height }) => height};
  max-height: 480px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  overflow-y: auto;
  list-style: none;
  ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  > div {
    > div {
      padding: 0;
      scrollbar-width: thin;
      overflow-y: auto;
    }
  }
  ${DataRowStyled} {
    border-width: 0;
    &:not(:last-child) {
      border-bottom-width: 1px;
    }
  }
`;

export const ListRow = styled(DataRow)<{ selected: boolean }>`
  border-width: 0;
  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ selected }) => (selected ? colors.brand_shadow_025 : 'rgba(151, 151, 151, 0.3)')};
  }
`;

export const ListTitle = styled.h4`
  margin: 16px 0 8px;
`;

export const ToolbarBottomStyled = styled.div`
  position: sticky;
  bottom: 0;
  padding: 0 24px 24px calc(${CLOSE_ICON_SIZE} * 2);
  width: 100%;
  border-top: 1px solid ${colors.gray_shadow_03};
  background-color: ${colors.white};
  z-index: 10;
  ${ButtonsContainer} {
    > button {
      margin-left: 0;
    }
  }
`;

export const ToggleIconStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  path {
    fill: inherit;
  }
`;
