import styled from 'styled-components';

import colors from 'styles/colors';

import FlexDiv from 'components/FlexDiv';

export const NoResultsStyled = styled(FlexDiv)<{ margin: string }>`
  padding: 4px 16px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  width: 100%;
  min-width: 0;
  min-height: 48px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.black};
  margin: ${({ margin }) => margin};
`;
