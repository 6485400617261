import styled from 'styled-components';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';
import { screenMdAbove } from 'styles/breakpoinst';
import { buttonColors, ButtonColors } from 'components/Button/styles';

export interface AnchorStyledProps {
  color: ButtonColors;
}

export const AnchorStyled = styled.a<AnchorStyledProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  width: auto;
  height: 40px;
  border-radius: 5px;
  border: 0;
  font-weight: 600;
  font-size: 16px;
  color: ${colors.white};
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  outline: 0;
  cursor: pointer;
  transition: background ${ANIMATION}, color ${ANIMATION};
  
  ${({ color }) => buttonColors(color)};
  
  @media screen and (min-width: ${screenMdAbove}) {
    padding-inline: 20px;
  }
`;
