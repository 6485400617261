import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';
import { languages } from 'utils/languages';
import { CreateSchoolRequest } from 'utils/api/schools';

import Button from 'components/Button';
import DetailsTitle from 'components/DetailsTitle';
import FlexDiv from 'components/FlexDiv';
import RadioInput from 'components/RadioInput';

import {
  Content,
  ButtonsContainer,
  FieldsContainer,
  StyledInputField,
  TextData,
  Subtitle,
  StyledFormField,
} from './styles';

interface Props {
  onClose: () => void;
}

const DetailsForm: React.FC<
  Pick<FormikProps<CreateSchoolRequest>, 'errors' | 'touched' | 'isSubmitting' | 'values'> & Props
> = ({ errors, touched, isSubmitting, onClose, values, ...props }) => {
  const t = usePrefix('General');

  return (
    <Content {...props} autoComplete="off">
      <FlexDiv justifyContent="space-between" alignItems="center">
        <TextData>
          {/* eslint-disable-next-line no-magic-numbers */}
          <DetailsTitle title={values.name || t('school_name')} empty={values.name.length < 1} />
          {/* eslint-disable-next-line no-magic-numbers */}
          <Subtitle empty={values.locality.length < 1}>{values.locality || t('locality')}</Subtitle>
        </TextData>
        <ButtonsContainer alignItems="center">
          <Button onClick={onClose} color="link">
            {t('dont_save_changes')}
          </Button>
          <Button label={t('save_changes')} type="submit" disabled={isSubmitting} />
        </ButtonsContainer>
      </FlexDiv>
      <FieldsContainer>
        <FlexDiv justifyContent="space-between" alignItems="flex-start">
          <StyledInputField
            name="name"
            error={touched.name && errors.name}
            label={t('school_name')}
          />
          <StyledInputField
            name="voivodeship"
            error={touched.voivodeship && errors.voivodeship}
            label={t('voivodeship')}
          />
        </FlexDiv>
        <FlexDiv justifyContent="space-between" alignItems="flex-start">
          <StyledInputField
            name="street"
            error={touched.street && errors.street}
            label={t('street')}
          />
          <StyledInputField
            name="number"
            error={touched.number && errors.number}
            label={t('st_number')}
          />
        </FlexDiv>
        <FlexDiv justifyContent="space-between" alignItems="flex-start">
          <StyledInputField
            name="postal_code"
            error={touched.postal_code && errors.postal_code}
            label={t('postal_code')}
          />
          <StyledInputField
            name="locality"
            error={touched.locality && errors.locality}
            label={t('locality')}
          />
        </FlexDiv>
        <FlexDiv justifyContent="flex-start" alignItems="flex-start">
          <StyledFormField label={t('language')} margin={'0'}>
            <RadioInput
              name="school_language"
              error={touched.school_language && !!errors.school_language}
              label="PL"
              value={languages.pl}
            />
            <RadioInput
              name="school_language"
              error={touched.school_language && !!errors.school_language}
              label="EN"
              value={languages.en}
            />
            <RadioInput
              name="school_language"
              error={touched.school_language && !!errors.school_language}
              label="CZ"
              value={languages.cs}
            />
            <RadioInput
              name="school_language"
              error={touched.school_language && !!errors.school_language}
              label="SK"
              value={languages.sk}
            />
            <RadioInput
              name="school_language"
              error={touched.school_language && !!errors.school_language}
              label="UA"
              value={languages.uk}
            />
          </StyledFormField>
        </FlexDiv>
      </FieldsContainer>
    </Content>
  );
};

export default DetailsForm;
