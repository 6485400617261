import React from 'react';

import { StyledButton, ButtonColors, StyledLink } from './styles';

interface ButtonProps {
  children?: React.ReactNode;
  label?: string;
  title?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  color?: ButtonColors;
  width?: string;
  link?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  label,
  title,
  type = 'button',
  onClick,
  disabled = false,
  color = 'primary',
  width,
  link,
  ...props
}) =>
  !link ? (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      color={color}
      width={width}
      title={title}
      {...props}
    >
      {label}
      {children && children}
    </StyledButton>
  ) : (
    <StyledLink to={link} disabled={disabled} title={title} color={color} width={width} {...props}>
      {label}
      {children && children}
    </StyledLink>
  );

export default Button;
