import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';

export const DataCellStyled = styled(FlexDiv)<{ cols: number }>`
  padding: 0 8px;
  width: calc(100% / ${({ cols }) => cols});
  min-width: 0;
  color: inherit;
`;

export const Value = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
`;
