const colors = {
  // white colors
  white: '#fff',
  white_half: 'rgba(255,255,255,0.5)',
  white_tint_70: 'rgba(255,255,255,0.7)',

  // black colors
  black: '#000',
  black_shadow: 'rgba(0,0,0,0.1)',

  // gray colors
  gray: '#3e3e3e',
  gray_scale2: '#979797',
  gray_scale3: '#adadad',
  gray_scale4: '#cacaca',
  gray_scale5: '#f7f7f7',
  gray_scale6: 'rgba(62,62,62,0.04)',
  gray_scale7: '#5E6165',
  gray_scale8: '#7F8083',
  gray_scale9: '#F9F9F9',
  gray_scale10: '#666666',

  // blue colors
  brand_1: '#29b9d1',
  brand_1_scale2: '#24a6bc',
  brand_2: '#00497e',
  brand_2_scale2: '#004171',
  brand_2_tint_70: 'rgba(0, 73, 126, 0.7)',
  brand_2_tint_10: 'rgba(0, 73, 126, 0.1)',
  brand_3: '#53C6D9',
  brand_4: '#7ED4E2',
  brand_5: '#A8E2EC',
  brand_6: '#D3F0F5',
  brand_7: '#326C97',

  // red colors
  error: '#ff595e',
  error_scale2: '#ff363c',

  // green colors
  success: '#9bc53d',

  // yellow colors
  yellow: '#FDC420',

  // shadows
  white_shadow_06: 'rgba(255, 255, 255, 0.6)',
  gray_shadow_03: 'rgba(157, 157, 157, 0.3)',
  gray_shadow_05: 'rgba(157, 157, 157, 0.5)',
  brand_shadow_005: 'rgba(0, 73, 126, 0.05)',
  brand_shadow_01: 'rgba(0, 73, 126, 0.1)',
  brand_shadow_025: 'rgba(0, 73, 126, 0.25)',
  brand_shadow_05: 'rgba(0, 73, 126, 0.5)',
  brand_2_shadow_01: 'rgba(41, 185, 209, 0.1)',
  brand_2_shadow_02: 'rgba(41, 185, 209, 0.2)',
};

export default colors;
