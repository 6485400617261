import React, { useState, useEffect } from 'react';

import { ActionTypes } from 'App/types';
import { useApp } from 'App';
import { useApi, getApiUrl } from 'utils/api/useApi';
import { api, ResponseError } from 'utils/api';
import { ChatViolenceStatsResponse } from 'utils/api/chats';
import { notificationTypes } from 'utils/constants';

import GeneralScreen from './GeneralScreen';
import ViolenceScreen from './ViolenceScreen';
import NotesScreen from './NotesScreen';

const url = getApiUrl('/interveners/me/chats');

interface Props {
  chatId: number;
  onClear: () => void;
  handleInactiveChat: (deactivation_time: string | null) => void;
  deactivationTime: string | null;
  fetchTags: () => void;
  setChatPadding: (set: boolean) => void;
}

const Content: React.FC<Props> = ({
  chatId,
  onClear,
  handleInactiveChat,
  deactivationTime,
  fetchTags,
  setChatPadding,
}) => {
  const [generalView, setGeneralView] = useState(true);
  const [violenceView, setViolenceView] = useState(false);
  const [violenceRequestPending, setViolenceRequestPending] = useState(false);

  const [, dispatch] = useApp();

  const { data, isLoading, fetchData } = useApi<ChatViolenceStatsResponse>(
    `${url}/${chatId}/violence-stats`
  );

  useEffect(() => {
    if (!generalView && !violenceView) setChatPadding(true);
    else setChatPadding(false);
  }, [generalView, violenceView, setChatPadding]);

  const onViolenceSelect = async (violenceId: number) => {
    setViolenceRequestPending(true);
    try {
      const response = await api(`${url}/${chatId}/violence`, {
        method: 'POST',
        payload: { violenceId },
      });

      if (response) {
        fetchData();
        setViolenceRequestPending(false);
        if (violenceView) {
          setViolenceView(false);
          setGeneralView(true);
        }
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const onViolenceDelete = async (violenceId: number) => {
    setViolenceRequestPending(true);
    try {
      const response = await api(`${url}/${chatId}/violence`, {
        method: 'DELETE',
        payload: { violenceId },
      });

      if (response) {
        fetchData();
        setViolenceRequestPending(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <>
      {generalView ? (
        <GeneralScreen
          chatId={chatId}
          onClear={onClear}
          handleInactiveChat={handleInactiveChat}
          deactivationTime={deactivationTime}
          dataViolence={data}
          isLoadingViolence={isLoading}
          openViolenceScreen={() => {
            setViolenceView(true);
            setGeneralView(false);
          }}
          onViolenceDelete={onViolenceDelete}
          disableViolenceSelect={violenceRequestPending || isLoading}
          fetchTags={fetchTags}
          openNotesScreen={() => setGeneralView(false)}
        />
      ) : violenceView ? (
        <ViolenceScreen
          dataViolence={data}
          closeViolenceScreen={() => {
            setViolenceView(false);
            setGeneralView(true);
          }}
          onViolenceSelect={onViolenceSelect}
        />
      ) : (
        <NotesScreen chatId={chatId} closeNotesScreen={() => setGeneralView(true)} />
      )}
    </>
  );
};

export default Content;
