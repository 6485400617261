import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import Graphic from 'components/Graphics';
import FlexDiv from 'components/FlexDiv';

interface PositionType {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

interface CloudProps {
  inverted?: boolean;
  position: PositionType;
}

type PositionKeysType = 'top' | 'bottom' | 'left' | 'right';

export const LoginWrapperStyled = styled.div`
  height: 100%;
  grid-column: 1 / span 2;
  justify-content: center;
  align-items: center;
`;

export const LoginContentStyled = styled(FlexDiv)``;

export const LoginFormStyled = styled('div')<{ img: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
  row-gap: 16px;
  width: 548px;
	background-image: url('${({ img }) => img}');
	background-size: 548px 388px;
	background-repeat: no-repeat;
	background-position: top center;
`;

export const loginFormStyles = css`
  margin-inline: auto;
  padding-block: 40px;
	width: 280px;
	min-height: 388px;
`;

export const loginTitleStyles = css`
  margin: 0;
  font-weight: 600;
  font-size: 46px;
  line-height: 1.2;
  text-align: center;
  color: ${colors.brand_2};
`;

export const StyledGraphic = styled(Graphic)<CloudProps>`
  position: absolute;
  z-index: -1;
  ${({ inverted }) =>
  inverted &&
  css`
      transform: rotateY(0.5turn);
    `}
  ${({ position }: { position: PositionType }) =>
  (Object.keys(position) as PositionKeysType[]).map((key) => `${key}: ${position[key]};`)};
`;
