import React from 'react';

import usePrefix from 'utils/usePrefix';

import Button from 'components/Button';

import { Container, ModalContainer, ButtonsContainer, ButtonItem } from './styles';

interface Props {
  title: string;
  text: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmModal: React.FC<Props> = ({ title, text, onCancel, onConfirm }) => {
  const t = usePrefix('General');

  return (
    <Container>
      <ModalContainer>
        {title && <h3>{title}</h3>}
        {text && <p>{text}</p>}
        <ButtonsContainer justifyContent="center" alignItems="center" flex={1}>
          <ButtonItem>
            <Button label={t('cancel')} color="link" onClick={onCancel} />
          </ButtonItem>
          <ButtonItem>
            <Button label={t('yes')} onClick={onConfirm} />
          </ButtonItem>
        </ButtonsContainer>
      </ModalContainer>
    </Container>
  );
};

export default ConfirmModal;
