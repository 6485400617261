import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import DetailsTitle from 'components/DetailsTitle';

export const Container = styled(FlexDiv)`
  overflow: auto;
  width: 100%;
  padding: 0 24px 24px 40px;

  & > div {
    padding-top: 20px;
  }
`;

export const TextData = styled.div`
  flex: 1;
  min-width: 0;

	a {
		color: inherit;

		@media (hover: hover) {
			&:hover {
				text-decoration-thickness: 2px;
			}
		}
	}
`;

export const Title = styled(DetailsTitle)`
  font-size: 35px;
  letter-spacing: 1.75px;
`;

export const Content = styled(FlexDiv)`
  width: 100%;
`;
