import styled, { css } from 'styled-components';

import colors from 'styles/colors';

const Dot = styled.div<{ selected?: boolean }>`
  ${({ selected }) =>
    selected &&
    css`
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: ${colors.white};
      padding-right: 5px;
    `}
`;

export default Dot;
