import React, { useCallback } from 'react';

import usePrefix from 'utils/usePrefix';

import { Label, SwitchContainer } from '../ChatMeta/styles';
import { ArchiveSwitcherStyled, StyledInput, Slider } from './styles';

interface Props {
  isArchive: boolean;
  handleIsArchive: (isArchive: boolean) => void;
}

const ArchiveSwitcher: React.FC<Props> = ({ handleIsArchive, isArchive }) => {
  const t = usePrefix('Chat');

  const onChange = useCallback(() => {
    handleIsArchive(!isArchive);
  }, [isArchive, handleIsArchive]);

  return (
    <ArchiveSwitcherStyled>
      <Label>{t('showArchive')}</Label>
      <SwitchContainer>
        <StyledInput type="checkbox" onChange={onChange} defaultChecked={isArchive} />
        <Slider />
      </SwitchContainer>
    </ArchiveSwitcherStyled>
  );
};

export default ArchiveSwitcher;
