import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';
import FlexDiv from 'components/FlexDiv';

export const TEXT_AREA_HEIGHT = '44px';

export const Wrapper = styled(FlexDiv)`
  width: 100%;
`;

export const StyledTextArea = styled.textarea<{ height?: string }>`
  color: ${colors.brand_2};
  padding: 8px;
  width: 100%;
  min-height: ${TEXT_AREA_HEIGHT};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
  background-color: ${colors.gray_scale5};
  box-shadow: inset 0 0 0 0 rgba(62, 62, 62, 0);
  resize: none;
  outline: none;
  transition: box-shadow ${ANIMATION};
  &:focus {
    box-shadow: inset 0 0 0 1px rgba(62, 62, 62, 0.2);
    border: 1px solid ${colors.brand_1};
  }
`;
