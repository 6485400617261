import React from 'react';
import dayjs from 'dayjs';

import { ChatMessageResponse } from 'utils/api/chats';
import { messageTypes } from 'utils/constants';

import MessageAttachment from './MessageAttachment';
import {
  MessageContainer,
  MessageWrapper,
  MessageDate,
  MessageAuthor,
  MessageData,
} from './styles';

const Message: React.FC<{
  msg: ChatMessageResponse;
  nickname: string;
}> = ({ msg, nickname }) => {
  const { type, message, creation_time, attachment } = msg;
  const { intervener_response } = messageTypes;

  const formattedDate = dayjs(creation_time).format('H:mm DD.MM.YYYY');

  return (
    <MessageWrapper
      flexDirection={type === intervener_response ? 'row-reverse' : 'row'}
      alignItems="flex-end"
    >
      <MessageData>
        <MessageAuthor>{type === intervener_response ? msg.author : nickname}</MessageAuthor>
        <MessageContainer intervenerMsg={type === intervener_response}>
          {message ? message : attachment && <MessageAttachment attachment={attachment} />}
          <MessageDate>{formattedDate}</MessageDate>
        </MessageContainer>
      </MessageData>
    </MessageWrapper>
  );
};

export default Message;
