import styled, { css } from 'styled-components';
import { Form } from 'formik';

import colors from 'styles/colors';
import { ANIMATION, FONT_PRIMARY } from 'styles/globalVariables';

import { CLOSE_ICON_SIZE } from 'components/Wrapper';
import InputField from 'components/InputField';
import FlexDiv from 'components/FlexDiv';
import Icon from 'components/Icons';
import FormField from 'components/FormField';

export const contentStyles = css`
  overflow: auto;
  border-bottom: 1px solid ${colors.gray_shadow_03};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 24px calc(${CLOSE_ICON_SIZE} * 2);
`;

export const Content = styled(Form)`
  ${contentStyles}
`;

export const SectionWrapper = styled.section`
  ${contentStyles}
`;

export const TextData = styled.div`
  flex: 1;
  min-width: 0;
`;

export const Subtitle = styled.h3<{ empty: boolean }>`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #3e3e3e;
  ${({ empty }) =>
    empty &&
    css`
      opacity: 0.2;
    `};
`;

export const ButtonsContainer = styled(FlexDiv)`
  > button {
    margin-left: 20px;
  }
`;

const INPUT_GROUP_WIDTH = '372px';

export const StyledInputField = styled(InputField)`
  width: ${INPUT_GROUP_WIDTH};
  margin-bottom: 24px;
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  width: 880px;
  margin-top: 24px;
`;

export const SectionTitle = styled.div`
  margin: 24px 0;
  font-weight: 700;
  font-size: 18px;
  color: ${colors.gray};
`;

export const CodeField = styled(FlexDiv)`
  width: 100%;
  max-width: ${INPUT_GROUP_WIDTH};
`;

export const ButtonActionsStyled = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-width: 0;
`;

export const ButtonAction = styled.button<{ disabled: boolean }>`
  justify-self: flex-end;
  margin-left: auto;
  border: 0;
  cursor: pointer;
  outline: none;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `};
`;

export const AddIntervenerDirector = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 24px;
  height: 48px;
  border: 2px dashed ${colors.brand_2};
  background-color: ${colors.white};
  font-weight: 400;
  font-family: ${FONT_PRIMARY};
  font-size: 14px;
  line-height: 18px;
  color: ${colors.brand_2};
  cursor: pointer;
  outline: none;
  transition: background-color ${ANIMATION};
  > span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${colors.brand_shadow_01};
    }
  }
`;

export const StyledFormField = styled(FormField)`
	max-width: 320px;
  margin-bottom: 24px;
`;

export const IconStyled = styled(Icon)`
  margin-right: 16px;
`;

export const ACTION_ICON_SIZE = '24px';
