import React from 'react';

import { NoResultsStyled } from './styles';

interface NoResultsProps {
  title?: string;
  margin?: string;
}

const NoResults: React.FC<NoResultsProps> = ({ title, margin = '0' }) => (
  <NoResultsStyled
    margin={margin}
    justifyContent="center"
    alignItems="center"
    alignContent="center"
    flex={1}
  >
    {title && title}
  </NoResultsStyled>
);

export default NoResults;
