import React, { useEffect } from 'react';

import { useApi, getApiUrl } from 'utils/api/useApi';
import { ReceivedMovieDetailsResponse, ReceivedMovieResponse } from 'utils/api/inbox';
import usePrefix from 'utils/usePrefix';
import { PaginatedList } from 'utils/api';

import NoticeContent from 'components/NoticeContent';
import Loader from 'components/Loader';

const url = getApiUrl('/interveners/me/movies');

interface Props {
  movieId: number;
  fetchData: () => Promise<void | PaginatedList<ReceivedMovieResponse[]>>;
}

const Movie: React.FC<Props> = ({ movieId, fetchData }) => {
  const t = usePrefix('Movies');

  const { data, isLoading } = useApi<ReceivedMovieDetailsResponse>(`${url}/${movieId}`, {
    method: 'GET',
  });

  useEffect(() => {
    if (data) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) return null;

  return !isLoading ? <NoticeContent {...data.details} /> : <Loader label={t('loading')} />;
};

export default Movie;
