import React from 'react';
import styled from 'styled-components';
import FlexDiv from 'components/FlexDiv';

interface TopPanelProps {
  children?: React.ReactNode;
}

const TopPanel: React.FC<TopPanelProps> = ({ children }) => (
  <StyledHeader justifyContent="space-between" alignItems="center" flex={1}>
    {children}
  </StyledHeader>
);

const StyledHeader = styled(FlexDiv)`
  min-width: 100%;
`;

export default TopPanel;
