import React from 'react';

import Button from 'components/Button';

import { ButtonContainer, Description, Content } from './styles';

interface PromptProps {
  text: string;
  label: string;
  link: string;
}

const Prompt: React.FC<PromptProps> = ({ text, label, link }) => (
  <Content>
    <Description>{text}</Description>
    <ButtonContainer flexDirection="column" alignItems="center">
      <Button link={link}>{label}</Button>
    </ButtonContainer>
  </Content>
);

export default Prompt;
