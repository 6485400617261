import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import ReactSelect from 'react-select';

import { ActionTypes } from 'App/types';
import { useApp } from 'App';
import usePrefix from 'utils/usePrefix';
import { useApi, getApiUrl } from 'utils/api/useApi';
import { api, PaginatedList, ResponseError } from 'utils/api';
import { ChatDetailsResponse, ChatViolenceStatsResponse } from 'utils/api/chats';
import { findViolenceTypeKey } from 'utils/misc';
import { notificationTypes } from 'utils/constants';
import colors from 'styles/colors';
import { GetUsersResponse } from 'utils/api/users';

import ToggleSwitch from 'components/ToggleSwitch';
import Loader from 'components/Loader';
import NoData from 'components/NoData';
import { customSelectStyles } from 'components/Select/styles';
import Icon from 'components/Icons';

import {
  DetailTitle,
  SwitchContainer,
  Label,
  ViolenceTypesList,
  TypeItem,
  Detail,
  PlusContainer,
} from '../styles';
import MetaSection, { ICON_HEIGHT } from '../MetaSection';

const url = getApiUrl('/interveners/me/chats');

interface IntervenerTypeOption {
  value: number;
  label: string;
}

interface Props {
  chatId: number;
  onClear: () => void;
  handleInactiveChat: (deactivation_time: string | null) => void;
  deactivationTime: string | null;
  dataViolence?: ChatViolenceStatsResponse;
  isLoadingViolence: boolean;
  disableViolenceSelect: boolean;
  openViolenceScreen: () => void;
  onViolenceDelete: (violenceId: number) => void;
  fetchTags: () => void;
  openNotesScreen: () => void;
}

interface FormValues {
  attention_required: boolean;
}

const GeneralScreen: React.FC<Props> = ({
  chatId,
  onClear,
  handleInactiveChat,
  deactivationTime,
  dataViolence,
  isLoadingViolence,
  openViolenceScreen,
  onViolenceDelete,
  disableViolenceSelect,
  openNotesScreen,
}) => {
  const t = usePrefix('Chat');
  const tv = usePrefix('Violence');
  const tg = usePrefix('General');
  const [firstLoad, setFirstLoad] = useState(true);
  // eslint-disable-next-line no-magic-numbers
  const [intervalId, setIntervalId] = useState(0);
  const intervalIdRef = useRef<number>();

  const [{ violenceTypes }, dispatch] = useApp();

  const REFRESH_INTERVAL = 30000;

  const { data, isLoading, fetchData } = useApi<ChatDetailsResponse>(`${url}/${chatId}`, {
    method: 'GET',
  });

  const { data: dataIntervener, isLoading: isLoadingIntervener } = useApi<
    PaginatedList<GetUsersResponse[]>
  >(`${url}/${chatId}/possibleInterveners`, {
    method: 'GET',
  });

  useEffect(() => {
    intervalIdRef.current = intervalId;
  });

  const dataPoll = async () => {
    try {
      const response: ChatDetailsResponse = await api(`${url}/${chatId}`, {
        method: 'GET',
      });

      if (!!response.chat.deactivation_time && !deactivationTime)
        handleInactiveChat(response.chat.deactivation_time);
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleInterval = () => {
    if (intervalId) window.clearInterval(intervalId);
    const tempIntervalId = window.setInterval(() => {
      dataPoll();
    }, REFRESH_INTERVAL);
    setIntervalId(tempIntervalId);
  };

  useEffect(() => {
    handleInterval();

    return () => window.clearInterval(intervalIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (values: FormValues) => {
    try {
      const response = await api(`${url}/${chatId}`, {
        method: 'PUT',
        payload: { ...values },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        onClear();
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    if (data?.chat) {
      if (firstLoad) {
        setFirstLoad(false);
        return;
      }
      if (data.chat.deactivation_time) handleInactiveChat(data.chat.deactivation_time);
      handleInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleIntervenerAssign = async (value: IntervenerTypeOption) => {
    if (!value || !('value' in value)) {
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: 'GENERAL_ERROR', type: notificationTypes.error },
      });
      return;
    }
    try {
      const response = await api(`${url}/${chatId}/assignIntervener`, {
        method: 'POST',
        payload: { intervenerId: value.value },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <>
      {!isLoading && data?.chat ? (
        <Formik
          initialValues={{
            attention_required: data.chat.attention_required,
          }}
          onSubmit={submit}
          enableReinitialize={true}
        >
          {({ isSubmitting, submitForm }) => (
            <Form autoComplete="off">
              <MetaSection title={t('childs_report')}>
                <Detail>
                  <Label>{t('attention_required')}</Label>
                  <SwitchContainer onChange={() => submitForm()}>
                    <ToggleSwitch
                      checked={data.chat.attention_required}
                      disabled={isSubmitting}
                      name="attention_required"
                    />
                  </SwitchContainer>
                </Detail>
                <DetailTitle>{t('assigned_to')}</DetailTitle>
                {!isLoadingIntervener ? (
                  <ReactSelect
                    onChange={(value) => handleIntervenerAssign(value as IntervenerTypeOption)}
                    options={
                      dataIntervener?.content.length
                        ? dataIntervener.content.map((intervener) => ({
                            value: intervener.id,
                            label: `${intervener.first_name} ${intervener.last_name}`,
                          }))
                        : []
                    }
                    styles={customSelectStyles}
                    value={
                      data.chat.assigned_intervent
                        ? {
                            label: `${data.chat.assigned_intervent.first_name} ${data.chat.assigned_intervent.last_name}`,
                            value: data.chat.assigned_intervent.id,
                          }
                        : undefined
                    }
                  />
                ) : (
                  <Loader label={t('fetching_interveners_list')} />
                )}
                <DetailTitle>{tg('school_code').toUpperCase()}</DetailTitle>
                <Detail transparent>
                  <Label>{data.school.code}</Label>
                </Detail>
                <DetailTitle>{t('reported_violence_types').toUpperCase()}</DetailTitle>
                {!isLoadingViolence && dataViolence?.reporter.length ? (
                  <ViolenceTypesList>
                    {dataViolence.reporter.map((violence) => (
                      <TypeItem key={violence.violenceId}>
                        <Label>
                          {tv(
                            `${violenceTypes.find((type) => type.id === violence.violenceId)?.key}`
                          )}
                        </Label>
                      </TypeItem>
                    ))}
                  </ViolenceTypesList>
                ) : (
                  <NoData title={t('no_data')} />
                )}
              </MetaSection>
              <MetaSection
                iconType="plusbg"
                onClick={openViolenceScreen}
                title={t('classified_violence_types')}
              >
                {!isLoadingViolence && dataViolence?.intervener.length ? (
                  <ViolenceTypesList>
                    {dataViolence.intervener.map((violence) => (
                      <TypeItem key={violence.violenceId}>
                        <Label>{tv(findViolenceTypeKey(violenceTypes, violence.violenceId))}</Label>
                        <PlusContainer>
                          <Icon
                            onClick={() => {
                              !disableViolenceSelect && onViolenceDelete(violence.violenceId);
                            }}
                            type="minus"
                            width={ICON_HEIGHT}
                            height={ICON_HEIGHT}
                            color={!disableViolenceSelect ? colors.brand_2 : colors.gray_scale4}
                            disabled={disableViolenceSelect}
                          />
                        </PlusContainer>
                      </TypeItem>
                    ))}
                  </ViolenceTypesList>
                ) : (
                  <NoData title={t('no_data')} />
                )}
              </MetaSection>
              <MetaSection title={t('notes')}>
                <Detail>
                  <Label>{t('open_notes_pane')}</Label>
                  <PlusContainer>
                    <Icon
                      onClick={() => openNotesScreen()}
                      type="edit"
                      width={ICON_HEIGHT}
                      height={ICON_HEIGHT}
                      color={colors.brand_2}
                    />
                  </PlusContainer>
                </Detail>
              </MetaSection>
            </Form>
          )}
        </Formik>
      ) : (
        <Loader label={t('fetching_meta_data')} />
      )}
    </>
  );
};

export default GeneralScreen;
