import React, { FC } from 'react';

import usePrefix from 'utils/usePrefix';
import LoaderBouncing from 'components/LoaderBouncing';

import { FetchingMessageStyled } from './styles';

const FetchingMessage: FC = () => {
  const t = usePrefix('Reports');

  return (
    <FetchingMessageStyled>
      <LoaderBouncing />
      <h3>{t('fetching_report')}</h3>
    </FetchingMessageStyled>
  );
};

export default FetchingMessage;
