import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';

import Icon from 'components/Icons';

export const ButtonStyles = css`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  min-width: 0;
  height: 50px;
  border: 1px solid ${colors.brand_shadow_01};
  border-radius: 4px;
  background-color: ${colors.brand_shadow_005};
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.brand_2};
  text-align: left;
  cursor: pointer;
  transition: background-color ${ANIMATION};
  outline: none;
  overflow: hidden;
  svg {
    flex: 0 0 16px;
  }
`;

export const Wrapper = styled.ul`
  margin: 16px 0;
  padding: 0;
  width: 100%;
  list-style: none;
`;

export const GroupList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0 0 0 24px;
  list-style: none;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 16px;
    left: 12px;
    border-left: 1px dashed rgba(17, 132, 255, 0.4);
  }
`;

export const GroupItem = styled.li`
  position: relative;
  margin-bottom: 8px;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -12px;
    width: 8px;
    border-top: 1px dashed rgba(17, 132, 255, 0.4);
    transform: translateY(-50%);
  }
`;

export const ButtonSelect = styled.button<{ selected: boolean; marginBot?: string }>`
  ${ButtonStyles};
  ${({ marginBot }) =>
    marginBot &&
    css`
      margin-bottom: ${marginBot};
    `}

  ${({ selected }) =>
    selected &&
    `
    background-color: ${colors.brand_shadow_025};
    cursor: default;
    `};
  ${({ selected }) =>
    !selected &&
    `    
    @media (hover: hover) {
      &:hover {
        background-color: rgba(17, 132, 255, 0.08);
      }
    }
    `};
`;

export const Label = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  padding-right: 8px;
  min-width: 0;
  width: calc(100% - 16px);
  > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const IconStyled = styled(Icon)<{ isSelected?: boolean }>`
  justify-self: flex-end;
  margin-left: auto;
  ${({ isSelected }) =>
    isSelected &&
    css`
      transform: rotate(180deg);
    `}
`;
