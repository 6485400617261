import styled from 'styled-components';

import colors from 'styles/colors';

export const ListWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  > div {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  max-height: 100%;
  border-right: 1px solid ${colors.black_shadow};
  scrollbar-gutter: stable;
`;

export const TitleContainer = styled.div`
  padding-bottom: 20px;
`;
