import colors from 'styles/colors';

export const lineChartColors = ['#C12600', '#13E233', '#2B22E5', '#13E2E3'];

export const pieChartColors = [
  colors.brand_1,
  colors.brand_3,
  colors.brand_4,
  colors.brand_5,
  colors.brand_6,
  colors.brand_7,
  colors.brand_2,
];
