import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import { screenSmUnder, screenXlgAbove } from '../../styles/breakpoinst';

export const CHAT_META_WIDTH_MD = '300px';
export const CHAT_META_WIDTH = '370px';

export const Wrapper = styled(FlexDiv)`
  grid-area: main;
  height: 100%;
  overflow: hidden;
`;

export const PageTop = styled.header`
  padding: 16px 24px;
  width: 100%;
`;

export const ChatMetaWrapper = styled.section`
  flex: 0 0 ${CHAT_META_WIDTH_MD};
  width: ${CHAT_META_WIDTH_MD};
  @media screen and (min-width: ${screenXlgAbove}) {
    flex: 0 0 ${CHAT_META_WIDTH};
    width: ${CHAT_META_WIDTH};
  }
  @media screen and (max-width: ${screenSmUnder}) {
    display: none;
  }
`;
