import React, { useState } from 'react';

import { getApiUrl, api, PaginatedList, ResponseError } from 'utils/api';
import usePrefix from 'utils/usePrefix';
import { useApi } from 'utils/api/useApi';
import { GetUsersResponse } from 'utils/api/users';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';
import colors from 'styles/colors';

import Loader from 'components/Loader';
import { UsersList } from 'components/TableContainer/styles';
import Row from 'components/Row';
import Icon from 'components/Icons';

import Section from '../Section';
import { AddIntervenerDirector, IconStyled, ButtonAction, ACTION_ICON_SIZE } from '../styles';
import PossibleUsers from './PossibleUsers';

const url = getApiUrl(`/schools`);

const RECORD_COUNT = 9999999;

interface Props {
  schoolId: number;
  schoolName: string;
}

const SchoolUsers: React.FC<Props> = ({ schoolId, schoolName }) => {
  const [, dispatch] = useApp();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data, isLoading, fetchData } = useApi<PaginatedList<GetUsersResponse[]>>(
    `${url}/${schoolId}/users?page=0&size=${RECORD_COUNT}`,
    {
      method: 'GET',
    }
  );
  const t = usePrefix('Schools');
  const tg = usePrefix('General');

  const removeIntervener = async (intervener: GetUsersResponse) => {
    const result = window.confirm(
      t('confirm_remove', {
        userName: `${intervener.first_name} ${intervener.last_name}`,
        schoolName,
      })
    );
    if (!result) return;

    try {
      const response = await api(`${url}/${schoolId}/users/${intervener.id}`, {
        method: 'DELETE',
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Section title={t('users_list')}>
      {isLoading && <Loader label={t('fetching_users')} />}
      <UsersList>
        {data?.content &&
          data.content.map((int) => (
            <Row
              key={int.id}
              title={`${int.first_name} ${int.last_name}`}
              labels={[`${int.first_name} ${int.last_name}`, int.email, tg(int.role)]}
              clickable={false}
              cellChildren={
                <ButtonAction
                  onClick={() => removeIntervener(int)}
                  title={t('delete')}
                  disabled={false}
                >
                  <Icon
                    type={'close'}
                    width={ACTION_ICON_SIZE}
                    height={ACTION_ICON_SIZE}
                    color={colors.error}
                  />
                </ButtonAction>
              }
            />
          ))}
      </UsersList>
      <AddIntervenerDirector type="button" onClick={() => setModalIsOpen(true)}>
        <span>
          <IconStyled type="plus" width="24px" height="24px" color={colors.brand_2} />
          {t('add_user')}
        </span>
      </AddIntervenerDirector>
      {modalIsOpen && (
        <PossibleUsers
          schoolId={schoolId}
          onClose={() => setModalIsOpen(false)}
          fetchData={fetchData}
          isOpen={modalIsOpen}
        />
      )}
    </Section>
  );
};

export default SchoolUsers;
