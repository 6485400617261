import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import Icon from 'components/Icons';

import modalStyles, { MODAL_CLOSE_ICON_SIZE } from './styles';

interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  paddingTop?: string;
  centered?: boolean;
  small?: boolean;
}

const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  paddingTop,
  centered = false,
  small = false,
  ...props
}) => (
  <ReactModal
    style={modalStyles({ centered, small, paddingTop })}
    isOpen={isOpen}
    shouldReturnFocusAfterClose={true}
    // this should be removed if app is used by screen readers
    ariaHideApp={false}
    {...props}
  >
    {!!onClose && (
      <StyledIcon
        type="close"
        width={MODAL_CLOSE_ICON_SIZE}
        height={MODAL_CLOSE_ICON_SIZE}
        onClick={onClose}
      />
    )}
    {children}
  </ReactModal>
);

const StyledIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  left: calc(45px / 2);
`;

export default Modal;
