import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';

import InputField from 'components/InputField';
import Button from 'components/Button';
import FlexDiv from 'components/FlexDiv';
import DisabledSavePasswordInput from 'components/DisabledSavePasswordInput';

import { Content, Title } from './styles';

export interface UserLoginData {
  email: string;
  password: string;
}

const Form: React.FC<Pick<FormikProps<UserLoginData>, 'errors' | 'touched' | 'isSubmitting'>> = ({
  errors,
  touched,
  isSubmitting,
  ...props
}) => {
  const t = usePrefix('Login');

  return (
    <>
      <Content {...props} autoComplete="off">
        <Title>{t('logging')}</Title>
        <DisabledSavePasswordInput />
        <InputField
          name="email"
          error={touched.email && errors.email}
          label={t('email')}
          placeholder={t('email_placeholder')}
          margin="15px 0 17px"
          autoComplete="off"
        />
        <InputField
          name="password"
          type="password"
          error={touched.password && errors.password}
          label={t('password')}
          placeholder="••••••••••"
          margin="0 0 25px"
          autoComplete="off"
        />
        <FlexDiv flexDirection="column" alignItems="center">
          <Button label={t('logIn')} type="submit" disabled={isSubmitting} />
        </FlexDiv>
      </Content>
    </>
  );
};

export default Form;
