import React, { FC } from 'react';

import { ButtonColors } from 'components/Button/styles';

import { AnchorStyled } from './styles';

interface Props {
  color?: ButtonColors;
  disabled?: boolean;
  download?: string | boolean;
  href: string;
  label?: string;
  title?: string;
}

const Anchor: FC<Props> = ({ color = 'primary', download, href, label, title }) => (
  <AnchorStyled href={href} title={title} color={color} download={download}>
    {label}
  </AnchorStyled>
);

export default Anchor;
