import styled from 'styled-components';

import colors from 'styles/colors';
import { InputProps } from '.';

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: ${colors.gray_scale5};
`;

export const Checkmark = styled.span`
  width: 14px;
  height: 14px;
  border: solid 1px ${colors.brand_1};
`;

export const ValueStyled = styled.span`
  color: ${colors.gray};
`;

export const StyledInput = styled.input<InputProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${({ error }) => (error ? colors.error : 'transparent')};

  &:hover:not(:disabled) {
    & ~ ${Checkmark} {
      background-color: ${colors.gray_scale4};
    }
  }

  &:checked {
    & ~ ${Checkmark} {
      background-color: ${colors.brand_1};
    }
  }

  &:disabled {
    cursor: default;
    &:not(:checked) {
      cursor: default;
      & ~ ${ValueStyled} {
        color: ${colors.gray_scale2};
      }
      & ~ ${Checkmark} {
        border-color: ${colors.gray_scale4};
      }
    }
  }
`;
