import React from 'react';
import colors from 'styles/colors';

export const MODAL_CLOSE_ICON_SIZE = '46px';
const MODAL_WIDTH = '1232px';

interface ModalStyleProps {
  overlay: React.CSSProperties;
  content: React.CSSProperties;
}

const defaultContent: React.CSSProperties = {
  top: '0',
  right: '0',
  left: 'auto',
  bottom: '0',
  padding: '0',
  maxWidth: MODAL_WIDTH,
  width: '100%',
};

const parsedIconSize: number = parseInt(MODAL_CLOSE_ICON_SIZE, 10);

const centeredContent: React.CSSProperties = {
  top: '10%',
  right: '10%',
  left: '10%',
  bottom: '10%',
  // eslint-disable-next-line no-magic-numbers
  padding: `0 24px 0 ${parsedIconSize * 2}px`,
};

const smallContent: React.CSSProperties = {
  top: '50%',
  right: '30%',
  left: '30%',
  bottom: '30%',
  // eslint-disable-next-line no-magic-numbers
  padding: `0 24px 0 ${parsedIconSize * 2}px`,
};

interface Props {
  centered: boolean;
  small: boolean;
  paddingTop?: string;
}

const getStyles = (centered: boolean, small: boolean): React.CSSProperties =>
  centered ? (small ? smallContent : centeredContent) : defaultContent;

const modalStyles = ({ centered, small, paddingTop }: Props): ModalStyleProps => ({
  overlay: {
    zIndex: 999,
  },
  content: {
    boxShadow: `0 0 64px 0 ${colors.gray_shadow_05}`,
    border: 'none',
    boxSizing: 'border-box',
    ...getStyles(centered, small),
    paddingTop,
  },
});

export default modalStyles;
