import styled from 'styled-components';

import colors from 'styles/colors';
import FlexDiv from 'components/FlexDiv';

const PAGE_PADDING = '24px';

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 168px 1fr;
  padding: ${PAGE_PADDING};
  width: 100%;
`;

export const Content = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  width: 100%;
`;

export const ButtonContainer = styled(FlexDiv)`
  align-self: flex-end;
  margin-left: auto;
  padding-left: 16px;
  color: ${colors.black};
`;

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  width: 100%;
`;

export const UsersWrapper = styled.div`
  flex: 1;
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const ObjectReportWrapper = styled.div`
  flex: 1;
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const UsersList = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  list-style: none;
  ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  > div {
    > div {
      padding: 0 192px;
      scrollbar-width: thin;
      overflow-y: auto;
    }
  }
`;
