import React from 'react';

import usePrefix from 'utils/usePrefix';
import { AttachmentResponse } from 'utils/api/chats';
import { filenameFromPathRegex } from 'utils/validation';

import { AttachmentImage, AttachmentLink } from './styles';

const MessageAttachment: React.FC<{ attachment: AttachmentResponse }> = ({ attachment }) => {
  const t = usePrefix('General');

  let filename = attachment.name;
  if (!filename) {
    const regArray = attachment.uri.match(filenameFromPathRegex);
    // eslint-disable-next-line no-magic-numbers
    if (regArray?.length) filename = regArray[0];
  }

  return attachment.mimeType?.includes('image') ? (
    <AttachmentImage
      target="_blank"
      mimeType={attachment.mimeType}
      src={`/${attachment.uri}`}
      alt={filename || t('file')}
    />
  ) : (
    <AttachmentLink
      type={attachment.mimeType ? attachment.mimeType : undefined}
      target="_blank"
      href={`/${attachment.uri}`}
    >
      {filename || t('file')}
    </AttachmentLink>
  );
};
export default MessageAttachment;
