import styled from 'styled-components';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';
import FlexDiv from 'components/FlexDiv';
import Icon from 'components/Icons';
import DetailsTitle from 'components/DetailsTitle';

export const Attachments = styled(FlexDiv)<{ paddingTop: string }>`
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-left: 20px;
  border-left: 1px solid ${colors.black_shadow};
`;

export const AttachmentTitle = styled(DetailsTitle)<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const AttachList = styled.ul`
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const AttachItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  min-width: 0;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Anchor = styled.a`
  display: flex;
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brand_2};
  word-break: break-word;
  transition: color ${ANIMATION};
  @media (hover: hover) {
    &:hover {
      color: ${colors.brand_2_scale2};
    }
  }
`;

export const IconStyled = styled(Icon)`
  flex: 0 0 20px;
  margin-left: 8px;
  path {
    fill: ${colors.gray_scale3};
  }
`;
