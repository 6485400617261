import styled, { css } from 'styled-components';
import { Form } from 'formik';

import colors from 'styles/colors';
import Icon from 'components/Icons';
import FlexDiv from 'components/FlexDiv';
import { loginFormStyles, loginTitleStyles } from 'components/LoginWrapper/styles';
import React from 'react';

export const Content = styled(Form)`
  ${loginFormStyles};
`;

export const Title = styled.h1`
  ${loginTitleStyles};
`;

export const ButtonContainer = styled(FlexDiv)``;

export const RequirementsStyled = styled.div`
  margin-top: 80px;
  margin-bottom: -40px;
  width: 280px;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.gray};
`;

export interface RequirementProps {
  children?: React.ReactNode;
  touched?: boolean;
  error: boolean;
}

export const RequirementStyled = styled.div<RequirementProps>`
  ${({ touched, error }) =>
    touched &&
    css`
      color: ${error ? colors.error : colors.success};
    `};
`;

export const StyledIcon = styled(Icon)`
  margin-right: 1px;
`;
