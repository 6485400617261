import React from 'react';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import usePrefix from 'utils/usePrefix';
import TextInput from 'components/TextInput';
import Icon from 'components/Icons';

interface Props {
  name: string;
  placeholder: string;
  value?: string;
  type?: string;
  error?: boolean;
  disabled?: boolean;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  short?: boolean;
  isModal?: boolean;
}

const SearchBarContent: React.FC<Props> = ({ onClear, isDisabled, short, isModal, ...props }) => {
  const t = usePrefix('General');

  return (
    <SearchBarContentStyled short={short} isModal={isModal}>
      <TextInput dimension="lg" isButton {...props} />
      <ButtonResetStyled
        onClick={onClear}
        title={t('cta_clear')}
        disabled={isDisabled}
        short={short}
      >
        <Icon color={colors.brand_2} type="close" />
      </ButtonResetStyled>
    </SearchBarContentStyled>
  );
};

const isShortStyles = css`
  height: 50px;
  font-size: 16px;
`;

const isModalStyles = css`
  max-width: 296px;
`;

const SearchBar = styled(SearchBarContent)<{ short?: boolean }>`
  height: 40px;
  color: ${colors.gray};

  ${({ short }) => short && isShortStyles}

  &::placeholder {
    color: ${colors.brand_shadow_05};
  }
`;

const SearchBarContentStyled = styled.div<{ short?: boolean; isModal?: boolean }>`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 662px;
  ${({ short }) => short && 'margin-bottom: 30px;'};
  ${({ isModal }) => isModal && isModalStyles}
`;

const ButtonResetStyled = styled.button<{ short?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: ${({ short }) => (short ? '50px' : '40px')};
  height: 100%;
  border-radius: 4px;
  border: 0;
  background: transparent;
  cursor: pointer;

  &:disabled {
    visibility: hidden;
    cursor: default;
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      background: ${colors.brand_2_tint_10};
    }
  }
`;

export default SearchBar;
