import React, { useEffect } from 'react';

import { useApi, getApiUrl } from 'utils/api/useApi';
import { ReceivedArticleDetailsResponse, ReceivedArticleResponse } from 'utils/api/inbox';
import usePrefix from 'utils/usePrefix';
import { PaginatedList } from 'utils/api';

import NoticeContent from 'components/NoticeContent';
import Loader from 'components/Loader';

const url = getApiUrl('/interveners/me/research-and-articles');

interface Props {
  articleId: number;
  fetchData: () => Promise<void | PaginatedList<ReceivedArticleResponse[]>>;
}

const Article: React.FC<Props> = ({ articleId, fetchData }) => {
  const t = usePrefix('Articles');

  const { data, isLoading } = useApi<ReceivedArticleDetailsResponse>(`${url}/${articleId}`, {
    method: 'GET',
  });

  useEffect(() => {
    if (data) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) return null;

  return !isLoading ? <NoticeContent {...data.details} /> : <Loader label={t('loading')} />;
};

export default Article;
