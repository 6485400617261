import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';

export const Wrapper = styled(FlexDiv)`
  width: 100%;
  height: 100%;
  list-style: none;
`;

export const IntervenersList = styled.div`
  flex: 1;
  padding: 24px 0 0;
  width: 100%;
  overflow-y: auto;
`;
