/* eslint-disable no-extra-parens */
import React, { InputHTMLAttributes } from 'react';
import { FieldProps, Field } from 'formik';

import TextInput from 'components/TextInput';
import FormField from 'components/FormField';

interface Props {
  autoComplete?: 'on' | 'off';
  name: string;
  placeholder?: string;
  label?: string;
  error?: boolean | string;
  type?: string;
  isEditing?: boolean;
  disabled?: boolean;
  margin?: string;
  autoFocus?: boolean;
  onFocus?: () => void;
  width?: string;
  dimension?: string;
  login?: boolean;
}

const InputField: React.FC<Props & InputHTMLAttributes<HTMLInputElement>> = ({
  autoComplete,
  name,
  placeholder,
  label,
  margin = '0',
  error,
  type = 'text',
  isEditing = true,
  disabled = false,
  autoFocus,
  dimension = 'lg',
  onFocus,
  login,
  ...props
}) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <FormField error={error} label={label} {...props} margin={margin} login={login}>
        {isEditing ? (
          <TextInput
            autoComplete={autoComplete}
            placeholder={placeholder || ''}
            error={error}
            type={type}
            disabled={disabled}
            autoFocus={autoFocus}
            onFocus={onFocus}
            dimension={dimension}
            {...field}
            name={name}
            value={field.value || ''}
          />
        ) : (
          field.value
        )}
      </FormField>
    )}
  </Field>
);

export default InputField;
