import React, { useEffect } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import { notificationTypes } from 'utils/constants';
import { userRoles } from 'utils/userRoles';
import { api, getApiUrl, ResponseError, Violence } from 'utils/api';

import PrivateRoute from 'containers/PrivateRoute';
import RetrievePassword from 'pages/RetrievePassword';
import ResetPassword from 'pages/ResetPassword';
import Login from 'pages/Login';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import Schools from 'pages/Schools';
import Chat from 'pages/Chat';
import Notices from 'pages/Notices';
import Advices from 'pages/Advices';
import Instructions from 'pages/Instructions';
import Reports from 'pages/Reports';
import Movies from 'pages/Movies';
import Articles from 'pages/Articles';
import Notifications from 'components/Notifications';
import Layout from 'components/Layout';
import Navigation from 'components/Navigation';
import { SET_PASSWORD_URL } from 'App/constants';

const url = getApiUrl('/violence-types');

const HomeApp: React.FC = () => {
  const [{ profile, notifications }, dispatch] = useApp();

  const loadViolenceTypes = async () => {
    try {
      const response: Violence[] = await api(url, {
        method: 'GET',
      });
      if (response) {
        dispatch({ type: ActionTypes.GET_VIOLENCE_TYPES, payload: response });
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    if (profile) loadViolenceTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <Layout>
      {!!profile && <Navigation />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={`/${SET_PASSWORD_URL}`} element={<RetrievePassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/reports"
          element={
            <PrivateRoute
              Component={Reports}
              requiredRole={[userRoles.admin, userRoles.director]}
            />
          }
        />
        <Route
          path="/chat"
          element={
            <PrivateRoute
              Component={Chat}
              requiredRole={[userRoles.intervener, userRoles.director]}
            />
          }
        />
        <Route
          path="/users"
          element={<PrivateRoute Component={Users} requiredRole={[userRoles.admin]} />}
        />
        <Route
          path="/schools"
          element={<PrivateRoute Component={Schools} requiredRole={[userRoles.admin]} />}
        />
        <Route path="/profile" element={<PrivateRoute Component={Profile} />} />
        <Route
          path="/notices"
          element={<PrivateRoute Component={Notices} requiredRole={[userRoles.admin]} />}
        />
        <Route
          path="/advices"
          element={
            <PrivateRoute
              Component={Advices}
              requiredRole={[userRoles.intervener, userRoles.director]}
            />
          }
        />
        <Route
          path="/instructions"
          element={
            <PrivateRoute
              Component={Instructions}
              requiredRole={[userRoles.intervener, userRoles.director]}
            />
          }
        />
        <Route
          path="/movies"
          element={
            <PrivateRoute
              Component={Movies}
              requiredRole={[userRoles.intervener, userRoles.director]}
            />
          }
        />
        <Route
          path="/articles"
          element={
            <PrivateRoute
              Component={Articles}
              requiredRole={[userRoles.intervener, userRoles.director]}
            />
          }
        />
        <Route path="*" element={<Navigate to="/app" />} />
      </Routes>
      {!!notifications.length && <Notifications notifications={notifications} />}
    </Layout>
  );
};

export default HomeApp;
