import React from 'react';
import { ButtonLangStyled } from './styles';

export interface Props {
  isActive?: boolean;
  label?: string;
  onClick?: () => void;
}

const ButtonLang: React.FC<Props> = ({ isActive, label, onClick}) => (
  <ButtonLangStyled
    isActive={isActive}
    onClick={onClick}
    type="button"
  >
    {label}
  </ButtonLangStyled>
);

export default ButtonLang;
