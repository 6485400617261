import React from 'react';

import Dot from 'components/Dot';
import { Wrapper, Content, Title, Subtitle } from './styles';

interface Props {
  title: string;
  subtitle?: string | null;
  selected?: boolean;
  disabled?: boolean;
  unread?: boolean;
  clickable?: boolean;
  attention?: boolean;
  violenceType?: string;
  onClick?: () => void;
  maxHeight?: string;
  color?: string;
  style?: object;
}

const RowShort: React.FC<Props> = ({
  title,
  subtitle,
  onClick,
  selected = false,
  disabled = false,
  unread = false,
  clickable = true,
  attention = false,
  maxHeight,
  color,
  style,
}) => (
  <Wrapper style={style}>
    <Content
      onClick={() => !disabled && !!onClick && onClick()}
      clickable={clickable}
      selected={selected}
      unread={unread}
      disabled={disabled}
      alignItems="center"
      justifyContent="space-between"
      attention={attention}
    >
      <div>
        <Title maxHeight={maxHeight} color={color}>
          {title}
        </Title>
        <Subtitle color={color}>{subtitle}</Subtitle>
      </div>
      <Dot selected={selected} />
    </Content>
  </Wrapper>
);

export default RowShort;
