import React, { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import usePrefix from 'utils/usePrefix';
import { TagResponse } from 'utils/api/chats';
import { chatlistTypes } from 'utils/constants';
import { ChatListTypes } from 'utils/api';

import PageTitle from 'components/PageTitle';
import NoData from 'components/NoData';
import RowShort from 'components/RowShort';
import SearchBar from 'components/SearchBar';

import { ListState } from '..';
import { PageTop } from '../styles';
import { Wrapper, CategoriesWrapper, TabsConatiner, ArchiveSwitcherGroupStyled } from './styles';
import ChatTab from './ChatTab';
import ArchiveSwitcher from './ArchiveSwitcher';

interface ListProps {
  setChatId: (chatId: number) => void;
  PAGE_START: number;
  PAGE_SIZE: number;
  totalElements?: number;
  loadMore: () => void;
  currentPage: number;
  chatId?: number;
  listFilter: ChatListTypes;
  handleListFilter: (filter: ChatListTypes) => void;
  tags?: TagResponse[];
  isLoadingTags: boolean;
  handleSelectedTags: (tagId: string) => void;
  handleIsArchive: (isArchive: boolean) => void;
  isArchive: boolean;
  query?: string;
  setQuery?: (query: string) => void;
  resetQuery?: () => void;
  isSearchClearDisabled?: boolean;
}

type ListStateProps = Omit<ListState, 'page' | 'size' | 'isArchive'>;

const ChatList: React.FC<ListProps & ListStateProps> = ({
  setChatId,
  chatList,
  totalElements,
  PAGE_START,
  PAGE_SIZE,
  loadMore,
  currentPage,
  chatId,
  listFilter,
  handleListFilter,
  handleIsArchive,
  query,
  setQuery,
  isArchive,
  resetQuery,
  isSearchClearDisabled,
}) => {
  const [showAllTags, setShowAllTags] = useState(false);

  const t = usePrefix('Chat');

  const scrollParentRef = useRef(null);

  const filteredListByAttentionRequired = (attentionRequired: boolean) =>
    chatList.filter((chat) => chat.attention_required === attentionRequired);

  return (
    <Wrapper
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
    >
      <PageTop>
        <PageTitle title={t('messages')} />
        <ArchiveSwitcherGroupStyled>
          <ArchiveSwitcher handleIsArchive={handleIsArchive} isArchive={isArchive} />
        </ArchiveSwitcherGroupStyled>
        {setQuery && (
          <SearchBar
            name="filter"
            placeholder={t('search')}
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            onClear={resetQuery}
            isDisabled={isSearchClearDisabled}
            short
          />
        )}
        <TabsConatiner>
          <ChatTab
            value={chatlistTypes.all}
            text={t('all')}
            listFilter={listFilter}
            onClick={handleListFilter}
          />
          <ChatTab
            value={chatlistTypes.important}
            text={t('my_important')}
            listFilter={listFilter}
            onClick={handleListFilter}
          />
          <ChatTab
            value={chatlistTypes.assigned}
            text={t('my_all')}
            listFilter={listFilter}
            onClick={handleListFilter}
          />
        </TabsConatiner>
      </PageTop>
      <CategoriesWrapper ref={scrollParentRef}>
        <InfiniteScroll
          pageStart={PAGE_START}
          loadMore={loadMore}
          hasMore={!!totalElements && totalElements > (currentPage + PAGE_START + 1) * PAGE_SIZE}
          getScrollParent={() => scrollParentRef.current}
          threshold={100}
          useWindow={false}
          initialLoad={false}
        >
          {chatList.length ? (
            <>
              {filteredListByAttentionRequired(true).map((chat) => (
                <RowShort
                  key={chat.id}
                  title={
                    chat.nickname
                      ? chat.nickname
                      : chat.chat_number
                      ? t('username_number', { number: chat.chat_number })
                      : t('username')
                  }
                  subtitle={chat.last_message.message}
                  onClick={() => setChatId(chat.id)}
                  selected={!!chatId && chat.id === chatId}
                  unread={!!chat.unread_messages_count}
                  attention
                />
              ))}
              {filteredListByAttentionRequired(false).map((chat) => (
                <RowShort
                  key={chat.id}
                  title={
                    chat.nickname
                      ? chat.nickname
                      : chat.chat_number
                      ? t('username_number', { number: chat.chat_number })
                      : t('username')
                  }
                  subtitle={chat.last_message.message}
                  onClick={() => setChatId(chat.id)}
                  selected={!!chatId && chat.id === chatId}
                  unread={!!chat.unread_messages_count}
                />
              ))}
            </>
          ) : (
            <NoData title={t('no_chats')} />
          )}
        </InfiniteScroll>
      </CategoriesWrapper>
    </Wrapper>
  );
};

export default ChatList;
