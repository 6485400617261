import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import usePrefix from 'utils/usePrefix';
import { getApiUrl, api, ResponseError } from 'utils/api';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';

import TextArea from 'components/TextArea';
import Icon from 'components/Icons';
import { TEXT_AREA_HEIGHT } from 'components/TextArea/styles';

import { AnswerMessage, FormStyled, SendButton } from './styles';

const url = getApiUrl('/interveners/me/chats');

interface FormValues {
  message: string;
}

interface PanelProps {
  chatId?: number;
  disabled: boolean;
  onClearPanel: () => void;
  onClearAndReset: () => void;
}

const MessageForm: React.FC<PanelProps> = ({ chatId, disabled, onClearAndReset, onClearPanel }) => {
  const [, dispatch] = useApp();

  const t = usePrefix('Chat');

  const submit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    try {
      const response = await api(`${url}/${chatId}/messages/text-message`, {
        method: 'POST',
        payload: {
          ...values,
        },
      });

      if (response) {
        resetForm();
        onClearAndReset();
        onClearPanel();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <AnswerMessage>
      <Formik initialValues={{ message: '' }} onSubmit={submit}>
        {({ isSubmitting }) => (
          <FormStyled autoComplete="off">
            <TextArea
              name="message"
              placeholder={t('type_message')}
              disabled={disabled}
              maxLength={1000}
              height={TEXT_AREA_HEIGHT}
            />
            <SendButton type="submit" disabled={disabled || isSubmitting}>
              <Icon type="send" width="21px" height="18px" />
            </SendButton>
          </FormStyled>
        )}
      </Formik>
    </AnswerMessage>
  );
};

export default MessageForm;
