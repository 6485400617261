import React, { useState, ChangeEvent } from 'react';
import dayjs from 'dayjs';

import { ActionTypes } from 'App/types';
import { useApp } from 'App';
import usePrefix from 'utils/usePrefix';
import { useApi, getApiUrl } from 'utils/api/useApi';
import { api, ResponseError } from 'utils/api';
import { ChatDetailsResponse } from 'utils/api/chats';
import { notificationTypes } from 'utils/constants';
import colors from 'styles/colors';

import Button from 'components/Button';
import FlexDiv from 'components/FlexDiv';
import Loader from 'components/Loader';
import RowShort from 'components/RowShort';
import NoData from 'components/NoData';

import MetaSection from '../MetaSection';
import { NoteActionButton, TextArea, PastNote, PastNoteInfo } from './styles';

const url = getApiUrl('/interveners/me/chats');

interface Props {
  chatId: number;
  closeNotesScreen: () => void;
}

const NotesScreen: React.FC<Props> = ({ chatId, closeNotesScreen }) => {
  const t = usePrefix('Chat');

  const [note, setNote] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState<number>();

  const { data, isLoading, fetchData } = useApi<ChatDetailsResponse>(`${url}/${chatId}`, {
    method: 'GET',
  });

  const [, dispatch] = useApp();

  const isValueNotChanged = (note: string) =>
    !!selectedNoteId &&
    note === data?.chat.notes.find((noteData) => noteData.id === selectedNoteId)?.note;

  const submit = async () => {
    setIsSubmitting(true);

    try {
      const response = await api(`${url}/${chatId}/notes`, {
        method: 'POST',
        payload: { note },
      });

      if (response) {
        setNote('');
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const selectedNote = data?.chat.notes.find((note) => note.id === selectedNoteId);

  return (
    <FlexDiv flex={1}>
      <MetaSection title={t('selected_note_content')} absolute>
        {!isLoading ? (
          <FlexDiv justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap" flex={1}>
            <TextArea
              placeholder={t('provide_notes')}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value)}
              value={note}
              autoFocus
            />

            <NoteActionButton justifyContent="flex-end" alignItems="flex-start">
              <Button
                label={t('save').toUpperCase()}
                disabled={isValueNotChanged(note) || !note || isSubmitting}
                color="secondary"
                onClick={() => submit()}
              />
            </NoteActionButton>
          </FlexDiv>
        ) : (
          <Loader label={t('fetching_meta_data')} />
        )}

        {selectedNote && (
          <>
            <PastNote>{selectedNote.note}</PastNote>
            <PastNoteInfo>
              {dayjs(selectedNote.creation_time).format('H:mm DD.MM.YYYY')} {selectedNote.author}
            </PastNoteInfo>
          </>
        )}
      </MetaSection>
      <MetaSection iconType="close" onClick={closeNotesScreen} title={t('notes')}>
        {!isLoading && !!data && (
          <>
            {data.chat.notes.map((note) => (
              <RowShort
                key={note.id}
                title={note.note}
                subtitle={dayjs(note.creation_time).format('H:mm DD.MM.YYYY')}
                onClick={() => setSelectedNoteId(note.id)}
                selected={!!selectedNoteId && note.id === selectedNoteId}
                attention
                maxHeight="50px"
                color={colors.brand_2}
              />
            ))}
            {!data.chat.notes.length && <NoData title={t('no_notes_to_display')} />}
          </>
        )}
      </MetaSection>
    </FlexDiv>
  );
};

export default NotesScreen;
