import React from 'react';
import { NavLink } from 'react-router-dom';

import rsqlH from 'statics/rsql_logo_h.svg';

import { HeaderStyled, LogoStyled } from './styles';

const Header: React.FC = () => (
  <HeaderStyled>
    <NavLink to="/">
      <LogoStyled src={rsqlH} width="100px" alt="RSQL Logo Horizontal" />
    </NavLink>
  </HeaderStyled>
);

export default Header;
