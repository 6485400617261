import styled, { css } from 'styled-components';
import colors from 'styles/colors';

import { InputProps } from '.';

export type inputDimension = 'lg' | 'md' | 'sm';

export const INPUT_PADDING = '12px';

const inputDimension = (dimension: inputDimension) =>
  ({
    lg: css`
      height: 48px;
      padding: 8px ${INPUT_PADDING};
    `,
    md: css`
      height: 40px;
      padding: 8px ${INPUT_PADDING};
    `,
    sm: css`
      height: 32px;
      padding: 4px ${INPUT_PADDING};
    `,
  })[dimension];

const isButtonStyles = css`
  padding-right: 60px;
`;

export const StyledInput = styled.input<InputProps & { textarea?: boolean } & { dimension: any }>`
  box-sizing: border-box;
  border: 1px solid ${({ error }) => (error ? colors.error : colors.brand_2)};
  border-radius: 4px;
  background-color: transparent;

  :focus {
    border-color: ${colors.brand_1};
    background-color: ${colors.white};
  }

  outline: none;
  padding: 0 ${INPUT_PADDING};
  width: 100%;
  color: ${colors.brand_2};
  transition: border-color 100ms linear;

  ${({ dimension }) => inputDimension(dimension)};
  ${({ isButton }) => isButton && isButtonStyles};

  ::placeholder {
    opacity: 0.5;
  }

  ::-webkit-input-placeholder {
    opacity: 0.5;
  }

  :-ms-input-placeholder {
    opacity: 0.5;
  }

  ::-webkit-clear-button,
  ::-ms-clear {
    display: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }

  ${({ textarea }) => textarea && 'height: auto'};
`;
