import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';
import { PostUserRequest } from 'utils/api/users';

import InputField from 'components/InputField';
import Button from 'components/Button';
import DetailsTitle from 'components/DetailsTitle';
import TopPanel from 'components/TopPanel';
import { DetailsFields } from 'components/DetailsFields';
import FormField from 'components/FormField';
import TextInput from 'components/TextInput';
import FlexDiv from 'components/FlexDiv';
import RadioInput from 'components/RadioInput';
import { languages } from 'utils/languages';

import { Content, InputsContainer, StyledFormField } from './styles';

const DetailsForm: React.FC<
  Pick<FormikProps<PostUserRequest>, 'errors' | 'touched' | 'isSubmitting' | 'values'>
> = ({ errors, touched, isSubmitting, values, ...props }) => {
  const t = usePrefix('General');

  return (
    <Content {...props} autoComplete="off">
      <TopPanel>
        <DetailsTitle empty={false} title={`${values.first_name} ${values.last_name}`} />
        <FlexDiv>
          <Button label={t('save_changes')} type="submit" disabled={isSubmitting} />
        </FlexDiv>
      </TopPanel>
      <DetailsFields>
        <InputsContainer>
          <InputField
            name="first_name"
            error={touched.first_name && errors.first_name}
            label={t('firstname')}
            margin="0 0 24px"
          />
          <InputField
            name="email"
            error={touched.email && errors.email}
            label={t('email')}
            type="email"
            margin="0 0 24px"
          />
        </InputsContainer>
        <InputsContainer>
          <InputField
            name="last_name"
            error={touched.last_name && errors.last_name}
            label={t('lastname')}
            margin="0 0 24px"
          />
          <InputField
            name="phone"
            error={touched.phone && errors.phone}
            label={t('phone_no')}
            type="tel"
            margin="0 0 24px"
          />
        </InputsContainer>
        <InputsContainer>
          <FormField label={t('role')} {...props} margin={'0 0 24px'}>
            <TextInput name="role" type={'text'} value={t(values.role)} disabled={true} />
          </FormField>
        </InputsContainer>
        <InputsContainer />
        <InputsContainer>
          <StyledFormField label={t('language')} margin={'0'}>
            <RadioInput
              name="user_language"
              error={touched.user_language && !!errors.user_language}
              label="PL"
              value={languages.pl}
            />
            <RadioInput
              name="user_language"
              error={touched.user_language && !!errors.user_language}
              label="EN"
              value={languages.en}
            />
            <RadioInput
              name="user_language"
              error={touched.user_language && !!errors.user_language}
              label="CZ"
              value={languages.cs}
            />
            <RadioInput
              name="user_language"
              error={touched.user_language && !!errors.user_language}
              label="SK"
              value={languages.sk}
            />
            <RadioInput
              name="user_language"
              error={touched.user_language && !!errors.user_language}
              label="UA"
              value={languages.uk}
            />
          </StyledFormField>
        </InputsContainer>
      </DetailsFields>
    </Content>
  );
};

export default DetailsForm;
