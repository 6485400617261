import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.label`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 35px;
  height: 14px;
`;

export const Slider = styled.span`
  position: relative;
  display: inline-flex;
  width: 35px;
  height: 14px;
  border-radius: 7px;
  background-color: ${colors.gray_scale4};
  transition: 0.4s;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: ${colors.gray_scale3};
    border-radius: 50%;
    transition: 0.4s;
    cursor: pointer;
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 12px;
  opacity: 0;
  &:checked + ${Slider} {
    background-color: ${colors.brand_shadow_025};
    &:before {
      transform: translate(15px, -50%);
      background-color: ${colors.brand_2};
    }
  }
`;
