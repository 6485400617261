import React from 'react';

import { ChatListTypes } from 'utils/api';

import { StyledChatTab } from './styles';

interface Props {
  value: ChatListTypes;
  text: string;
  listFilter: ChatListTypes;
  onClick: (filter: ChatListTypes) => void;
}

const ChatTab: React.FC<Props> = ({ value, text, listFilter, onClick }) => (
  <StyledChatTab active={listFilter === value} onClick={() => onClick(value)}>
    {text}
  </StyledChatTab>
);

export default ChatTab;
