import React, { InputHTMLAttributes } from 'react';
import { FieldProps, Field } from 'formik';

import { Slider, StyledInput, Wrapper } from './styles';

interface InputProps {
  name: string;
  checked: boolean;
}

const ToggleSwitch: React.FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  name,
  checked,
  ...props
}) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <Wrapper>
        <StyledInput {...field} type="checkbox" name={name} checked={checked} {...props} />
        <Slider />
      </Wrapper>
    )}
  </Field>
);

export default ToggleSwitch;
