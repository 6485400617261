import React from 'react';

import { Wrapper } from './styles';

interface NoticeContainerProps {
  children?: React.ReactNode;
}

const NoticeContainer: React.FC<NoticeContainerProps> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default NoticeContainer;
