import React from 'react';

export interface IconProps {
  color: string;
  width: string;
  height: string;
  onClick?: () => void;
}

export const ExitIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props}>
    <path
      fill={color}
      d="M13,2.99999996 L11,2.99999996 L11,13 L13,13 L13,2.99999996 Z M17.83,5.16999997 L16.41,6.58999998 C17.99,7.85999998 19,9.80999999 19,12 C19,15.87 15.87,19 12,19 C8.12999998,19 4.99999996,15.87 4.99999996,12 C4.99999996,9.80999999 6.00999998,7.85999998 7.57999998,6.57999998 L6.16999998,5.16999997 C4.22999997,6.81999998 2.99999996,9.25999999 2.99999996,12 C2.99999996,16.97 7.02999998,21 12,21 C16.97,21 21,16.97 21,12 C21,9.25999999 19.77,6.81999998 17.83,5.16999997 Z"
    ></path>
  </svg>
);

export const BulbIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M12,2A7,7 0 0,1 19,9C19,11.38 17.81,13.47 16,14.74V17A1,1 0 0,1 15,18H9A1,1 0 0,1 8,17V14.74C6.19,13.47 5,11.38 5,9A7,7 0 0,1 12,2M9,21V20H15V21A1,1 0 0,1 14,22H10A1,1 0 0,1 9,21M12,4A5,5 0 0,0 7,9C7,11.05 8.23,12.81 10,13.58V16H14V13.58C15.77,12.81 17,11.05 17,9A5,5 0 0,0 12,4Z"
    />
  </svg>
);

export const ChatIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M20,1.99999996 L3.99999996,1.99999996 C2.89999996,1.99999996 1.99999996,2.89999996 1.99999996,3.99999996 L1.99999996,22 L5.99999996,18 L20,18 C21.1,18 22,17.1 22,16 L22,3.99999996 C22,2.89999996 21.1,1.99999996 20,1.99999996 Z M20,16 L5.99999997,16 L3.99999997,18 L3.99999997,3.99999997 L20,3.99999997 L20,16 Z"
    />
  </svg>
);

export const CloseIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
    />
  </svg>
);

export const CheckIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path fill={color} d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
  </svg>
);

export const SendIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path fill={color} d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
  </svg>
);

export const ReportsIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <g fill={color}>
      <path
        fill={color}
        d="M351.195,101.733h-6.696v20.217c0,11.09-9.06,20.15-20.22,20.15H176.12c-11.087,0-20.15-9.06-20.15-20.15v-20.217h-6.762
        c-18.526,0-33.675,15.145-33.675,33.674v282.781c0,18.596,15.148,33.678,33.675,33.678h201.987
        c18.596,0,33.665-15.082,33.665-33.678V135.407C384.86,116.878,369.791,101.733,351.195,101.733z M169.699,401.283
        c-9.199,0-16.839-7.501-16.839-16.693c0-9.205,7.303-16.706,16.498-16.706h0.341c9.261,0,16.7,7.501,16.7,16.706
        C186.398,393.782,178.96,401.283,169.699,401.283z M169.699,340.638c-9.199,0-16.839-7.442-16.839-16.707
        c0-9.192,7.303-16.634,16.498-16.634h0.341c9.261,0,16.7,7.441,16.7,16.634C186.398,333.195,178.96,340.638,169.699,340.638z
         M169.699,280.109c-9.199,0-16.839-7.502-16.839-16.7c0-9.195,7.303-16.7,16.498-16.7h0.341c9.261,0,16.7,7.504,16.7,16.7
        C186.398,272.607,178.96,280.109,169.699,280.109z M169.699,219.457c-9.199,0-16.839-7.436-16.839-16.635
        c0-9.195,7.303-16.7,16.498-16.7h0.341c9.261,0,16.7,7.505,16.7,16.7C186.398,212.021,178.96,219.457,169.699,219.457z
         M340.167,392.29H217.505c-4.259,0-7.71-3.447-7.71-7.634c0-4.266,3.451-7.713,7.71-7.713h122.662c4.267,0,7.713,3.447,7.713,7.713
        C347.88,388.843,344.434,392.29,340.167,392.29z M340.167,333.875H217.505c-4.259,0-7.71-3.381-7.71-7.646
        c0-4.187,3.451-7.641,7.71-7.641h122.662c4.267,0,7.713,3.454,7.713,7.641C347.88,330.494,344.434,333.875,340.167,333.875z
         M340.167,268.817H217.505c-4.259,0-7.71-3.446-7.71-7.706c0-4.193,3.451-7.64,7.71-7.64h122.662c4.267,0,7.713,3.446,7.713,7.64
        C347.88,265.371,344.434,268.817,340.167,268.817z M340.167,210.396H217.505c-4.259,0-7.71-3.447-7.71-7.64
        c0-4.259,3.451-7.709,7.71-7.709h122.662c4.267,0,7.713,3.45,7.713,7.709C347.88,206.95,344.434,210.396,340.167,210.396z"
      />
      <path
        fill={color}
        d="M176.15,128.668h148.129c3.711,0,6.735-3.011,6.735-6.735V95.004c0-11.14-9.06-20.2-20.206-20.2h-33.651
        c0-14.851-12.098-26.936-26.933-26.936h-0.006c-7.202,0-13.96,2.8-19.052,7.891c-5.084,5.085-7.887,11.847-7.887,19.044h-33.665
        c-11.14,0-20.197,9.06-20.197,20.2v26.929C169.418,125.657,172.426,128.668,176.15,128.668"
      />
    </g>
  </svg>
);

export const NotesIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 36 36" {...props}>
    <g fill={color} fillRule="evenodd" transform="translate(4 9)">
      <rect width="28" height="5" fill="#E6C229" rx="2.5" />
      <rect width="15" height="5" y="7" fill="#ebce54" rx="2.5" />
      <rect width="22" height="5" y="14" fill="#f0da7f" rx="2.5" />
    </g>
  </svg>
);

export const PlusIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path fill={color} d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </svg>
);

export const MinusIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path fill={color} d="M19 13H5v-2h14v2z" />
  </svg>
);

export const EditIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
    />
  </svg>
);

export const ChevronDownIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path fill={color} d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export const ErrorCircleIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
    />
  </svg>
);

export const SuccessCircleIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
    />
  </svg>
);

export const AttachIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"
    />
  </svg>
);

export const LogoIcon: React.FC<IconProps> = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 60 52">
    <defs>
      <polygon
        id="path-1"
        points="0.00515759312 0.0705730659 48.1678797 0.0705730659 48.1678797 14.9570201 0.00515759312 14.9570201"
      ></polygon>
    </defs>
    <g id="02-Chat" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="BOI---D---01.1" transform="translate(-45.000000, -20.000000)">
        <g id="Logo-/-Logotyp-/-Pion-/-01-White" transform="translate(45.000000, 20.000000)">
          <g id="Resql_LOGO_pion_bialy">
            <path
              d="M29.9930372,5.16550143 C31.5767622,5.16550143 33.0547564,5.61550143 34.3085673,6.39292264 L37.9945272,2.70679083 C35.7660172,1.03169054 32.995616,0.0385959885 29.9930372,0.0385959885 C26.9905444,0.0385959885 24.2199713,1.03169054 21.9914613,2.70679083 L25.6774212,6.39292264 C26.9312321,5.61550143 28.4093983,5.16550143 29.9930372,5.16550143"
              id="Fill-1"
              fill={color}
            ></path>
            <path
              d="M21.7899713,13.3684814 C21.7899713,11.7847564 22.2398854,10.3067622 23.0172206,9.05286533 L19.3313467,5.36690544 C17.6560745,7.59550143 16.6630659,10.3659885 16.6630659,13.3684814 C16.6630659,16.3710602 17.6560745,19.1414613 19.3313467,21.3700573 L23.0172206,17.6840974 C22.2398854,16.4302006 21.7899713,14.9522063 21.7899713,13.3684814"
              id="Fill-2"
              fill={color}
            ></path>
            <path
              d="M41.1853582,20.6102579 C42.5371633,18.5252149 43.3229226,16.0387393 43.3229226,13.3684814 C43.3229226,10.3659885 42.3298281,7.59550143 40.6546418,5.36690544 L36.9687679,9.05286533 C37.7461032,10.3067622 38.1960172,11.7847564 38.1960172,13.3684814 C38.1960172,14.8841261 37.7833238,16.3026361 37.0666762,17.5208596 C37.0580802,17.5770774 37.0485387,17.6352722 37.0374499,17.6963037 C36.5800573,20.2238682 37.7015759,21.842149 40.0110602,23.6067335 C37.0672779,24.3292264 34.240745,22.5945559 33.1217192,20.9522063 C32.1573352,21.3505444 31.1011461,21.5716332 29.9930372,21.5716332 C28.4093983,21.5716332 26.9312321,21.1215473 25.6774212,20.344212 L21.9914613,24.0302579 C24.2199713,25.7053582 26.9905444,26.6984527 29.9930372,26.6984527 C31.8032665,26.6984527 33.5288252,26.3369054 35.1024928,25.6831805 C39.396447,27.6267335 42.2112894,26.4823496 44.6116332,24.7231805 C44.6116332,24.7231805 42.190659,22.9879943 41.1853582,20.6102579"
              id="Fill-3"
              fill={color}
            ></path>
            <polyline
              id="Fill-4"
              fill={color}
              points="59.997765 50.0275358 50.8196562 50.0275358 50.8196562 36.6982521 53.637937 36.6982521 53.637937 47.5523209 59.997765 47.5523209 59.997765 50.0275358"
            ></polyline>
            <polyline
              id="Fill-5"
              fill={color}
              points="12.9137536 36.6982521 21.5016619 36.6982521 21.5016619 39.173639 15.7319484 39.173639 15.7319484 42.0680802 20.9684527 42.0680802 20.9684527 44.543467 15.7319484 44.543467 15.7319484 47.5523209 21.5969054 47.5523209 21.5969054 50.0275358 12.9137536 50.0275358 12.9137536 36.6982521"
            ></polyline>
            <g id="Group-10" transform="translate(0.000000, 36.361032)">
              <mask id="mask-2" fill={color}>
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-7"></g>
              <path
                d="M31.6497421,6.70684814 C29.7199427,5.47942693 26.7463324,5.31498567 26.7463324,3.76469914 C26.7463324,2.80487106 27.782063,2.50796562 28.7458453,2.50796562 C29.5212894,2.50796562 30.3652436,2.63080229 31.0364183,2.81510029 L32.9795415,0.871633238 C31.8354155,0.269398281 30.1009169,0.0705730659 28.8030086,0.0705730659 C26.0272779,0.0705730659 23.9282235,1.26765043 23.9282235,4.03126074 C23.9282235,5.1452149 24.2597708,6.21318052 25.6514613,7.11610315 C27.5494556,8.34945559 30.5546991,8.45518625 30.5546991,10.0103725 C30.5546991,11.247765 29.2176791,11.4958453 27.9841547,11.4958453 C27.1908309,11.4958453 26.4507163,11.367765 25.6983954,11.1487393 L23.7101433,13.1369914 C24.2064756,13.3463037 25.7906304,13.9319484 28.0214613,13.9317765 C31.8423782,13.9314327 33.3730659,12.1493123 33.3730659,9.68681948 C33.3730659,8.63200573 33.0332665,7.58819484 31.6497421,6.70684814"
                id="Fill-6"
                fill={color}
                mask="url(#mask-2)"
              ></path>
              <path
                d="M8.05100287,9.35578797 C7.34114613,8.33097421 6.55022923,8.02994269 6.55022923,8.02994269 C8.4030086,7.53618911 9.59707736,6.13530086 9.59707736,3.89793696 C9.59707736,1.02438395 6.98510029,0.146647564 4.13200573,0.146647564 C2.79034384,0.146647564 1.34492837,0.247048711 0,0.470458453 L0,13.6663324 L2.81810888,13.6663324 L2.81810888,2.69836676 C3.16383954,2.61868195 3.807851,2.6222063 4.32240688,2.6222063 C5.59005731,2.6222063 6.60747851,3.07401146 6.60747851,4.33598854 C6.60747851,5.47813754 6.07203438,6.34297994 4.12005731,6.3722063 L4.12005731,8.77246418 C4.5147851,9.02750716 4.85561605,9.36601719 5.11530086,9.77045845 L7.61664756,13.6663324 L10.8919771,13.6663324 C10.8919771,13.6663324 8.30295129,9.7191404 8.05100287,9.35578797"
                id="Fill-8"
                fill={color}
                mask="url(#mask-2)"
              ></path>
              <path
                d="M38.4748711,7.0017765 C38.4748711,4.50713467 39.2136103,2.54613181 41.7207163,2.54613181 C44.243467,2.54613181 45.0380802,4.48014327 45.0380802,7.0017765 C45.0380802,8.60140401 44.7585387,9.74389685 44.2001433,10.4294269 C43.641404,11.114957 42.7233524,11.4576791 41.7207163,11.4576791 C39.2819484,11.4576791 38.4748711,9.43977077 38.4748711,7.0017765 Z M46.3676218,12.1181948 C46.3687393,12.1170774 46.3697708,12.1158739 46.3709742,12.1146705 C47.6040688,10.7492837 48.0275072,8.8334957 48.0275072,7.0017765 C48.0275072,2.90621777 46.0197421,0.0705730659 41.7207163,0.0705730659 C37.5051576,0.0705730659 35.4852722,2.88782235 35.4852722,7.0017765 C35.4852722,11.177192 37.5476218,13.9329799 41.7207163,13.9329799 C42.2546132,13.9329799 42.7744126,13.8902579 43.2730659,13.7960458 C45.593639,16.1895989 48.1678797,14.1402292 48.1678797,14.1402292 C47.3529799,13.8762464 46.6709742,13.0325501 46.3676218,12.1181948 L46.3676218,12.1181948 Z"
                id="Fill-9"
                fill={color}
                mask="url(#mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const UserIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props}>
    <path
      fill={color}
      d="M12,5.89999997 C13.16,5.89999997 14.1,6.83999998 14.1,7.99999998 C14.1,9.15999999 13.16,10.1 12,10.1 C10.84,10.1 9.89999999,9.15999999 9.89999999,7.99999998 C9.89999999,6.83999998 10.84,5.89999997 12,5.89999997 L12,5.89999997 Z M12,14.9 C14.97,14.9 18.1,16.36 18.1,17 L18.1,18.1 L5.89999997,18.1 L5.89999997,17 C5.89999997,16.36 9.02999999,14.9 12,14.9 L12,14.9 Z M12,3.99999997 C9.78999999,3.99999997 7.99999998,5.78999997 7.99999998,7.99999997 C7.99999998,10.21 9.78999999,12 12,12 C14.21,12 16,10.21 16,7.99999997 C16,5.78999997 14.21,3.99999997 12,3.99999997 Z M12,13 C9.32999999,13 3.99999997,14.34 3.99999997,17 L3.99999997,20 L20,20 L20,17 C20,14.34 14.67,13 12,13 Z"
    />
  </svg>
);

export const UsersIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <path
      fill={color}
      d="M423.407,416.477l-11.23-44.945c-2.478-9.859-9.104-18.105-18.08-22.588l-37.326-19.438
		c-2.267-1.191-5.148-0.717-6.915,1.203c-1.773,1.922-2.037,4.791-0.653,7.004l8.093,12.934c2.721,4.379,3.381,9.693,1.773,14.6
		c-1.607,4.902-5.257,8.809-10.045,10.729l-26.25,10.488c-0.455,0.18-0.872,0.422-1.256,0.703l-35.636,25.904
		c-2.018,1.486-2.868,4.086-2.094,6.467c0.762,2.395,2.989,4.01,5.5,4.01l128.497,0.115c1.787,0,3.457-0.807,4.559-2.217
		C423.445,420.037,423.843,418.205,423.407,416.477z"
    />
    <path
      fill={color}
      d="M179.037,387.563c-0.475-0.346-1.012-0.613-1.569-0.807l-31.326-10.422c-5.085-1.703-9.028-5.57-10.848-10.604
		c-1.812-5.031-1.222-10.525,1.615-15.084l8.105-12.984c1.395-2.215,1.127-5.084-0.653-6.99c-1.755-1.908-4.572-2.408-6.915-1.205
		l-31.475,16.365c-9.059,4.533-15.679,12.779-18.17,22.703l-11.229,47.854c-0.404,1.715,0,3.533,1.1,4.93
		c1.089,1.369,2.768,2.188,4.528,2.188h128.438c2.511,0,4.727-1.625,5.507-3.994c0.769-2.381-0.063-5.006-2.101-6.467
		L179.037,387.563z"
    />
    <path
      fill={color}
      d="M344.714,365.23c1.595-0.639,2.824-1.959,3.361-3.584c0.531-1.627,0.313-3.393-0.602-4.865l-28.926-46.277
		c-1.051-1.703-2.92-2.715-4.912-2.715c-0.006,0-0.019,0-0.031,0c-1.997,0-3.854,1.049-4.904,2.752l-58.928,96.422l-64.397-96.6
		c-1.068-1.615-2.88-2.574-4.813-2.574c-0.025,0-0.059,0-0.091,0c-1.959,0.025-3.776,1.037-4.809,2.715l-28.938,46.277
		c-0.936,1.51-1.14,3.367-0.544,5.031c0.601,1.664,1.934,2.971,3.623,3.521l33.87,11.295l62.871,45.738
		c0.366,0.27,0.763,0.488,1.166,0.666c0.736,0.309,1.498,0.473,2.286,0.473c0.044,0,0.076,0,0.108,0
		c2.331-0.025,3.367-0.717,4.456-1.959l61.894-45.021L344.714,365.23z"
    />
    <path
      fill={color}
      d="M222.715,216.861V201.98c0-4.109-3.317-7.439-7.44-7.439c-4.097,0-7.427,3.33-7.427,7.439v14.881
		c0,4.121,3.33,7.451,7.427,7.451C219.397,224.313,222.715,220.982,222.715,216.861z"
    />
    <path
      fill={color}
      d="M292.143,216.861v-14.867c0-4.123-3.31-7.439-7.433-7.439c-4.104,0-7.434,3.316-7.434,7.439v14.867
		c0,4.109,3.329,7.439,7.434,7.439C288.833,224.301,292.143,220.971,292.143,216.861z"
    />
    <path
      fill={color}
      d="M196.888,297.57c0.346,0.063,0.691,0.09,1.037,0.09c0.107,0,0.166,0,0.217,0c0.16,0,0.301-0.09,0.461-0.09
		c31.027,30.232,70.876,27.824,102.971-0.242c21.071-3.754,87.971-20.143,74.02-34.139c-36.788-36.84,8.861-186.852-127.05-186.852
		c-56.624,0-102.696,44.127-102.696,98.367c0,2.217-0.154,4.715-0.282,7.301l-0.263,5.313c-0.186,2.613-0.429,5.379-0.736,8.248l0,0
		l0,0c-3.399,32.217-11.313,58.76-20.168,67.623c-2.267,2.266-2.267,5.928,0,8.182C139.924,286.891,191.112,296.508,196.888,297.57z
		 M178.883,238.426c-0.436-1.807-1.313-2.947-3.336-2.959c-15.084,3.188-12.043-35.305-11.863-35.791
		c0.013-0.012,36.015-18.592,49.683-56.137c22.436,21.922,79.583,53.32,125.009,58.969c1.134,0.152,2.241,0.203,3.362,0.318
		c2.689,14.521-0.807,30.643-6.717,32.346c-5.319,1.537-8.502-3.279-9.636,1.779c-4.341,19.387-15.456,37.211-30.04,50.49
		C247.774,330.723,194.256,300.414,178.883,238.426z"
    />
  </svg>
);

export const SchoolsIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <path
      fill={color}
      d="M333.199,417.9h-11.225V177.984h17.994c2.389,0,4.545-1.417,5.506-3.607c0.947-2.193,0.508-4.742-1.123-6.494
      L254.387,71.92c-2.291-2.422-6.477-2.422-8.768,0l-89.967,95.963c-1.631,1.752-2.068,4.301-1.127,6.494
      c0.967,2.19,3.117,3.607,5.51,3.607h17.994V417.9h-17.994c-3.307,0-6.002,2.681-6.002,5.993c0,3.32,2.695,6.003,6.002,6.003h23.988
      h35.982h59.982h35.992h17.219c3.301,0,5.996-2.683,5.996-6.003C339.195,420.581,336.5,417.9,333.199,417.9 M297.986,237.965
      c0,26.459-21.521,47.979-47.984,47.979s-47.984-21.521-47.984-47.979c0-26.463,21.521-47.984,47.984-47.984
      S297.986,211.502,297.986,237.965 M214.016,417.9V339.93c0-3.318,2.695-5.995,5.99-5.995h59.982c3.318,0,6.002,2.677,6.002,5.995
      V417.9H214.016z"
    />
    <path
      fill={color}
      d="M244,219.973v15.986c0,2.006,0.996,3.892,2.67,5.002l12,7.991c1.023,0.692,2.178,1.011,3.324,1.011
      c1.936,0,3.846-0.937,4.996-2.677c1.838-2.758,1.086-6.482-1.666-8.313l-9.326-6.211v-12.789c0-3.319-2.684-5.996-5.996-5.996
      C246.693,213.977,244,216.653,244,219.973"
    />
    <path
      fill={color}
      d="M166.031,399.913V255.959v-59.982c0-3.313-2.684-5.996-5.996-5.996h-59.982c-2.455,0-4.648,1.504-5.561,3.771
      l-23.961,59.877l0,0l-0.035,0.104c-0.045,0.103-0.01,0.221-0.045,0.324c-0.215,0.602-0.389,1.23-0.389,1.901v143.954
      c0,3.313,2.689,5.994,5.996,5.994h83.977C163.348,405.907,166.031,403.226,166.031,399.913 M154.033,351.922
      c0,3.317-2.682,6.002-5.996,6.002h-59.98c-3.303,0-5.998-2.685-5.998-6.002v-47.985c0-3.313,2.695-5.996,5.998-5.996h59.98
      c3.314,0,5.996,2.684,5.996,5.996V351.922z"
    />
    <path
      fill={color}
      d="M429.555,254.058c-0.035-0.104,0-0.222-0.047-0.324l-0.035-0.104l0,0l-23.959-59.877c-0.914-2.268-3.1-3.771-5.563-3.771
      h-59.982c-3.301,0-5.996,2.684-5.996,5.996v59.982v143.954c0,3.313,2.695,5.994,5.996,5.994h83.973
      c3.313,0,5.996-2.682,5.996-5.994V255.959C429.938,255.288,429.762,254.659,429.555,254.058 M417.945,351.922
      c0,3.317-2.684,6.002-6.004,6.002h-59.975c-3.301,0-5.996-2.685-5.996-6.002v-47.985c0-3.313,2.695-5.996,5.996-5.996h59.975
      c3.32,0,6.004,2.684,6.004,5.996V351.922z"
    />
  </svg>
);

export const MaterialIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <g>
      <path
        fill={color}
        d="M392.584,199.864c7.92-3.317,14.731-8.771,19.717-15.627C407.271,191.092,400.425,196.547,392.584,199.864z"
      />
      <path
        fill={color}
        d="M438,156.975c0-34.917-28.544-63.333-63.468-63.333c-26.67,0-49.345,16.576-58.666,39.963
        c-0.728,1.809-1.426,3.722-2.038,5.613c0.56-1.926,1.26-3.218,1.988-5.218h-0.009c-14.74,0-49.223,2.415-66.428,14.689
        c-16.957-10.306-56.797-15.055-71.208-15.055c-9.738,0-59.834,0.854-76.773,18.681c-1.151,1.197-2.398,2.752-2.398,4.411v2.927
        c-1,0-0.226-0.107-0.661,0l-32.295,6.448c-3.043,0.585-5.044,3.255-5.044,6.341v227.416c0,1.826,0.886,3.564,2.225,4.787
        c1.341,1.232,3.107,1.828,5.015,1.695l144.129-12.541c4.506,8.072,17.548,12.559,37.245,12.559c19.689,0,32.717-4.486,37.23-12.559
        l144.09,12.541c0.187,0,0.377,0.018,0.563,0.018c1.633,0,3.189-0.613,4.397-1.713c1.339-1.223,2.106-2.961,2.106-4.787V172.441
        C438,171.111,438,160.939,438,156.975z M242,362.43c-20-8.08-52.658-11.752-65.5-11.752c-8.08,0-42.5,0.631-65.5,10.98V159.6
        c12-8.976,41.475-13.073,65.5-13.073c18.572,0,55.5,6.235,65.5,13.224V362.43z M385,362.43c-20-8.08-52.157-11.752-65-11.752
        c-8.08,0-43,0.631-65,10.98V159.6c11-7.894,34.611-12.017,56.615-12.886c0.063,0,0.319,0.133,0.38,0.133
        c0.142-0.887,0.153-1.783,0.34-2.678c-0.115,0.842-0.335,1.685-0.335,2.537v0.009c-1,3.343-0.744,6.767-0.744,10.261
        c0,34.917,28.372,63.332,63.289,63.332c3.875,0,7.178-0.346,10.867-1.021c0.027-0.008-0.412-0.008-0.412-0.017V362.43z
         M420.664,163.697c-1.1,7.592-4.054,14.589-8.363,20.54c-4.985,6.855-11.797,12.31-19.717,15.627
        c-0.522,0.222-1.055,0.435-1.596,0.639c-1.614,0.603-3.264,1.127-4.958,1.552c-3.636,0.914-7.441,1.401-11.361,1.401
        c-25.622,0-46.472-20.851-46.472-46.481c0-3.477,0.38-6.865,1.108-10.128c1.002-4.479,2.643-8.709,4.843-12.593
        c7.964-14.173,23.146-23.76,40.521-23.76c25.632,0,46.482,20.851,46.482,46.482C421.151,159.253,420.983,161.498,420.664,163.697z"
      />
      <path
        fill={color}
        d="M415.234,157c0,4.656-3.769,8-8.425,8H383v24.115c0,4.656-4.344,8.425-9,8.425c-4.648,0-9-3.769-9-8.425V165h-22.462
        c-4.656,0-8.426-3.344-8.426-8s3.77-8,8.426-8H365v-24.167c0-4.656,4.352-8.425,9-8.425c4.656,0,9,3.769,9,8.425V149h23.81
        C411.466,149,415.234,152.344,415.234,157z"
      />
    </g>
  </svg>
);

export const MovieIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <g>
      <path
        fill={color}
        d="M388.373,69.572h-276.67c-23.215,0-42.131,18.918-42.131,42.124v276.609c0,23.283,18.916,42.123,42.131,42.123h276.67
        c23.215,0,42.055-18.84,42.055-42.123V111.696C430.428,88.49,411.588,69.572,388.373,69.572z M191.432,124.31
        c0-2.729,1.434-5.087,3.729-6.233c2.365-1.295,5.086-1.078,7.311,0.287l127.016,84.674c2.078,1.365,3.227,3.729,3.156,6.164
        c-0.078,2.505-1.441,4.729-3.66,5.877l-127.016,70.634c-1.076,0.572-2.295,0.859-3.441,0.859c-1.287,0-2.436-0.287-3.652-0.93
        c-2.078-1.219-3.441-3.582-3.441-6.164V124.31z M388.373,370.533H219.73c-2.791,16.84-17.482,29.734-35.17,29.734
        c-17.631,0-32.385-12.895-35.176-29.734H99.662c-3.295,0-6.018-2.721-6.018-6.016c0-3.365,2.723-6.018,6.018-6.018h49.723
        c2.791-16.91,17.545-29.727,35.176-29.727c17.688,0,32.379,12.816,35.17,29.727h168.643c3.295,0,6.018,2.652,6.018,6.018
        C394.391,367.813,391.668,370.533,388.373,370.533z"
      />
    </g>
  </svg>
);

export const ArticleIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <g>
      <path
        fill={color}
        d="M445.61,79H53.32c-4.08,0-7.36,3.38-7.36,7.46S49.24,94,53.32,94H61v257.93c0,4.08,3.02,8.07,7.11,8.07H243v18.45
        c-9,3.01-14.93,11.1-14.93,20.739c0,12.24,9.79,22.141,22.041,22.141c12.18,0,21.899-9.9,21.899-22.141
        c0-9.64-6.01-17.729-15.01-20.739V360h173.89c4.08,0,7.11-3.99,7.11-8.07V94h7.61c4.09,0,7.43-3.46,7.43-7.54S449.7,79,445.61,79z
         M119,302.89c0,5.75-4.75,10.36-10.5,10.36c-5.69,0-10.5-4.61-10.5-10.36V253.57c0-5.74,4.81-10.431,10.5-10.431
        c5.75,0,10.5,4.69,10.5,10.431V302.89z M160,302.89c0,5.75-4.25,10.36-10,10.36c-5.69,0-10-4.61-10-10.36v-111.6
        c0-5.69,4.31-10.37,10-10.37c5.75,0,10,4.68,10,10.37V302.89z M202,302.89c0,5.75-4.81,10.36-10.5,10.36
        c-5.76,0-10.5-4.61-10.5-10.36V129.07c0-5.761,4.74-10.37,10.5-10.37c5.69,0,10.5,4.609,10.5,10.37V302.89z M308.98,313.66
        c-44.82,0-81.23-36.4-81.23-81.16s36.24-81.23,81.06-81.23c4.08,0,7.19,3.351,7.19,7.431V225h66.79c4.07,0,7.35,3.354,7.35,7.435
        C390.14,277.195,353.74,313.66,308.98,313.66z M412.29,211h-73.81c-4.07,0-7.48-3.92-7.48-8v-73.811
        c0-4.069,3.381-7.42,7.451-7.42c44.77,0,81.185,36.785,81.185,81.556C419.636,207.405,416.37,211,412.29,211z"
      />
    </g>
  </svg>
);

export const ChartIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M11,5.08V2C6,2.5,2,6.81,2,12s4,9.5,9,10v-3.08c-3-0.48-6-3.4-6-6.92S8,5.56,11,5.08z M18.97,11H22c-0.47-5-4-8.53-9-9 v3.08C16,5.51,18.54,8,18.97,11z M13,18.92V22c5-0.47,8.53-4,9-9h-3.03C18.54,16,16,18.49,13,18.92z"
    />
  </svg>
);

export const PlusbgIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M19,2.99999996 L4.99999996,2.99999996 C3.88999997,2.99999996 2.99999996,3.89999997 2.99999996,4.99999996 L2.99999996,19 C2.99999996,20.1 3.88999997,21 4.99999996,21 L19,21 C20.1,21 21,20.1 21,19 L21,4.99999996 C21,3.89999997 20.1,2.99999996 19,2.99999996 Z M17,13 L13,13 L13,17 L11,17 L11,13 L6.99999998,13 L6.99999998,11 L11,11 L11,6.99999998 L13,6.99999998 L13,11 L17,11 L17,13 Z"
    />
  </svg>
);

export const DeleteIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12,1.99999996 C6.46999998,1.99999996 1.99999996,6.46999998 1.99999996,12 C1.99999996,17.53 6.46999998,22 12,22 C17.53,22 22,17.53 22,12 C22,6.46999998 17.53,1.99999996 12,1.99999996 Z M17,15.59 L15.59,17 L12,13.41 L8.40999999,17 L6.99999998,15.59 L10.59,12 L6.99999998,8.40999999 L8.40999999,6.99999998 L12,10.59 L15.59,6.99999998 L17,8.40999999 L13.41,12 L17,15.59 Z"
    />
  </svg>
);

export const RadioOnIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
    />
  </svg>
);

export const RadioOffIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
    />
  </svg>
);

export const NotesSmallIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 14H6v-2h2v2zm0-3H6V9h2v2zm0-3H6V6h2v2zm7 6h-5v-2h5v2zm3-3h-8V9h8v2zm0-3h-8V6h8v2z"
    />
  </svg>
);

export const HomeIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 31 27">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(15.500000, 13.500000) scale(-1, 1) rotate(-180.000000) translate(-15.500000, -13.500000)"
        fillRule="nonzero"
      >
        <path
          fill={color}
          d="M7.45926197,19.5226891 C2.03200585,14.3012057 0,12.1920351 0,11.754768 C0,11.2146145 0.102886372,11.1888929 2.5721593,11.060285 L5.1443186,10.931677 L5.22148338,5.45297771 L5.27292656,-3.14260173e-05 C8.41605423,0.00450449399 10.7132804,0.006772454 12.1646049,0.006772454 C12.4939363,0.006772454 11.8577466,7.77481769 12.3610161,7.77481769 C12.6607577,7.77481769 14.0193189,7.77481769 16.4366997,7.77481769 C17.2148746,7.77481769 16.0109027,-0.00439446142 16.7413872,-3.14260173e-05 C20.8158059,0.024510962 24.0988736,0.11852808 24.2297406,0.205772744 C24.4612349,0.360102302 24.5641213,1.85195469 24.6155645,5.65875046 L24.6927293,10.931677 L27.3163318,11.0088418 C29.6827183,11.060285 29.9913774,11.1374498 30.2485934,11.62616 C30.4800877,12.0377055 30.4286445,12.2434783 30.0685422,12.6035806 C29.7856047,12.8607965 26.3646328,16.1274388 22.4292291,19.908513 C18.4938254,23.6638655 15.1757399,26.7504567 15.0985751,26.7248947 C14.9956887,26.7248947 11.5747168,23.4838144 7.45926197,19.5226891 Z M24.2811838,16.1274388 C26.3131896,14.1725977 27.9079284,12.5264158 27.8564852,12.449251 C27.8143449,12.4211575 26.0692286,12.400735 23.3458735,12.3921728 C22.426469,12.3892822 23.6824307,1.35980451 22.5679252,1.35977863 C21.9205973,1.3597636 20.5802291,1.3610436 17.8765071,1.35977863 C17.3926134,1.35955223 18.1947028,9.1143888 17.6960991,9.11417316 C16.875618,9.11381832 11.668136,9.11428286 10.8634575,9.11417316 C10.5033729,9.11410642 11.2103471,1.35975568 10.8634575,1.35977863 C8.46377424,1.35958525 7.45986925,1.35619738 6.86657527,1.35977863 C6.17847115,1.36393218 7.39759166,12.4433611 6.86657527,12.4465664 C5.82080605,12.4528789 4.25215224,12.4623476 2.16061381,12.4749726 L8.66817684,18.6995981 L15.1757399,24.9242236 L17.8765071,22.3006211 C19.3683595,20.8602119 22.2491779,18.0822799 24.2811838,16.1274388 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export const MenuIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill={color} />
  </svg>
);

export const FacebookIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 32 32">
    <path
      fill={color}
      d="M3.2 0h25.6c1.767 0 3.2 1.433 3.2 3.2v25.6c0 1.767-1.433 3.2-3.2 3.2h-25.6c-1.767 0-3.2-1.433-3.2-3.2v-25.6c0-1.767 1.433-3.2 3.2-3.2zM3.2 3.2v25.6h25.6v-25.6h-25.6zM14.613 16.007h2.987v9.593h3.2v-9.593h3.172v-3.2h-3.172v-1.607c0-0.884 0.716-1.6 1.6-1.6h1.6v-3.2h-1.6c-2.651 0-4.8 2.149-4.8 4.8v1.607h-2.987v3.2z"
    ></path>
  </svg>
);

export const LinkedInIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 32 32">
    <path
      fill={color}
      d="M3.2 0h25.6c1.767 0 3.2 1.433 3.2 3.2v25.6c0 1.767-1.433 3.2-3.2 3.2h-25.6c-1.767 0-3.2-1.433-3.2-3.2v-25.6c0-1.767 1.433-3.2 3.2-3.2zM3.2 3.2v25.6h25.6v-25.6h-25.6zM17.6 11.2c-0.828 0-1.704 0.253-2.477 0.723l-0.723-0.723h-1.6v11.2h3.2v-6.4c0-0.921 0.95-1.6 1.6-1.6h1.6c0.65 0 1.6 0.679 1.6 1.6v6.4h3.2v-6.4c0-2.964-2.57-4.8-4.8-4.8h-1.6zM9.6 9.6c0.884 0 1.6-0.716 1.6-1.6s-0.716-1.6-1.6-1.6c-0.884 0-1.6 0.716-1.6 1.6s0.716 1.6 1.6 1.6zM8 11.2v11.2h3.2v-11.2h-3.2z"
    ></path>
  </svg>
);

export const RepositoryIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <g>
      <path
        fill={color}
        d="M324.996,356.253V68.755c0-3.456-2.792-6.254-6.241-6.254H206.254c-0.277,0-0.519,0.121-0.788,0.168
        c-0.288,0.032-0.53,0.081-0.82,0.154c-1.062,0.29-2.059,0.754-2.812,1.52l-99.99,99.979c-0.768,0.773-1.232,1.77-1.527,2.845
        c-0.073,0.269-0.12,0.505-0.141,0.779c-0.054,0.271-0.176,0.512-0.176,0.808v187.5c0,3.457,2.791,6.248,6.249,6.248h212.506
        C322.204,362.501,324.996,359.71,324.996,356.253z M199.998,83.842v78.656h-78.656L199.998,83.842z"
      />
      <path
        fill={color}
        d="M362.504,393.755v-300c0-3.458-2.797-6.255-6.262-6.255h-12.495c-3.444,0-6.241,2.797-6.241,6.255v262.498
        c0,10.336-8.414,18.745-18.751,18.745h-187.5c-3.457,0-6.255,2.797-6.255,6.253v12.504c0,3.45,2.798,6.249,6.255,6.249h224.987
        C359.707,400.004,362.504,397.205,362.504,393.755z"
      />
      <path
        fill={color}
        d="M393.752,112.507H381.25c-3.458,0-6.256,2.79-6.256,6.247v275.001c0,10.337-8.406,18.745-18.752,18.745H156.248
        c-3.45,0-6.249,2.797-6.249,6.253v12.498c0,3.456,2.799,6.248,6.249,6.248h237.504c3.45,0,6.248-2.792,6.248-6.248V118.754
        C400,115.297,397.202,112.507,393.752,112.507z"
      />
    </g>
  </svg>
);

export const LessonIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <g>
      <path
        fill={color}
        d="M475.143,211.323c-0.195-0.331-0.721-1.127-0.855-1.322c-1.662-2.379-3.754-4.22-6.195-5.48
        c-0.373-0.177-0.752-0.342-1.145-0.452c-0.5-0.215-1.002-0.386-1.16-0.446l-203.771-72.87c-0.439-0.165-0.904-0.281-1.344-0.336
        c-7.303-2.324-13.99-2.343-21.297-0.013c-0.479,0.067-0.949,0.184-1.426,0.349L34.416,204.894
        c-0.402,0.104-2.104,0.759-2.469,0.948c-2.459,1.248-4.553,3.095-6.148,5.407c-0.176,0.219-0.801,1.168-0.924,1.4
        c-1.596,2.789-2.373,5.528-2.373,8.354s0.777,5.541,2.373,8.349c0.111,0.214,0.709,1.119,0.834,1.304
        c1.656,2.396,3.742,4.25,6.199,5.485c0.436,0.209,0.895,0.404,1.357,0.538c0.27,0.111,0.516,0.202,0.59,0.215l53.986,22.588
        c0.936,0.396,1.922,0.58,2.918,0.58c1.48,0,2.938-0.428,4.207-1.271c2.1-1.395,3.371-3.768,3.371-6.313v-2.471
        c0-58.967,136.143-60.667,151.666-60.667s151.66,1.7,151.66,60.667v2c0,2.563,1.289,4.949,3.424,6.342
        c2.154,1.402,4.857,1.652,7.182,0.611l4.563-1.98v74.643l-14.361,28.742c-1.871,3.742-0.359,8.305,3.389,10.176
        c3.76,1.848,8.307,0.348,10.176-3.406l8.381-16.76l8.387,16.76c1.326,2.672,4.01,4.201,6.781,4.201c1.137,0,2.293-0.25,3.389-0.795
        c3.75-1.871,5.26-6.434,3.395-10.176L432,331.623v-81.219l33.738-14.643c0.354-0.14,1.98-0.77,2.322-0.935
        c2.461-1.241,4.564-3.095,6.154-5.407c0.172-0.227,0.795-1.156,0.928-1.406c1.578-2.789,2.355-5.523,2.355-8.343
        C477.498,216.833,476.721,214.111,475.143,211.323"
      />
      <path
        fill={color}
        d="M250.004,204.509c-67.836,0-136.502,15.608-136.502,45.499v75.83c0,29.871,68.666,45.498,136.502,45.498
        c67.828,0,136.496-15.627,136.496-45.498v-75.83C386.5,220.117,317.832,204.509,250.004,204.509 M250.004,356.168
        c-79.652,0-121.328-19.742-121.328-30.33s41.676-30.324,121.328-30.324s121.328,19.736,121.328,30.324
        S329.656,356.168,250.004,356.168"
      />
    </g>
  </svg>
);

export const AlertIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 500 500" {...props}>
    <g>
      <path
        fill={color}
        d="M432.842,61c-2.922,0-5.373,2.037-6.008,4.75c-58.607,67.508-142.509,106.18-230.518,106.18h-9.984
        c-10.191,0-18.482,8.293-18.482,18.487v110.93c0,10.192,8.291,18.487,18.482,18.487h9.984c88.009,0,171.91,38.672,230.518,106.169
        c0.635,2.724,3.086,4.75,6.008,4.75c3.394,0,6.158-2.751,6.158-6.149V67.161C439,63.753,436.235,61,432.842,61"
      />
      <path
        fill={color}
        d="M147.281,301.347v-110.93c0-2.11,0.213-4.164,0.624-6.16H91.813C75.108,184.256,61,198.854,61,216.141v59.482
        c0,17.286,14.108,31.882,30.813,31.882h56.093C147.494,305.511,147.281,303.447,147.281,301.347"
      />
      <path
        fill={color}
        d="M227.735,398.929c-3.442-4.32-6.685-9.167-9.614-14.413c-7.392-13.192-9.838-28.464-7.697-43.724
        c-4.684-0.248-9.401-0.387-14.107-0.387h-9.984c-10.028,0-18.869-4.889-24.49-12.329H135.56l21.658,81.76
        C161.052,427.017,175.994,439,193.582,439h21.69c7.933,0,14.984-4.115,18.891-11.006c3.91-6.887,3.802-15.063-0.966-22.802
        C233.197,405.192,229.026,400.56,227.735,398.929"
      />
    </g>
  </svg>
);

export const RefreshIcon: React.FC<IconProps> = ({ color, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 -960 960 960" {...props}>
    <path fill={color} d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z" />
  </svg>
);
