import styled, { css } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';
import { screenLgAbove, screenLgUnder } from 'styles/breakpoinst';
import Icon from 'components/Icons';

export const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  grid-area: nav;
  background-color: ${colors.brand_2};
  padding: 10px;
`;

export const LogoLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0 28px;
`;

export const StyledIcon = styled(Icon)<{ margin?: string }>`
  margin-right: 4px;
  path {
    transition: fill ${ANIMATION};
  }
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const buttonStyles = css`
  display: inline-flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0;
  width: 100%;
  height: initial;
  color: ${colors.white};
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.7px;
  text-align: left;
  cursor: pointer;
  border: none;
  outline: none;
  transition: color ${ANIMATION};
  opacity: 0.7;
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
  &.active {
    opacity: 1;
  }
`;

export const NavList = styled.ul`
  margin: 0 -10px;
  padding: 0;
  list-style: none;
  @media screen and (max-width: ${screenLgUnder}) {
    margin-bottom: 32px;
  }
`;

export const ListItem = styled.li`
  margin: 0 0 12px;
  padding: 0 10px;
`;

export const NavItem = styled(NavLink)`
  ${buttonStyles};
  padding: 6px;
  &.active {
    background-color: ${colors.black_shadow};
    &::after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: ${colors.white};
      margin-left: 5px;
    }
  }
  & > span {
    width: 100%;
    margin-left: 5px;
  }
  ${StyledIcon} {
    flex: 0 0 24px;
  }
  @media screen and (max-width: ${screenLgUnder}) {
    &.active {
      position: relative;
      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
    & > span {
      display: none;
    }
    ${StyledIcon} {
      margin-right: 0;
    }
  }
`;

const buttonSquareStyles = css`
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
  opacity: 1;
  border-radius: 5px;
  @media screen and (min-width: ${screenLgAbove}) {
    width: 58px;
    height: 58px;
  }
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const StyledButtonLink = styled(Link)<{ bgColor: string }>`
  ${buttonStyles};
  ${buttonSquareStyles};
  background: ${({ bgColor }) => (bgColor ? bgColor : 'transparent')};
`;

export const StyledButton = styled.button<{ bgColor: string }>`
  ${buttonStyles};
  ${buttonSquareStyles};
  background: ${({ bgColor }) => (bgColor ? bgColor : 'transparent')};
`;

export const GrayLine = styled.hr`
  margin: 12px 0 24px;
  height: 1px;
  border: solid 1px ${colors.white};
  opacity: 0.1;
`;

export const AppVersionStyled = styled.div`
  margin-top: 8px;
  width: 100%;
	color: ${colors.white};
	box-shadow: none;
	font-size: 14px;
	font-weight: 400;
  text-align: center;
	opacity: 0.7;
`;
