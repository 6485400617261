import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import { ANIMATION } from 'styles/globalVariables';
import { screenMdUnder, screenXlgAbove } from 'styles/breakpoinst';
import FlexDiv from 'components/FlexDiv';

import { Detail } from '../ChatMeta/styles';

export interface ChatStyleProps {
  marked: boolean;
}

const markedStyles = css`
  margin-left: -24px;
  padding-left: 32px;
  background-color: ${colors.brand_1};
  border-bottom-color: ${colors.white_shadow_06};
  color: ${colors.white};
  &:hover {
    background-color: ${colors.brand_1_scale2};
  }
`;

const selectedStyles = css`
  margin-left: -24px;
  padding-left: 32px;
  background-color: ${colors.brand_2};
  border-bottom-color: ${colors.white_shadow_06};
  color: ${colors.white};
  &:hover {
    background-color: ${colors.brand_2_scale2};
  }
`;

const markedMessageStyles = css`
  color: ${colors.white_half};
`;

const selectedMessageStyles = css`
  color: ${colors.white};
`;

const markedAnonymousStyles = css`
  border-color: ${colors.white};
  color: ${colors.white};
`;

const selectedAnonymousStyles = css`
  border-color: ${colors.white};
  color: ${colors.white};
`;

export const Wrapper = styled(FlexDiv)`
  flex: 0 0 280px;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid ${colors.gray_shadow_03};
  > div {
    flex: 1;
    width: 100%;
  }
  @media screen and (min-width: ${screenXlgAbove}) {
    flex: 0 0 360px;
  }
  @media screen and (max-width: ${screenMdUnder}) {
    display: none;
  }
`;

export const CategoriesWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding: 0 20px;
`;

export const ChatContainer = styled.li<ChatStyleProps & { selected: boolean }>`
  margin-left: 0;
  padding: 16px 8px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.gray_shadow_03};
  color: ${colors.gray};
  transition:
    background-color ${ANIMATION},
    border-bottom-color ${ANIMATION},
    color ${ANIMATION};
  cursor: pointer;
  &:hover {
    background-color: ${colors.gray_scale6};
  }
  ${({ marked }) => (marked ? markedStyles : '')};
  ${({ selected }) => (selected ? selectedStyles : '')};
`;

export const NicknameRow = styled(FlexDiv)<ChatStyleProps>`
  font-size: 14px;
  line-height: 18px;
`;

export const Nick = styled.span`
  flex: 1;
  padding-right: 8px;
  min-width: 0;
  word-wrap: break-word;
`;

export const TruncatedMessage = styled.div<ChatStyleProps & { selected: boolean }>`
  font-size: 12px;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ marked }) => (marked ? markedMessageStyles : '')};
  ${({ selected }) => (selected ? selectedMessageStyles : '')};
`;

export const IndicatorsWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
`;

export const AnonymousIndicator = styled(FlexDiv)<ChatStyleProps & { selected: boolean }>`
  width: 14px;
  height: 14px;
  border: 1px solid ${colors.brand_2};
  border-radius: 50%;
  background-color: transparent;
  font-size: 10px;
  color: ${colors.brand_2};
  ${({ marked }) => (marked ? markedAnonymousStyles : '')};
  ${({ selected }) => (selected ? selectedAnonymousStyles : '')};
`;

export const ChatList = styled.ul`
  margin: 0;
  padding: 0 0 0 24px;
  list-style: none;
`;

export const Title = styled.div`
  margin-left: 24px;
  padding: 8px 16px 8px 0;
  font-size: 10px;
  line-height: 13px;
  color: ${colors.gray};
  border-bottom: 1px solid ${colors.gray_shadow_03};
  opacity: 0.7;
`;

const chatTabActiveStyles = css`
  font-weight: 600;
  color: ${colors.brand_2};
  &::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${colors.brand_2};
    margin: 5px auto;
  }
`;

export const StyledChatTab = styled.div<{ active: boolean }>`
  color: ${colors.gray_scale10};
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  padding: 0 3px;
  white-space: pre-line;
  ${({ active }) => active && chatTabActiveStyles};
  @media screen and (min-width: ${screenXlgAbove}) {
    font-size: 14px;
  }
`;

export const TabsConatiner = styled(FlexDiv)`
  margin-top: -15px;
`;

const allTagsStyles = css`
  max-height: 110px;
  overflow: hidden;
`;

export const TagsContainer = styled.div<{ showAllTags: boolean }>`
  margin: 15px 0;
  ${({ showAllTags }) => !showAllTags && allTagsStyles}
`;

export const ShowTagsButton = styled(FlexDiv)<{ showAllTags: boolean }>`
  border: 1px solid ${colors.brand_2};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin: auto;
  cursor: pointer;
  ${({ showAllTags }) =>
    showAllTags &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ArchiveSwitcherGroupStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: 15px 0;
`;

export const ArchiveSwitcherStyled = styled(Detail)`
  margin: 0;
`;

export const Slider = styled.span`
  position: relative;
  display: inline-flex;
  width: 35px;
  height: 14px;
  border-radius: 7px;
  background-color: ${colors.gray_scale4};
  transition: 0.4s;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: ${colors.gray_scale3};
    border-radius: 50%;
    transition: 0.4s;
    cursor: pointer;
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  &:checked + ${Slider} {
    background-color: ${colors.brand_shadow_025};
    &:before {
      transform: translate(15px, -50%);
      background-color: ${colors.brand_2};
    }
  }
`;

export const InfoStyled = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.brand_2};
`;
