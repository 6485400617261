import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  STATUS_CODE_UNAUTHORIZED,
  Options,
  ResponseError,
  api,
  STATUS_CODE_NOT_PERMITTED,
} from 'utils/api';
import { isEqual } from 'lodash/fp';
import useAuth from './withAuth';
import { reducer as dataReducer } from './reducer';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';

export function useApi<T>(
  url: string,
  options: Options = { method: 'GET' }
): {
  data?: T;
  isLoading: boolean;
  error?: ResponseError;
  loaded: boolean;
  fetchData: () => Promise<T | void>;
} {
  const [, dispatchApp] = useApp();

  const params = useMemo(() => options, [options]);
  const withAuth = useAuth();

  const reducer = dataReducer<T>();

  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    error: undefined,
    isLoading: true,
    loaded: false,
  });

  const fetchData = useCallback(async () => {
    try {
      const response = await api(url, {
        ...params,
        headers: {
          ...params.headers,
        },
      });

      dispatch({ type: 'FETCH_SUCCESS', payload: response });
    } catch (error) {
      const typedError = error as ResponseError;
      if (
        typedError.response &&
        (typedError.response.status === STATUS_CODE_UNAUTHORIZED ||
          typedError.response.status === STATUS_CODE_NOT_PERMITTED)
      ) {
        dispatchApp({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
        });
        throw error;
      }
      dispatch({ type: 'FETCH_FAILURE', payload: typedError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, url]);

  useEffect(() => {
    if (isEqual(previousOptions.current, options) && previousUrl.current === url) {
      return;
    }
    dispatch({ type: 'FETCH_INIT' });
    let abortController: AbortController | undefined;
    if (typeof AbortController !== 'undefined') {
      abortController = new AbortController();
    }
    withAuth(fetchData());
    return () => {
      if (abortController) abortController.abort();
    };
  }, [fetchData, options, url, withAuth]);

  const previousOptions = useRef<Options>();
  const previousUrl = useRef<string>();

  useEffect(() => {
    previousOptions.current = options;
    previousUrl.current = url;
  });

  const { data, error, isLoading, loaded } = state;
  return { data, error, isLoading, loaded, fetchData };
}

const apiUrl = process.env.REACT_APP_API_URL || '/api/backoffice';

export const getApiUrl = (postfix: string) => `${apiUrl}${postfix}`;
